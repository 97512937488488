import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { LeftPane } from 'components/Layout';
import {
  ButtonsContainer,
  Divider,
  Spacer,
} from 'components/common/ButtonsContainer';
import { SectionLabel } from 'components/common/SectionLabel';
import {
  Breadcrumb,
  Breadcrumbs,
} from 'components/common/Breadcrumbs';
import { Box } from 'components/common/Box';
import { CollapsibleBox } from 'components/common/Box/CollapsibleBox';
import { Datepicker } from 'components/common/Datepicker';
import { InputBasic } from 'components/common/Input';
import { Dropdown } from 'components/common/Dropdown';
import { Button } from 'components/common/Button';
import {
  addMonths,
  differenceInCalendarMonths,
  format,
  startOfMonth,
  subMonths,
} from 'date-fns';
import {
  toastSuccess,
  toastWarning,
} from 'utils/toast';
import {
  MAX_PERIODS,
  MetricAggregationMethodOptions,
  MetricCategoryOptions,
} from 'utils/constants';
import {
  FrequencyOptions,
  formatDateToISODate,
  isoToDate,
} from 'utils/dates';
import { PeriodsInputWrapper } from 'pages/Accounts/Upload/FinancialsUploader/styled';
import { AgGridReact } from '@ag-grid-community/react';
import { FactaTable } from 'components/FactaTable';
import {
  useGetMetricsWithValuesQuery,
  usePutManualMetricsMutation,
} from 'store/services/metrics';
import {
  CellKeyDownEvent,
  ColDef,
  GridOptions,
  ICellRendererParams,
  IRichCellEditorParams,
  ProcessCellForExportParams,
  ProcessDataFromClipboardParams,
} from '@ag-grid-community/core';
import { AgGridCellRendererDropdown } from 'components/common/AgGridCellRendererDropdown';
import {
  FormatOptions,
  mapMetricsWithValuesToTableView,
  prepareManualsPayload,
  verifyManualsPayload,
} from 'utils/metrics';
import {
  AggregationMethod,
  MetricCategory,
  MetricOrigin,
} from 'interfaces/metrics';
import { Checkbox } from 'components/common/Checkbox';
import { format as currencyFormat } from "currency-formatter";
import { parsedPastedValue } from 'utils/financialsUploader';
import { highlightNodeById } from 'utils/aggrid';

export const ManualMetrics = () => {
  const gridRef = useRef<AgGridReact>(null);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [periodStart, setPeriodStart] = useState<Date | null>(null);
  const [periodEnd, setPeriodEnd] = useState<Date | null>(null);
  const [frequency, setFrequency] = useState({
    name: 'Monthly',
    variant: 'monthYearPicker' as const,
  });
  const [periodsNumber, setPeriodsNumber] = useState<number | string | undefined>(0);
  const [tableData, setTableData] = useState<any[]>([]);
  const [isInitialized, setIsInitialized] = useState(false);
  const [displayCurrentData, setDisplayCurrentData] = useState(true);

  const { data: metrics, isFetching: isFetchingMetrics } = useGetMetricsWithValuesQuery({
    origin: MetricOrigin.MANUAL,
    omitSettingsDates: 'true',
  }, {
    skip: isInitialized,
  });

  const [putManuals, { isLoading: isUploading }] = usePutManualMetricsMutation();

  const categoryOptions = MetricCategoryOptions.map(({ name }) => name);
  const aggregationOptions = MetricAggregationMethodOptions.map(({ name }) => name);

  const processDataFromClipboard = useCallback(
    (params: ProcessDataFromClipboardParams): string[][] | null => {
      const data = [...params.data]
        .map((line) => line.map((cell) => cell.trim()));

      const emptyLastRow =
        data[data.length - 1][0] === '' && data[data.length - 1].length === 1;
      if (emptyLastRow) {
        data.splice(data.length - 1, 1);
      }
      const lastIndex = gridRef.current!.api.getModel()
        .getRowCount() - 1;

      const focusedCell = gridRef.current!.api.getFocusedCell();
      const focusedIndex = focusedCell!.rowIndex;

      if (focusedIndex + data.length - 1 > lastIndex) {
        const resultLastIndex = focusedIndex + (data.length - 1);
        const numRowsToAdd = resultLastIndex - lastIndex;
        const rowsToAdd: any[] = [];
        for (let i = 0; i < numRowsToAdd; i++) {
          const index = data.length - 1;
          const row = data.slice(index, index + 1)[0];
          // Create row object
          const rowObject: any = {
            id: crypto.randomUUID(),
          };
          let currentColumn: any = focusedCell!.column;
          row.forEach((item) => {
            if (!currentColumn) {
              return;
            }
            rowObject[currentColumn.colDef.field] = currentColumn.colDef.type === "numberField" ? item || "0" : item;
            currentColumn = gridRef.current!.api.getDisplayedColAfter(
              currentColumn,
            );
          });
          rowsToAdd.push(rowObject);
        }
        gridRef.current!.api.applyTransaction({ add: rowsToAdd });
      }
      return data;
    },
    [],
  );

  const processCellFromClipboard = (params: ProcessCellForExportParams<any, any>) => {
    const colDef = params.column.getColDef();

    if (colDef.type === 'numberField') {
      return parsedPastedValue(params.value);
    }

    return params.value;
  };

  const gridOptions: GridOptions = {
    onSelectionChanged: (e) => setSelectedRows(e.api.getSelectedRows()),
    onFirstDataRendered: (e) => {
      e.api.autoSizeAllColumns();
    },
    processDataFromClipboard,
    processCellFromClipboard,
    suppressRowClickSelection: true,
    rowDragManaged: true,
    suppressMoveWhenRowDragging: true,
    rowDragMultiRow: true,
    icons: {
      rowDrag: '<i class="ag-icon ag-icon-columns"/>',
    },
    undoRedoCellEditing: true,
    undoRedoCellEditingLimit: 10,
    columnTypes: {
      numberField: {
        editable: true,
      },
    },
    tooltipShowDelay: 1000,
    enableCharts: true,
    statusBar: {
      statusPanels: [
        { statusPanel: 'agAggregationComponent' },
      ],
    },
    onColumnEverythingChanged: ({ api }) => {
      api.resetColumnState();
    },
    onCellValueChanged: (params) => {
      if (params.column.getColId() === 'category' && !params.data.aggregationMethod) {
        switch (params.newValue) {
          case MetricCategory.PL:
            params.api.applyTransaction({
              update: [{
                ...params.data, aggregationMethod:
                  MetricAggregationMethodOptions.find((am) => am.value === AggregationMethod.SUM)?.name,
              }],
            });
            break;
          case MetricCategory.BS:
            params.api.applyTransaction({
              update: [{
                ...params.data, aggregationMethod:
                  MetricAggregationMethodOptions.find((am) => am.value === AggregationMethod.EOP)?.name,
              }],
            });
            break;
          default:
            break;
        }
      }
    },
    onCellKeyDown: (props: CellKeyDownEvent<any, any>) => {
      const event = props.event as KeyboardEvent;
      if (event.code === 'Minus' && (event.metaKey || event.ctrlKey) && event.altKey) {
        if (props.api.getSelectedRows().length === 0) {
          props.node.setSelected(true);
        }
        props.api.applyTransaction({
          remove: props.api.getSelectedRows(),
        });
      }
    },
  };

  const commonColumns: ColDef[] = [
    {
      field: '',
      headerName: '',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      pinned: 'left',
      maxWidth: 52,
    },
    {
      field: 'name',
      headerName: 'Metric',
      editable: true,
      resizable: true,
      pinned: 'left',
      minWidth: 250,
      valueSetter: (params: any) => {
        params.data.name = params.newValue;
        return true;
      },
    },
    {
      field: 'format',
      headerName: 'Format',
      editable: true,
      resizable: true,
      pinned: 'left',
      minWidth: 225,
      cellEditor: 'agRichSelectCellEditor',
      cellEditorPopup: false,
      cellEditorParams: {
        values: FormatOptions.map(({ label }) => label),
        cellHeight: 40,
        allowTyping: true,
      } as IRichCellEditorParams,
      cellRenderer: (params: ICellRendererParams) => (
        <AgGridCellRendererDropdown gridParams={params}>
          {params.value || '-'}
        </AgGridCellRendererDropdown>
      ),
      valueSetter: (params: any) => {
        params.data.format = FormatOptions.find((format) => format.label === params.newValue)?.value;
        return true;
      },
      valueGetter: (params: any) => {
        return FormatOptions.find((format) => format.value === params.data.format)?.label || '-';
      },
    },
    {
      field: 'category',
      headerName: 'Metric Category',
      editable: true,
      resizable: true,
      pinned: 'left',
      minWidth: 225,
      cellEditor: 'agRichSelectCellEditor',
      cellEditorPopup: false,
      cellEditorParams: {
        values: categoryOptions,
        cellHeight: 40,
        cellRenderer: ({ value }: any) => value || '-',
        allowTyping: true,
      },
      cellRenderer: (params: ICellRendererParams) => (
        <AgGridCellRendererDropdown gridParams={params}>
          {params.value || '-'}
        </AgGridCellRendererDropdown>
      ),
      valueSetter: (params: any) => {
        params.data.category = params.newValue;
        return true;
      },
      valueGetter: (params: any) => {
        return categoryOptions.find((cat) => cat === params.data.category);
      },
    },
    {
      field: 'description',
      headerName: 'Description',
      editable: true,
      resizable: true,
      pinned: 'left',
      minWidth: 215,
    },
    {
      field: 'aggregationMethod',
      headerName: 'Aggregation Method',
      editable: true,
      resizable: true,
      pinned: 'left',
      minWidth: 225,
      cellEditor: 'agRichSelectCellEditor',
      cellEditorPopup: false,
      cellEditorParams: {
        values: aggregationOptions,
        cellHeight: 40,
        allowTyping: true,
      },
      cellRenderer: (params: ICellRendererParams) => (
        <AgGridCellRendererDropdown gridParams={params}>
          {params.value || '-'}
        </AgGridCellRendererDropdown>
      ),
      valueSetter: (params: any) => {
        params.data.aggregationMethod = params.newValue;
        return true;
      },
      valueGetter: (params: any) => {
        return aggregationOptions.find((cat) => cat === params.data.aggregationMethod);
      },
    },
    {
      field: 'tags',
      headerName: 'Tags',
      editable: true,
      resizable: true,
      pinned: 'left',
      minWidth: 215,
    },
  ];
  const columnDefs: ColDef[] = useMemo(() => {
    return [
      ...commonColumns,
      ...columns,
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  const addEmptyRow = () => {
    const id = crypto.randomUUID();
    gridRef.current?.api?.applyTransaction({
      add: [{
        id,
        format: 'currency',
        newlyCreated: true,
      }],
    });
    highlightNodeById(id, gridRef);
  };

  const handleStartDateChange = (date: Date | [Date | null, Date | null] | undefined) => {
    if (!periodEnd) {
      setPeriodStart(date as Date);
      return;
    };

    const newNumberOfPeriods = differenceInCalendarMonths(periodEnd, date as Date) + 1;

    if (newNumberOfPeriods > 0) {
      setPeriodStart(date as Date);
      setPeriodsNumber(newNumberOfPeriods);
    }
  };

  const handleEndDateChange = (date: Date | [Date | null, Date | null] | undefined) => {
    const newNumberOfPeriods = periodStart ? differenceInCalendarMonths(date as Date, periodStart) + 1 : 0;

    if (newNumberOfPeriods > 0) {
      setPeriodEnd(date as Date);
      setPeriodsNumber(newNumberOfPeriods);
    }
  };

  const handlePeriodsChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!periodStart) return;

    if (Number(e.currentTarget.value) > MAX_PERIODS) return MAX_PERIODS;
    if (Number(e.currentTarget.value) < 0) return;

    const newValue = Number(e.currentTarget.value);
    setPeriodsNumber(newValue ? newValue : '');
    setPeriodEnd(addMonths(periodStart, newValue ? newValue - 1 : 0));
  };

  const handleResetForm = useCallback(() => {
    const items: any[] = [];
    gridRef.current?.api?.forEachNode((node) => {
      items.push(node.data);
    });
    const newItems = items?.filter((item) => !!item.newlyCreated) || [];
    const oldItems = mapMetricsWithValuesToTableView(metrics);

    setTableData(displayCurrentData
      ? [...oldItems, ...newItems]
      : !!newItems.length
        ? newItems
        : [{
          id: crypto.randomUUID(),
          format: 'currency',
          newlyCreated: true,
        }]);
  }, [displayCurrentData, metrics]);

  const handleSave = async () => {
    if (!periodStart || !periodEnd) return;

    gridRef.current?.api.stopEditing();
    if (!periodEnd) return;

    const items: any[] = [];
    gridRef.current?.api.forEachNode((node) => {
      items.push(node.data);
    });

    const payload = prepareManualsPayload({
      data: items,
      start: periodStart,
      end: periodEnd,
    });

    if (!verifyManualsPayload(payload)) {
      return toastWarning('Missing fields found. Fill in all required data before uploading.');
    }

    await putManuals(payload)
      .unwrap()
      .then(() => {
        toastSuccess('Metrics successfully uploaded.');
        setDisplayCurrentData(true);
      });
  };

  useEffect(() => {
    if (!periodStart || !periodEnd) return;

    const columns: ColDef[] = new Array(periodsNumber)
      .fill(undefined)
      .map((_, index) => {
        const field = format(addMonths(periodStart, index), 'yyyy-MM-dd');

        const headerName = format(addMonths(periodStart, index), 'MMM y');
        return {
          field,
          headerName,
          editable: true,
          type: 'numberField',
          minWidth: 170,
          width: 170,
          headerClass: 'ag-right-aligned-header',
          cellEditor: 'agNumberCellEditor',
          cellEditorParams: {
            precision: 10,
          },
          cellClass: 'ag-right-aligned-cell',
          valueFormatter: ({ value }) => value !== '' && value !== undefined && value !== null ? currencyFormat(value, { code: 'USD', precision: 2, symbol: '' }) : '',
        };
      });

    setColumns(columns);
  }, [periodStart, periodEnd, periodsNumber]);

  useEffect(() => {
    handleResetForm();
  }, [handleResetForm]);

  useEffect(() => {
    if (!metrics || isInitialized) return;

    const allEntriesPeriodsArray = [
      ...new Set(metrics
        ?.map((metric) => metric.entries.map((entry) => entry.period))
        .flat()
        .sort()),
    ];

    setIsInitialized(true);

    const noManualMetrics = allEntriesPeriodsArray.length === 0;
    const thisMonth = startOfMonth(new Date());
    const start = noManualMetrics
      ? formatDateToISODate(subMonths(thisMonth, 12))
      : isoToDate(allEntriesPeriodsArray[0])!;

    const end = noManualMetrics
      ? formatDateToISODate(subMonths(thisMonth, 1))
      : isoToDate(allEntriesPeriodsArray[allEntriesPeriodsArray.length - 1])!;

    const newNumberOfPeriods = differenceInCalendarMonths(end, start) + 1;
    setPeriodStart(start);
    setPeriodEnd(end);
    setPeriodsNumber(newNumberOfPeriods);
    setDisplayCurrentData(!noManualMetrics);
  }, [isInitialized, metrics]);

  return (
    <>
      <LeftPane>
        <ButtonsContainer>
          <Breadcrumbs>
            <Breadcrumb link="/metrics">
              Metrics
            </Breadcrumb>
            <Breadcrumb>
              Manual Metrics
            </Breadcrumb>
          </Breadcrumbs>
        </ButtonsContainer>
        <SectionLabel marginBottom={20}>
          Manual Metrics
        </SectionLabel>
        <ButtonsContainer>
          <Spacer />
        </ButtonsContainer>
        <CollapsibleBox
          title="1. Select Dates"
          subtitle="Select the start and end dates to populate the table below."
          tertiary
          initiallyOpen
          autoFocus
          marginBottom={24}
        >
          <ButtonsContainer
            paddingBottom={0}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                gridRef.current?.api.setFocusedCell(0, 'name');
                gridRef.current?.api.startEditingCell({
                  rowIndex: 0,
                  colKey: 'name',
                });
              }
            }}
          >
            <Datepicker
              name="periodStart"
              labelText="Period Start"
              onChange={handleStartDateChange}
              value={periodStart || undefined}
              variant={frequency.variant}
              isClearable={false}
              condensed
            />
            <PeriodsInputWrapper>
              <InputBasic
                name="periods"
                labelText="Periods"
                value={`${periodsNumber}`}
                onChange={handlePeriodsChange}
                type="number"
                max={MAX_PERIODS}
                min="1"
                condensed
              />
            </PeriodsInputWrapper>
            <Datepicker
              name="periodEnd"
              labelText="Period End"
              onChange={handleEndDateChange}
              value={periodEnd || undefined}
              variant={frequency.variant}
              isClearable={false}
              condensed
            />
            <Dropdown
              labelText="Frequency"
              values={[frequency]}
              labelField="name"
              valueField="name"
              minWidth={150}
              options={FrequencyOptions}
              onChange={(value: any[]) => {
                setFrequency(value[0]);
              }}
              disabled
              condensed
            />
          </ButtonsContainer>
        </CollapsibleBox>
        <Box
          marginBottom={0}
          style={{ height: 'calc(100vh - 138px)' }}
          disabled={!periodStart || !periodEnd}
          disabledText="Select Dates first"
        >
          <SectionLabel tertiary>
            <span>
              2. Paste in Spreadsheet
              <p>
                Copy the data from your spreadsheet into the Name and Date columns.
                Fill in the corresponding Metric and select if each line is Actuals or Forecast,
                and delete all empty rows, including any heading rows with empty values in the
                Date columns. Click “Submit” when you’re finished updating the table.
              </p>
            </span>
          </SectionLabel>
          <ButtonsContainer paddingBottom={32}>
            <Button
              variant="contained"
              onClick={handleSave}
              disabled={isUploading}
              isLoading={isUploading}
              minWidth={175}
            >
              SUBMIT
            </Button>
            <Spacer />
            <Button
              variant="outlined"
              color="error"
              disabled={isUploading}
              onClick={handleResetForm}
            >
              Reset form
            </Button>
          </ButtonsContainer>
          <FactaTable
            gridRef={gridRef}
            data={tableData}
            columnDefs={columnDefs}
            selectedRowsLength={selectedRows.length}
            entityName="line"
            gridOptions={gridOptions}
            onClickDelete={() => {
              gridRef.current?.api.applyTransaction({
                remove: selectedRows,
              });
            }}
            onClickAdd={addEmptyRow}
            condensed
            suppressQuantityDisplay
            customJSX={<>
              <Divider />
              <Checkbox
                name="displayCurrentData"
                checked={displayCurrentData}
                onChange={() => setDisplayCurrentData(!displayCurrentData)}
                condensed
              >
                Display Current Data
              </Checkbox>
            </>}
            isLoading={isFetchingMetrics}
            isDisabled={isUploading}
            views={[
              {
                name: 'Hide non-essential',
                hiddenColumns: ['category', 'description', 'aggregationMethod', 'tags'],
              },
            ]}
          />
        </Box>
      </LeftPane>
    </>
  );
};