import {
  SalesRepData,
  CommissionsManagementData,
  CommissionsRequest,
  SDRData,
} from "interfaces/commissions";
import { api } from "./api";
import { CommissionTags } from "./api-tags";

export const commissionsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSalesData: builder.query<SalesRepData, CommissionsRequest>({
      query: ({startDate, endDate}) => ({
        url: `commissions/sales?startDate=${startDate}&endDate=${endDate}`,
      }),
      providesTags: [CommissionTags.Sales],
    }),
    getManagementData: builder.query<CommissionsManagementData, CommissionsRequest>({
      query: ({startDate, endDate}) => ({
        url: `commissions/management?startDate=${startDate}&endDate=${endDate}`,
      }),
      providesTags: [CommissionTags.Management],
    }),
    getSDRData: builder.query<SDRData, CommissionsRequest>({
      query: ({startDate, endDate}) => ({
        url: `commissions/sales_dev?startDate=${startDate}&endDate=${endDate}`,
      }),
      providesTags: [CommissionTags.SDR],
    }),
  }),
});

export const {
  useGetSalesDataQuery,
  useGetManagementDataQuery,
  useGetSDRDataQuery,
} = commissionsApi;
