import {
  css,
  createGlobalStyle,
} from 'styled-components';
import { theme } from './theme';

export const globalStyles = css`
  body,
  input {
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  button {
    font-family: inherit;
  }

  h1 {
    font-size: 96px;
    line-height: 144px;
    font-weight: 500;
  }

  h2 {
    font-size: 60px;
    line-height: 90px;
    font-weight: 500;
  }

  h3 {
    font-size: 48px;
    line-height: 76px;
    font-weight: 600;
  }

  h4 {
    font-size: 34px;
    line-height: 54px;
    font-weight: 600;
  }

  h5 {
    font-size: 24px;
    line-height: 38px;
    font-weight: 600;
  }

  h6 {
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
  }

  p {
    font-size: 16px;
    line-height: 24px;
  }

  p + p {
    margin-top: 16px;
  }

  strong {
    font-weight: 700;
  }

  a,
  a:visited {
    color: ${theme.colors.primary};
    text-decoration: none;
  }

  [data-amplify-authenticator] {
    --amplify-space-xl: 60px;
  }

  [data-amplify-authenticator] [data-amplify-form] {
    padding: 30px 60px 60px;
  }

  :root {
    --toastify-color-info: ${theme.colors.textDark};
    --toastify-color-success: ${theme.colors.success};
    --toastify-color-warning: ${theme.colors.warning};
    --toastify-color-error: ${theme.colors.error};
    --toastify-toast-width: auto;
  }

  .ag-popup-editor .ag-rich-select-value {
    border-radius: 0;
  }

  .ag-popup .ag-rich-select-row .ag-react-container {
    width: 100%;
  }
`;

export const GlobalStyles = createGlobalStyle`${globalStyles}`;

export default globalStyles;