import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  LeftPane,
  RightPane,
} from 'components/Layout';
import {
  useLazyExportContractsQuery,
  useGetContractsSearchQuery,
} from 'store/services/contracts';
import { getColumnDefs } from './columnDefinitions';
import { useRightPanes } from 'hooks/useRightPanes';
import { Button } from 'components/common/Button';
import { GridOptions } from '@ag-grid-community/core';
import { Contract } from 'interfaces/contracts';
import { AgGridReact } from '@ag-grid-community/react';
import {
  RefreshIcon,
  DownloadIcon,
  UploadIcon,
} from 'assets/icons';
import {
  ButtonsContainer,
  Spacer,
} from 'components/common/ButtonsContainer';
import { SectionLabel } from 'components/common/SectionLabel';
import { Tooltip } from 'components/common/Tooltip';
import { AddContract } from './AddContract';
import { EditContracts } from './EditContracts';
import { Tabs } from 'components/common/Tabs';
import {
  useLocation,
  useNavigate,
} from 'react-router';
import { FactaTable } from 'components/FactaTable';
import { AddTag } from 'pages/Tags/AddTag';
import { DeleteContracts } from './DeleteContracts';
import { CancelContracts } from './CancelContracts';
import { ContextMenu } from 'components/ContextMenu';
import { featureSwitch } from 'utils/featureSwitch';
import { BulkImportPanel } from 'components/BulkImport/BulkImportPanel';
import { RenewContractPane } from './RenewContractPane';
import {
  highlightNodeById,
  reselectNodeById,
} from 'utils/aggrid';
import { AddCustomer } from 'pages/Customers/AddCustomer';
import { LinkContracts } from './LinkContracts';
import { UnlinkContract } from './UnlinkContract';
import {
  Breadcrumbs,
  Breadcrumb,
} from 'components/common/Breadcrumbs';
import { handleDownloadTemplate } from 'utils/bulkImport';
import { CompanyRevenueType } from 'interfaces/company';

interface Props {
  setCustomMarkerData: (customData: {}) => void;
}

export const Contracts = ({ setCustomMarkerData }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const columnDefs = getColumnDefs();
  const gridRef = useRef<AgGridReact>(null);
  const [selectedRows, setSelectedRows] = useState<Contract[]>([]);
  const importPath = '/data-input/contract-management/contracts/import';

  const { rightPanes, setSelectedPane, resetPanes } = useRightPanes({
    add: false,
    edit: true,
    delete: false,
    cancel: false,
    renew: false,
    link: false,
    unlink: false,
    addTagFromAddContract: false,
    addTagFromEditContract: false,
    bulkImportContracts: false,
    addCustomerFromAddContract: false,
    addCustomerFromEditContract: false,
  });

  const gridOptions: GridOptions = {
    onSelectionChanged: (e) => {
      const selectedRows = e.api.getSelectedRows();
      setSelectedRows(selectedRows);
      if (selectedRows.length) {
        resetPanes();
      }
    },
  };

  const {
    data: contracts,
    isLoading,
    isFetching,
    refetch: refetchContracts,
  } = useGetContractsSearchQuery({
    filtering: { deleted: false },
    pagination: {
      page: 0,
      perPage: 0,
    },
    simplified: false,
  });

  const [exportContracts, { isFetching: isExportingContracts }] = useLazyExportContractsQuery();

  const handleBulkImport = () => {
    const hideInstructions = localStorage.getItem('hideInstructions') === 'true';
    gridRef.current?.api.deselectAll();
    if (hideInstructions) {
      navigate(importPath);
    } else {
      setSelectedPane('bulkImportContracts');
    }
  };

  const handleAddContract = () => {
    gridRef.current?.api.deselectAll();
    setSelectedPane('add');
  };

  const handleAddTagGoBack = () => {
    setSelectedPane(rightPanes.addTagFromAddContract ? 'add' : 'edit');
  };

  const handleAddCustomerGoBack = () => {
    setSelectedPane(rightPanes.addCustomerFromAddContract ? 'add' : 'edit');
  };

  const handleCSVDownload = () => exportContracts();

  const isAddTagPaneOpen = rightPanes.addTagFromAddContract
    || rightPanes.addTagFromEditContract;

  const isAddCustomerPaneOpen = rightPanes.addCustomerFromAddContract
    || rightPanes.addCustomerFromEditContract;

  const contextMenuItems = [
    {
      action: handleBulkImport,
      name: 'Upload PDF',
    },
    {
      action: handleBulkImport,
      name: 'Bulk Import',
    },
    {
      name: '--',
    },
    {
      action: () => handleDownloadTemplate('standard', CompanyRevenueType.CONTRACTS),
      name: 'Download Template',
    },
  ];

  useEffect(() => {
    if (location.hash === '#add') {
      location.hash = '';
      setSelectedPane('add');
    }
  }, [location, setSelectedPane]);

  useEffect(() => {
    setCustomMarkerData({
      selectedIds: selectedRows.map((row) => row.id),
    });
  }, [selectedRows, setCustomMarkerData]);

  useEffect(() => {
    if (selectedRows.length) {
      reselectNodeById(selectedRows[0].id, gridRef);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contracts]);

  return (
    <>
      <LeftPane>
        <ButtonsContainer>
          <Breadcrumbs>
            <Breadcrumb link="/data-input">Data Sources</Breadcrumb>
            <Breadcrumb link="/data-input/contract-management">Contract Management</Breadcrumb>
            <Breadcrumb>Contracts</Breadcrumb>
          </Breadcrumbs>
          <Spacer />
          <Tooltip title="Refresh table data">
            <Button
              variant="icon"
              aria-label="Refresh"
              onClick={refetchContracts}
            >
              <RefreshIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Download CSV">
            <Button
              variant="icon"
              aria-label="Download"
              onClick={handleCSVDownload}
            >
              <DownloadIcon />
            </Button>
          </Tooltip>
          {featureSwitch.bulkImportContracts && (
            <ContextMenu
              items={contextMenuItems}
              customIcon={<UploadIcon />}
              tooltip="Import data..."
            />
          )}
        </ButtonsContainer>
        <SectionLabel>
          <span>
            Contracts
          </span>
        </SectionLabel>
        <Tabs
          tabs={[
            {
              name: 'Contracts',
              value: '/data-input/contract-management/contracts',
            },
            {
              name: 'Contract Lines',
              value: '/data-input/contract-management/contracts/contract-lines',
            },
          ]}
          value="/data-input/contract-management/contracts"
        />
        <FactaTable
          gridRef={gridRef}
          data={contracts?.data || []}
          useNativeFilters
          columnDefs={columnDefs}
          isLoading={isLoading || isFetching || isExportingContracts}
          isDisabled={rightPanes.add}
          onClickAdd={handleAddContract}
          selectedRowsLength={selectedRows.length}
          entityName="Contract"
          gridOptions={gridOptions}
          showQuickSearch
        />
      </LeftPane>
      <RightPane isOpen={rightPanes.bulkImportContracts}>
        <BulkImportPanel
          onClose={() => resetPanes()}
          name="contracts"
          targetPath={importPath}
        />
      </RightPane>
      <RightPane isOpen={rightPanes.add}>
        <AddContract
          onClose={resetPanes}
          onSuccess={(id) => navigate(`/data-input/contract-management/contracts/${id}`)}
          onAddTag={() => setSelectedPane('addTagFromAddContract')}
          onAddCustomer={() => setSelectedPane('addCustomerFromAddContract')}
        />
      </RightPane>
      <RightPane isOpen={selectedRows.length > 0 && !isAddTagPaneOpen && !rightPanes.addCustomerFromEditContract}>
        {rightPanes.edit && (
          <EditContracts
            onClose={() => gridRef.current?.api.deselectAll()}
            onSave={(id) => reselectNodeById(id, gridRef)}
            selectedRows={selectedRows}
            onAddTag={() => setSelectedPane('addTagFromEditContract')}
            onDelete={() => setSelectedPane('delete')}
            onCancelContract={() => setSelectedPane('cancel')}
            onRenewContract={() => setSelectedPane('renew')}
            onLinkContract={() => setSelectedPane('link')}
            onSuccess={(id) => highlightNodeById(id, gridRef)}
            onAddCustomer={() => setSelectedPane('addCustomerFromEditContract')}
            onUnlinkContract={() => setSelectedPane('unlink')}
          />
        )}
        {rightPanes.delete && (
          <DeleteContracts
            selectedRows={selectedRows}
            onClose={() => {
              resetPanes();
              gridRef.current?.api.deselectAll();
            }}
            onGoBack={() => resetPanes()}
          />
        )}
        {rightPanes.cancel && (
          <CancelContracts
            selectedRows={selectedRows}
            onClose={() => gridRef.current?.api.deselectAll()}
            onGoBack={() => resetPanes()}
          />
        )}
        {rightPanes.renew && (
          <RenewContractPane
            selectedRows={selectedRows}
            onClose={() => {
              resetPanes();
            }}
            onGoBack={() => setSelectedPane('edit')}
          />
        )}
        {rightPanes.link && (
          <LinkContracts
            selectedRows={selectedRows}
            onClose={() => {
              resetPanes();
              gridRef.current?.api.deselectAll();
            }}
            onGoBack={() => setSelectedPane('edit')}
          />
        )}
        {rightPanes.unlink && (
          <UnlinkContract
            selectedRows={selectedRows}
            onClose={() => {
              resetPanes();
              gridRef.current?.api.deselectAll();
            }}
            onGoBack={() => setSelectedPane('edit')}
          />
        )}
      </RightPane>
      <RightPane isOpen={isAddTagPaneOpen}>
        <AddTag
          onClose={() => resetPanes()}
          onGoBack={handleAddTagGoBack}
          isIndirectOpen
        />
      </RightPane>
      <RightPane isOpen={isAddCustomerPaneOpen}>
        <AddCustomer
          onClose={() => resetPanes()}
          onGoBack={handleAddCustomerGoBack}
          isIndirectOpen
        />
      </RightPane>
    </>
  );
};
