import React, {
  Fragment,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router';
import {
  CartIcon,
  ChevronDown,
  CollapseIcon,
  CustomersIcon,
  DashboardIcon,
  ExpandIcon,
  FileIcon,
  FinancialAccountsIcon,
  FinancialDashboardsIcon,
  FinancialsIcon,
  HomeIcon,
  IntegrationsIcon,
  MetricsIcon,
  ReportsIcon,
} from 'assets/icons';
import {
  ChevronContainer,
  ItemContainer,
  StyledMenuItem,
  StyledMenuSubItem,
  StyledSidebar,
  SubItemsContainer,
  SubItemsTitle,
} from './styled';
import { useGetDashboardsQuery } from 'store/services/dashboards';
import { CompanyRevenueType } from 'interfaces/company';
import { featureSwitch } from 'utils/featureSwitch';
import { getCurrentCompany } from 'utils/currentCompany';
import { useGetAuthMeQuery } from 'store/services/auth';
import { UserRole } from 'interfaces/auth';

interface SidebarItem {
  icon?: JSX.Element;
  label: string;
  path: string;
  exact?: boolean;
  hidden?: boolean;
  prefetch?: () => void;
  subItems?: Array<SidebarItem>;
  action?: () => void;
  dataCy?: string;
};

export const Sidebar = () => {
  const location = useLocation();
  const defaultCollapsed = localStorage.getItem('defaultCollapsed');
  const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed === 'true');
  const [openSubMenus, setOpenSubMenus] = useState<string[]>([]);
  const { data: dashboards } = useGetDashboardsQuery();
  const { data: user } = useGetAuthMeQuery();
  const currentCompany = getCurrentCompany(user);

  const management = currentCompany?.companyRevenueType === CompanyRevenueType.SUBSCRIPTIONS
    ? {
      path: 'revenue-management',
      label: 'Revenue Management',
    } : {
      path: `contract-management`,
      label: 'Contract Management',
    };

  const setDefaultCollapsed = (collapsed: boolean) => localStorage.setItem('defaultCollapsed', `${collapsed}`);

  const handleCollapseClick = () => setIsCollapsed(!isCollapsed);

  const handleOpenSubMenu = (path: string, ev?: React.MouseEvent) => {
    ev && ev.preventDefault();
    setOpenSubMenus([...openSubMenus, path]);
  };

  const handleCloseSubMenu = (path: string, ev?: React.MouseEvent) => {
    ev && ev.preventDefault();
    setOpenSubMenus(openSubMenus.filter((subMenu) => subMenu !== path));
  };

  useEffect(() => {
    const topLevelItem = location.pathname.split('/')[1];
    handleOpenSubMenu(`/${topLevelItem}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    setDefaultCollapsed(isCollapsed);
  }, [isCollapsed]);

  const sidebarItems: Array<SidebarItem> = useMemo(() => ([
    {
      icon: <HomeIcon />,
      label: 'Home',
      path: '/',
      dataCy: 'sb-home',
      exact: true,
      hidden: currentCompany?.role === UserRole.VIEWER,
    },
    {
      icon: <DashboardIcon />,
      label: 'Dashboards',
      path: '/dashboards',
      dataCy: 'sb-dashboards',
      subItems: [
        {
          icon: <DashboardIcon />,
          label: 'Revenue Detail',
          path: '/dashboards/revenue-detail',
          dataCy: 'sb-reveue-detail',
          hidden: currentCompany?.role === UserRole.VIEWER,
        },
        {
          icon: <DashboardIcon />,
          label: 'Cohorts',
          path: '/dashboards/cohorts',
          dataCy: 'sb-cohorts',
          hidden: currentCompany?.role === UserRole.VIEWER,
        },
        {
          icon: <DashboardIcon />,
          label: 'Sales Commissions',
          path: '/dashboards/sales-commissions',
          dataCy: 'sb-sales-commissions',
          hidden: featureSwitch.isLocalDevelopment ? false : currentCompany?.id !== 'd14bef13-1b5b-4360-b962-0b646e6dd8a1',
        },
        {
          icon: <DashboardIcon />,
          label: 'Management Commission Report',
          path: '/dashboards/management-commissions',
          dataCy: 'sb-mgmt-commissions',
          hidden: (featureSwitch.isLocalDevelopment ? false : currentCompany?.id !== 'd14bef13-1b5b-4360-b962-0b646e6dd8a1') || currentCompany?.role === UserRole.VIEWER,
        },
        {
          icon: <DashboardIcon />,
          label: 'SDR Commissions',
          path: '/dashboards/sdr-commissions',
          dataCy: 'sb-sdr-commissions',
          hidden: featureSwitch.isLocalDevelopment ? false : currentCompany?.id !== 'd14bef13-1b5b-4360-b962-0b646e6dd8a1',
        },
        {
          icon: <DashboardIcon />,
          label: 'Comparison Chart',
          path: '/dashboards/comparison-chart',
          dataCy: 'sb-comparison-chart',
        },
        {
          icon: <FinancialDashboardsIcon />,
          label: 'Financial Dashboards',
          path: '/dashboards/financial-dashboards',
          hidden: dashboards && dashboards
            .filter((dashboard) => dashboard.group === 'financial-dashboards').length < 1,
          dataCy: 'sb-financial-dashboards',
          subItems: dashboards && dashboards
            .filter((dashboard) => dashboard.group === 'financial-dashboards')
            .map((dashboard) => ({
              label: dashboard.title,
              path: `/dashboards/financial-dashboards/${dashboard.path}`,
              icon: <DashboardIcon />,
              exact: true,
            })),
        },
        {
          icon: <FinancialsIcon />,
          label: 'Financial Reports',
          path: '/dashboards/financial-reports',
          hidden: dashboards && dashboards
            .filter((dashboard) => dashboard.group === 'financial-reports').length < 1,
          dataCy: 'sb-financial-reports',
          subItems: dashboards && dashboards
            .filter((dashboard) => dashboard.group === 'financial-reports')
            .map((dashboard) => ({
              label: dashboard.title,
              path: `/dashboards/financial-reports/${dashboard.path}`,
              icon: <FinancialsIcon />,
              exact: true,
            })),
        },
        {
          icon: <DashboardIcon />,
          label: 'SaaS Metrics',
          path: '/dashboards/saas',
          hidden: dashboards && dashboards
            .filter((dashboard) => dashboard.group === 'saas').length < 1,
          dataCy: 'sb-saas',
          subItems: dashboards && dashboards
            .filter((dashboard) => dashboard.group === 'saas')
            .map((dashboard) => ({
              label: dashboard.title,
              path: `/dashboards/saas/${dashboard.path}`,
              icon: <DashboardIcon />,
              exact: true,
            })),
        },
      ],
    },
    {
      icon: <ReportsIcon />,
      label: 'Reports',
      path: '/reports',
      dataCy: 'sb-reports',
      hidden: !featureSwitch.reports,
    },
    {
      icon: <MetricsIcon />,
      label: 'Metrics Library',
      path: '/metrics',
      dataCy: 'sb-metrics',
      hidden: currentCompany?.role === UserRole.VIEWER || !featureSwitch.metrics,
      subItems: [
        {
          icon: <MetricsIcon />,
          label: 'Metrics',
          path: '/metrics/metrics',
        },
        {
          icon: <MetricsIcon />,
          label: 'Manual Metrics',
          path: '/metrics/manual',
        },
      ],
    },
    {
      icon: <FinancialAccountsIcon />,
      label: 'Financial Accounts',
      path: '/accounts',
      dataCy: 'sb-financial-accounts',
      hidden: !featureSwitch.integrations || currentCompany?.role === UserRole.VIEWER,
      subItems: [
        {
          icon: <FinancialAccountsIcon />,
          label: 'Profit & Loss',
          path: '/accounts/account-mapping/profit_and_loss',
        },
        {
          icon: <FinancialAccountsIcon />,
          label: 'Balance Sheet',
          path: '/accounts/account-mapping/balance_sheet',
        },
        {
          icon: <FinancialAccountsIcon />,
          label: 'Spreadsheet Upload',
          path: '/accounts/upload',
        },
      ],
    },
    {
      icon: <IntegrationsIcon />,
      label: "Data Sources",
      path: `/data-input`,
      hidden: currentCompany?.role === UserRole.VIEWER,
      subItems: [
        {
          icon: <IntegrationsIcon />,
          label: 'Integrations',
          path: '/data-input/integrations',
        },
        {
          icon: <FileIcon />,
          label: management.label,
          path: `/data-input/${management.path}`,
          hidden: currentCompany?.role === UserRole.VIEWER,
          subItems: [
            {
              icon: <FileIcon />,
              label: 'Revenue',
              path: '/data-input/revenue-management/revenue',
              hidden: currentCompany?.companyRevenueType !== CompanyRevenueType.SUBSCRIPTIONS,
            },
            {
              icon: <FileIcon />,
              label: 'Contracts',
              path: '/data-input/contract-management/contracts',
              hidden: currentCompany?.companyRevenueType !== CompanyRevenueType.CONTRACTS || !featureSwitch.contracts,
            },
            {
              icon: <CustomersIcon />,
              label: 'Customers',
              path: `/data-input/${management.path}/customers`,
            },
            {
              icon: <CartIcon />,
              label: 'Products',
              path: `/data-input/${management.path}/products`,
            },
            {
              icon: <CartIcon />,
              label: 'Tags',
              path: `/data-input/${management.path}/tags`,
            },
          ],
        },
      ],
    },
  ]), [dashboards, management.label, management.path, currentCompany]);

  return (
    <StyledSidebar isCollapsed={isCollapsed}>
      {sidebarItems
        .filter(({ hidden }) => !hidden)
        .map(({ icon, label, path, exact, prefetch, subItems, dataCy }) => {
          const isActive = exact
            ? location.pathname === path
            : location.pathname.startsWith(path);

          const isSubMenuOpen = openSubMenus.includes(path);
          const subItemsVisible = subItems?.filter(({ hidden }) => !hidden) || [];
          const combinedLength = subItems
            ?.filter(({ hidden }) => !hidden)
            .reduce((acc, subItem) =>
              acc + (subItem.subItems?.filter(({ hidden }) => !hidden)?.length || 0)
              , 0) || 0;

          return (
            <ItemContainer
              key={label}
              isCollapsed={isCollapsed}
            >
              <StyledMenuItem
                to={path}
                $active={isActive}
                onMouseEnter={prefetch}
                data-cy={dataCy}
              >
                {icon}
                <span>
                  {label}
                </span>
                {!!subItemsVisible.length && (
                  <ChevronContainer
                    onClick={(ev) => isSubMenuOpen
                      ? handleCloseSubMenu(path, ev)
                      : handleOpenSubMenu(path, ev)
                    }
                    isOpen={isSubMenuOpen}
                  >
                    <ChevronDown />
                  </ChevronContainer>
                )}
              </StyledMenuItem>
              <SubItemsContainer height={subItemsVisible && (isSubMenuOpen) ? (combinedLength + subItemsVisible.length) * 49 : 0}>
                <SubItemsTitle
                  to={path}
                  $active={isActive}
                >
                  {label}
                </SubItemsTitle>
                {subItemsVisible
                  .map((subItem) => (
                    <Fragment key={subItem.label}>
                      <StyledMenuSubItem
                        to={subItem.path}
                        $active={subItem.exact ? location.pathname === subItem.path : location.pathname.startsWith(subItem.path)}
                        onMouseEnter={subItem.prefetch}
                      >
                        <span>
                          {subItem.label}
                        </span>
                      </StyledMenuSubItem>
                      {(subItem.subItems && subItem.subItems.length > 0) && (
                        subItem.subItems
                          .filter(({ hidden }) => !hidden)
                          .map((subSubItem) => (
                            <StyledMenuSubItem
                              key={subSubItem.label}
                              to={subSubItem.path}
                              $active={subSubItem.exact ? location.pathname === subSubItem.path : location.pathname.startsWith(subSubItem.path)}
                              onMouseEnter={subSubItem.prefetch}
                              $pushRight
                              $isCollapsed={isCollapsed}
                            >
                              <span>
                                {subSubItem.label}
                              </span>
                            </StyledMenuSubItem>
                          ))
                      )}
                    </Fragment>
                  ))}
              </SubItemsContainer>
            </ItemContainer>
          );
        })}
      <StyledMenuItem
        onClick={handleCollapseClick}
        to="#"
        $pushDown
      >
        {isCollapsed
          ? <ExpandIcon />
          : <CollapseIcon />
        }
        <span>Collapse</span>
      </StyledMenuItem>
    </StyledSidebar>
  );
};