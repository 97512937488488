import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { theme } from 'theme/theme';
import { CheckCircleIcon } from 'assets/icons';
import { FactaTable } from 'components/FactaTable';
import {
  LeftPane,
  RightPane,
} from 'components/Layout';
import { Loading } from 'components/Loading';
import { SavingIndicator } from 'components/SavingIndicator';
import { Box } from 'components/common/Box';
import {
  ButtonsContainer,
  Divider,
  Spacer,
} from 'components/common/ButtonsContainer';
import { SectionLabel } from 'components/common/SectionLabel';
import { FinancialType } from 'interfaces/financials';
import {
  Breadcrumb,
  Breadcrumbs,
} from 'components/common/Breadcrumbs';
import { Button } from 'components/common/Button';
import {
  useGetAccountMappingsQuery,
  useGetAccountsByFinancialTypeQuery,
  usePutAccountMappingTreeMutation,
} from 'store/services/accounts';
import {
  createAccountMappingFinancialsColumns,
  mapAccountsToTreeView,
  prepareAccountMappingTreePayload,
} from 'utils/accounts';
import {
  AgGridReact,
  AgGridReactProps,
} from '@ag-grid-community/react';
import {
  CellClassParams,
  ColDef,
  GridApi,
  GridOptions,
  IRowNode,
} from '@ag-grid-community/core';
import { RowDragEvent } from '@ag-grid-community/core';
import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router';
import { EditAccount } from './EditAccount';
import { StyledBox } from './styled';
import { Deleted } from 'components/common/Deleted';
import { Tooltip } from 'components/common/Tooltip';
import structuredClone from '@ungap/structured-clone';
import { usePopper } from 'react-popper';
import { MoveSelected } from './MoveSelected';
import { useDraft } from 'hooks/useDraft';
import { useDebouncedCallback } from 'use-debounce';
import { DRAFT_SAVE_INTERVAL } from 'utils/constants';
import { DraftFound } from 'components/common/DraftFound';
import {
  toastSuccess,
  toastWarning,
} from 'utils/toast';
import { AddFactaAccount } from './AddFactaAccount';
import { InactiveGLAccounts } from '../common/InactiveGLAccounts';
import { accountMappingActions } from 'store/slices/accountMapping';
import {
  useAppDispatch,
  useAppSelector,
} from 'hooks/redux';
import { viewBySelector } from 'store/selectors/accountMappings';
import { PeriodType } from 'interfaces/accounts';
import { highlightNodeById } from 'utils/aggrid';
import { Company } from 'interfaces/company';

interface Props {
  currentCompany: Company;
}

export const AccountMappingTreeView = ({ currentCompany }: Props) => {
  const { financialType = FinancialType.PROFIT_AND_LOSS } = useParams<{ financialType: FinancialType }>();
  return <AccountMappingTreeViewComponent
    key={financialType}
    currentCompany={currentCompany}
  />;
};

const AccountMappingTreeViewComponent = ({ currentCompany }: Props) => {
  const gridRef = useRef<AgGridReact>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { financialType = FinancialType.PROFIT_AND_LOSS } = useParams<{ financialType: FinancialType }>();
  const [isDirty, setIsDirty] = useState(false);
  const [accountMappings, setAccountMappings] = useState<any[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [uncategorizedAccounts, setUncategorizedAccounts] = useState(0);
  const [potentialParent, setPotentialParent] = useState<any>(null);
  const [isMoveSelectedVisible, setIsMoveSelectedVisible] = useState(false);
  const [isAddFactaAccountVisible, setIsAddFactaAccountVisible] = useState(false);
  const [isDraftApplicable, setIsDraftApplicable] = useState(true);
  const [includeUnused, setIncludeUnused] = useState(false);
  const [numberOfGLAccounts, setNumberOfGLAccount] = useState(0);
  const [moveSelectedReferenceElement, setMoveSelectedReferenceElement] = useState<HTMLButtonElement | null>(null);
  const [addFactaAccountReferenceElement, setAddFactaAccountReferenceElement] = useState<HTMLButtonElement | null>(null);
  const [moveSelectedPopperElement, setMoveSelectedPopperElement] = useState<HTMLDivElement | null>(null);
  const [addFactaAccountPopperElement, setAddFactaAccountPopperElement] = useState<HTMLDivElement | null>(null);
  const [columns, setColumns] = useState<any[]>([]);
  const setViewBy = (period: PeriodType) => dispatch(accountMappingActions.toggleViewBy(period));
  const viewBy = useAppSelector(viewBySelector);
  const moveSelected = usePopper(moveSelectedReferenceElement, moveSelectedPopperElement, {
    placement: 'bottom-start',
    strategy: 'fixed',
  });
  const addFactaAccount = usePopper(addFactaAccountReferenceElement, addFactaAccountPopperElement, {
    placement: 'bottom-start',
    strategy: 'fixed',
  });
  const currencyCode = currentCompany?.currency.code || 'USD';

  const [putAccountMapping, { isLoading: isUpdatingAccountMapping }] = usePutAccountMappingTreeMutation();
  const {
    data: accountMappingsData,
    isFetching: isLoadingAccountMappings,
    refetch,
  } = useGetAccountMappingsQuery({
    financialType,
  }, { refetchOnMountOrArgChange: true });

  const { data: accountsData, isLoading: isLoadingAccounts } = useGetAccountsByFinancialTypeQuery(financialType);

  const {
    clearDraft,
    draft,
    draftFound,
    isSavingDraft,
    saveDraft,
    setDraftFound,
  } = useDraft(`account_mapping_tree_${financialType}`);

  useEffect(() => {
    if (accountMappingsData && accountsData?.length) {
      setAccountMappings(mapAccountsToTreeView(accountsData, accountMappingsData));
    }
  }, [accountMappingsData, accountsData]);

  const cellClassRules = useMemo(() => ({
    'hover-over': ({ node }: CellClassParams) => {
      return node === potentialParent;
    },
    'cell-red': ({ node }: CellClassParams) => {
      return node.data?.path[0] === 'Uncategorized';
    },
  }), [potentialParent]);

  const handleSaveDraft = () => {
    let items: any[] = [];
    gridRef.current?.api.forEachNode((node) => {
      items.push({ ...node.data });
    });
    saveDraft(items);
  };

  const handleDebouncedSaveDraft = useDebouncedCallback(handleSaveDraft, DRAFT_SAVE_INTERVAL);

  const refreshRows = (api: GridApi, rowsToRefresh: IRowNode[]) => {
    api.refreshCells({
      rowNodes: rowsToRefresh,
      force: true,
    });
  };

  const updateUncategorized = useCallback(() => {
    let uncategorizedCount = 0;

    gridRef.current?.api.forEachNode((node) => {
      if (node.data?.type === 'gl_account' && node.data?.path[0] === 'Uncategorized') {
        if (!includeUnused && !node.data?.used) return;

        uncategorizedCount++;
      }
    });

    setUncategorizedAccounts(uncategorizedCount);
  }, [includeUnused]);

  const onRowDragMove = ({ node, overNode, api }: RowDragEvent) => {
    let newPotentialParent;

    if (overNode && node.data?.type === 'gl_account') {
      newPotentialParent =
        overNode.data.type === 'facta_account_name' || overNode.data.type === 'uncategorized'
          ? overNode
          : overNode.parent;
    } else if (overNode && node.data?.type === 'facta_account_name') {
      newPotentialParent =
        overNode.data.type === 'facta_account_type'
          ? overNode
          : overNode.parent;
    } else {
      newPotentialParent = null;
    }

    const alreadySelected = potentialParent === newPotentialParent;

    if (alreadySelected) {
      return;
    }

    const rowsToRefresh = [];

    if (potentialParent) {
      rowsToRefresh.push(potentialParent);
    }

    if (newPotentialParent) {
      rowsToRefresh.push(newPotentialParent);
    }

    setPotentialParent(newPotentialParent);
    refreshRows(api, rowsToRefresh);
  };

  const moveGLNodesToNewFANode = useMemo(() => (sources: IRowNode[], destiny?: IRowNode | null) => {
    let updated: any = [];
    if (!destiny) return;

    sources.forEach((source) => {
      if (source.data?.type !== 'gl_account' || destiny.data?.type === 'facta_account_type') return;

      updated.push({
        ...source.data,
        path: [...destiny.data.path, source.data.id],
      });
    });

    gridRef.current?.api.deselectAll();
    destiny.setExpanded(true);
    gridRef.current?.api.applyTransaction({
      update: updated,
    });

    handleDebouncedSaveDraft();
    setIsDirty(true);
    gridRef.current?.api.refreshCells();
  }, [handleDebouncedSaveDraft]);

  const moveFANodesToNewCategoryNode = useMemo(() => (sources: IRowNode[], destiny?: IRowNode | null, source?: IRowNode) => {
    if (destiny?.childrenAfterGroup?.map(({ key }) => key)
      .includes(source?.key || '')) {
      toastWarning(`Cannot move ${source?.key} from ${source?.parent?.key} to ${destiny.key} because it already exists there.`);
      return;
    }

    let updated: any = [];
    if (!destiny) return;

    sources.forEach((source) => {
      updated.push({
        ...source.data,
        isNew: true,
        path: [...destiny.data.path, source.data.path[1], ...(source.data.path[2] ? [source.data.path[2]] : [])],
      });
    });

    gridRef.current?.api.deselectAll();
    destiny.setExpanded(true);
    gridRef.current?.api.applyTransaction({
      update: updated,
    });

    handleDebouncedSaveDraft();
    setIsDirty(true);
    gridRef.current?.api.refreshCells();
  }, [handleDebouncedSaveDraft]);

  const onRowDragEnd = useMemo(() => ({ node, nodes, overNode }: RowDragEvent) => {
    setPotentialParent(null);

    if (overNode?.data.type === 'total') return;

    const sources = nodes;

    if (node?.data.type === 'gl_account') {
      const destiny = overNode?.data.type === 'gl_account'
        ? overNode?.parent
        : overNode;
      moveGLNodesToNewFANode(sources, destiny);
    }

    if (node?.data.type === 'facta_account_name' && overNode?.data.type !== 'uncategorized' && overNode?.parent?.data?.type !== 'uncategorized') {
      const sourceChildren = node.childrenAfterGroup || [];
      const destiny = overNode?.data.type === 'facta_account_name'
        ? overNode?.parent
        : overNode;

      moveFANodesToNewCategoryNode([node, ...sourceChildren], destiny, node);
    }

  }, [moveFANodesToNewCategoryNode, moveGLNodesToNewFANode]);

  const autoGroupColumnDef = useMemo((): ColDef => ({
    headerName: "Mapping",
    editable: (params: any) => params.data?.type === 'facta_account_name' || (params.data?.type === 'gl_account' && params.data.source === 'manual'),
    cellEditorParams: (params: any) => ({
      value: params.data?.type === 'gl_account' ? params.data.glAccountDisplayName : params.value,
    }),
    resizable: true,
    sortable: false,
    sort: 'asc',
    valueGetter: (params) => params.data?.type === 'gl_account' ? params.data.glAccountDisplayName : params.node?.key,
    comparator: (_, __, nodeA, nodeB) => {
      switch (nodeA.level) {
        case 1:
          return nodeA.key?.localeCompare(nodeB.key!) || 0;
        case 2:
          return nodeA.data.glAccountDisplayName?.localeCompare(nodeB.data.glAccountDisplayName!) || 0;
        default:
          return 0;
      }
    },
    cellRendererParams: {
      suppressCount: true,
      checkbox: (params: any) => params.data?.type === 'gl_account' || params.data?.type === 'facta_account_name',
      innerRenderer: (params: any) => (
        <span style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span>
            {params.data?.type !== 'gl_account'
              ? params.value
              : params.data.glAccountDisplayName}
            {params.data?.type === 'gl_account' && (
              <Tooltip
                title="Source"
                position="right"
                inline
              >
                <Deleted>
                  {params.data.source}
                </Deleted>
              </Tooltip>
            )}
          </span>
          {(params.node.data?.type === 'facta_account_name' || (params.node.data?.type === 'gl_account' && params.node.data?.source === 'manual')) && (
            <span>
              <EditAccount
                onRemove={() => {
                  setIsDirty(true);
                  gridRef.current?.api.refreshCells();
                }}
                params={params}
              />
            </span>
          )}
        </span>
      ),
    },
    cellClassRules: {
      ...cellClassRules,
      'cell-full-width': () => true,
    },
    flex: 1,
    minWidth: 700,
    pinned: 'left',
  }), [cellClassRules]);

  const gridOptions: GridOptions = useMemo(() => ({
    rowClassRules: {
      'total-row': ({ data }: any) => data?.type === 'total',
      'informative-row': ({ data }: any) => data?.type === 'informative',
    },
    onSelectionChanged: (e) => setSelectedRows(e.api.getSelectedRows()),
    suppressRowClickSelection: true,
    groupDefaultExpanded: 1,
    enableRangeSelection: true,
    onRowDragEnd: onRowDragEnd,
    rowDragMultiRow: true,
    isRowSelectable: (params) => params.data?.type === 'gl_account' || params.data?.type === 'facta_account_name',
    icons: {
      rowDrag: '<i class="ag-icon ag-icon-columns"/>',
    },
    onColumnRowGroupChanged: undefined,
    onRowGroupOpened: undefined,
    undoRedoCellEditing: true,
    undoRedoCellEditingLimit: 10,
    tooltipShowDelay: 1000,
    statusBar: {
      statusPanels: [
        { statusPanel: 'agAggregationComponent' },
      ],
    },
    onModelUpdated: () => setNumberOfGLAccount(countNumberOfGLAccounts()),
    treeData: true,
    treeDataDisplayType: 'auto',
    getDataPath: (data: any) => {
      if (!data) return [];
      return data.path;
    },
    enableGroupEdit: true,
    readOnlyEdit: true,
    excludeChildrenWhenTreeDataFiltering: true,
    onCellEditingStopped: (params) => {
      const { node, api } = params;
      const newValue = params.newValue?.trim() || '';

      if (node.groupData && newValue && node.data?.type === 'facta_account_name') {
        const coreNode = structuredClone(node.data);
        coreNode.path = [node.data?.path[0], newValue];

        let subNodes: any[] = [];

        gridRef.current?.api.forEachNode((n) => {
          if (n.data.path[0] === node.data?.path[0] && n.data.path[1] === node.data?.path[1] && n !== node) {
            subNodes.push({ ...n.data, path: [n.data.path[0], newValue, n.data.path[2]] });
          }
        });

        api.applyTransaction({
          update: [coreNode, ...subNodes],
        });

        handleDebouncedSaveDraft();
        setIsDirty(true);
      }

      if (node.groupData && newValue && node.data?.type === 'gl_account') {
        const coreNode = structuredClone(node.data);
        coreNode.glAccountDisplayName = newValue;
        coreNode.glAccountName = newValue;

        api.applyTransactionAsync({
          update: [coreNode],
        }, () => {
          api.refreshCells({ force: true });
        });

        handleDebouncedSaveDraft();
        setIsDirty(true);
      }
    },
    groupSelectsChildren: false,
    onRowSelected: ({ node }) => {
      if (!!node.allChildrenCount) {
        node.allLeafChildren.map((childNode) => childNode.setSelected(!!node.isSelected()));
      }
      if (node.isSelected() === false) {
        node.parent?.setSelected(false);
      }
    },
  }), [handleDebouncedSaveDraft, onRowDragEnd]);

  const updateInactiveGLAccounts = useCallback((api: GridApi) => {
    api.deselectAll();
    api.setFilterModel(includeUnused
      ? {}
      : {
        used: {
          filterType: 'set',
          values: ['true'],
        },
      });

  }, [includeUnused]);

  const dynamicGridOptions: AgGridReactProps = {
    autoGroupColumnDef: autoGroupColumnDef,
    onRowDragMove: onRowDragMove,
    onRowDataUpdated({ api }) {
      updateInactiveGLAccounts(api);
      updateUncategorized();
    },
  };

  const columnDefs: ColDef[] = useMemo(() => {
    return [
      {
        lockPosition: 'left',
        field: '',
        headerName: '',
        maxWidth: 52,
        rowDrag: (params: any) => params.node.data?.type === 'gl_account' || params.node.data?.type === 'facta_account_name',
        cellClassRules: cellClassRules,
        pinned: 'left',
        sortable: false,
      },
      {
        field: 'used',
        headerName: 'used',
        hide: true,
        filter: true,
      },
      ...columns,
    ] as ColDef[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  const handleRemoveSelectedGLAccounts = () => {
    const nodes = gridRef.current?.api.getSelectedNodes();
    if (nodes?.length) {
      gridRef.current?.api.applyTransaction({
        remove: nodes,
      });
      setIsDirty(true);
      gridRef.current?.api.refreshCells();
    }
  };

  const handleRevertChanges = () => {
    refetch();
    clearDraft();
    setIsDirty(false);
  };

  const handleSuccess = () => {
    const typeName = financialType === FinancialType.PROFIT_AND_LOSS
      ? 'Profit & Loss'
      : 'Balance Sheet';

    clearDraft();
    setIsDirty(false);
    toastSuccess(`${typeName} Account Mapping successfully updated.`);
  };

  const handleSave = async () => {
    gridRef.current?.api.stopEditing();

    const items: any[] = [];
    gridRef.current?.api.forEachNode((node) => {
      items.push(node.data);
    });

    clearDraft();

    const payload = prepareAccountMappingTreePayload(items);
    await putAccountMapping({ financialType, payload })
      .unwrap();
    handleSuccess();
  };

  const handleToggleMoveSelected = () => {
    setIsMoveSelectedVisible(!isMoveSelectedVisible);

    if (moveSelected.update) {
      moveSelected.update();
    }
  };

  const handleToggleAddFactaAccount = () => {
    setIsAddFactaAccountVisible(!isAddFactaAccountVisible);

    if (addFactaAccount.update) {
      addFactaAccount.update();
    }
  };

  const handleOnMoveGLAccounts = (node: IRowNode) => {
    const sources = gridRef.current?.api.getSelectedNodes();

    if (sources) {
      moveGLNodesToNewFANode(sources, node);
    }
  };

  const handleOnMoveFactaAccounts = (node: IRowNode) => {
    const sources = gridRef.current?.api.getSelectedNodes()
      .filter((node) => node.data?.path.length === 2 && node.data?.path[0] !== 'Uncategorized');
    const sourcesChildren = sources?.flatMap((source) => source.childrenAfterGroup || []) || [];

    if (sources) {
      moveFANodesToNewCategoryNode([...sources, ...sourcesChildren], node);
    }
  };

  const handleApplyDraft = () => {
    setTimeout(() => {
      gridRef.current?.api.setGridOption('rowData', structuredClone(draft || []));
      setDraftFound(false);
      setIsDirty(true);
    }, 100);
  };

  const handleDismissDraft = () => {
    clearDraft();
    setDraftFound(false);
    setIsDraftApplicable(true);
  };

  const isDeleteDisabled = useMemo(() => !!selectedRows.find((row) => row.type === 'gl_account' && row.source !== 'manual'), [selectedRows]);

  const countNumberOfGLAccounts = () => {
    let number = 0;
    gridRef.current?.api?.forEachNodeAfterFilter((node) => {
      if (node.data?.type === 'gl_account') number++;
    });

    return number;
  };

  useEffect(() => {
    if (draft && Array.isArray(draft) && accountMappingsData?.accountMappings?.length) {
      const localAccounts = accountMappingsData?.accountMappings?.map((am) => am.glAccount.name)
        .sort()
        .toString();

      const draftAccounts = draft.filter((da) => da.type === 'gl_account')
        .map((da) => da.glAccountName)
        .sort()
        .toString();

      setIsDraftApplicable(localAccounts === draftAccounts);
    }
  }, [accountMappingsData, draft]);

  useEffect(() => {
    if (gridRef.current?.api && accountMappingsData?.accountMappings?.length) {
      setColumns(createAccountMappingFinancialsColumns(accountMappingsData, viewBy, currencyCode));
    }
  }, [gridRef.current?.api, accountMappingsData, viewBy, currencyCode]);

  useEffect(() => {
    if (gridRef.current?.api) {
      updateInactiveGLAccounts(gridRef.current.api);
    }
  }, [updateInactiveGLAccounts]);

  useEffect(() => {
    if (gridRef.current?.api) {
      updateUncategorized();
    }
  }, [updateUncategorized]);

  useEffect(() => {
    if (location.hash) {
      containerRef?.current?.scrollTo(0, 9999999);
      setTimeout(() => {
        const id = location.hash.replace('#', '');
        const newRowNode = gridRef.current?.api.getRowNode(id)!;
        gridRef.current?.api.ensureNodeVisible(newRowNode);
        highlightNodeById(id, gridRef);
        location.hash = '';
      }, 1000);
    }
  }, [location]);

  return (
    <>
      <LeftPane _ref={containerRef}>
        <Breadcrumbs>
          <Breadcrumb link="/accounts">
            Financial Accounts
          </Breadcrumb>
          <Breadcrumb>
            {financialType === FinancialType.PROFIT_AND_LOSS ? 'Profit & Loss Account Mapping' : 'Balance Sheet Account Mapping'}
          </Breadcrumb>
        </Breadcrumbs>
        <ButtonsContainer>
          <SectionLabel marginBottom={30}>
            <span>
              {financialType === FinancialType.PROFIT_AND_LOSS ? 'Profit & Loss Account Mapping' : 'Balance Sheet Account Mapping'}
              <ins>Tree view</ins>
              <p>Create Facta Accounts to map uncategorized GL accounts into simplified views for your business.</p>
            </span>
          </SectionLabel>
          <Spacer />
          <Tooltip title="Switch to Table View">
            <Button
              minWidth={100}
              variant="borderless"
              size="large"
              onClick={() => navigate(`/accounts/account-mapping/table-view/${financialType}`)}
            >
              Table View
            </Button>
          </Tooltip>
        </ButtonsContainer>
        {isLoadingAccountMappings || isLoadingAccounts
          ? (
            <Loading />
          )
          : (
            <StyledBox>
              <Box
                condensed
                marginBottom={16}
              >
                <ButtonsContainer>
                  <div>&nbsp;&nbsp;{numberOfGLAccounts} GL Accounts</div>
                  <Divider />
                  <div>{uncategorizedAccounts} Uncategorized</div>
                  {uncategorizedAccounts === 0 && <CheckCircleIcon color={theme.colors.success} />}
                  <Spacer />
                  <SavingIndicator isSaving={isSavingDraft}>Saving Draft...</SavingIndicator>
                  <Button
                    variant="borderless"
                    onClick={handleRevertChanges}
                  >
                    Revert Changes
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleSave}
                    minWidth={175}
                    disabled={isUpdatingAccountMapping || !isDirty}
                    isLoading={isUpdatingAccountMapping}
                  >
                    SAVE CHANGES
                  </Button>
                </ButtonsContainer>
              </Box>
              {isMoveSelectedVisible && (
                <MoveSelected
                  popperRef={setMoveSelectedPopperElement}
                  style={moveSelected.styles.popper}
                  attributes={moveSelected.attributes}
                  gridRef={gridRef}
                  onClose={() => setIsMoveSelectedVisible(false)}
                  onMoveGLAccounts={handleOnMoveGLAccounts}
                  onMoveFactaAccounts={handleOnMoveFactaAccounts}
                />
              )}
              {isAddFactaAccountVisible && (
                <AddFactaAccount
                  popperRef={setAddFactaAccountPopperElement}
                  style={addFactaAccount.styles.popper}
                  attributes={addFactaAccount.attributes}
                  gridRef={gridRef}
                  onClose={() => setIsAddFactaAccountVisible(false)}
                />
              )}
              <FactaTable
                gridRef={gridRef}
                data={accountMappings}
                columnDefs={columnDefs}
                selectedRowsLength={selectedRows.length}
                entityName={'account'}
                gridOptions={gridOptions}
                dynamicGridOptions={dynamicGridOptions}
                condensed
                suppressQuantityDisplay
                onClickDelete={handleRemoveSelectedGLAccounts}
                isDeleteDisabled={isDeleteDisabled}
                deleteDisabledTooltip="Some of selected GL accounts are coming from General Ledger and cannot be deleted. Only manually entered accounts can be deleted."
                customButtons={[
                  {
                    ref: setMoveSelectedReferenceElement,
                    title: 'Move Selected',
                    onClick: handleToggleMoveSelected,
                    variant: 'borderless',
                    size: 'large',
                    hidden: !selectedRows.length,
                  },
                  {
                    ref: setAddFactaAccountReferenceElement,
                    title: 'Add Facta Account',
                    onClick: handleToggleAddFactaAccount,
                    variant: 'borderless',
                    size: 'large',
                    divider: true,
                  },
                  {
                    title: 'View by:',
                    pushRight: true,
                  },
                  {
                    title: 'Month',
                    onClick: () => setViewBy('monthly'),
                    active: viewBy.includes('monthly'),
                    variant: 'borderless',
                    size: 'large',
                  },
                  {
                    title: 'Quarter',
                    onClick: () => setViewBy('quarterly'),
                    active: viewBy.includes('quarterly'),
                    variant: 'borderless',
                    size: 'large',
                  },
                  {
                    title: 'Year',
                    onClick: () => setViewBy('annual'),
                    active: viewBy.includes('annual'),
                    variant: 'borderless',
                    size: 'large',
                  },
                ]}
                customEmptyState={() => <>Nothing to show</>}
                onResetView={() => setViewBy('monthly')}
                onClearAllFilters={() => setIncludeUnused(false)}
                clearAllFiltersExcludeProperty="used"
                customJSX={<InactiveGLAccounts
                  setIncludeUnused={setIncludeUnused}
                  includeUnused={includeUnused}
                />}
                suppressColumnViews
              />
            </StyledBox>
          )}
      </LeftPane>
      <RightPane isOpen={draftFound}>
        <DraftFound
          isDraftApplicable={isDraftApplicable}
          onApplyDraft={handleApplyDraft}
          onDismissDraft={handleDismissDraft}
        />
      </RightPane>
    </>
  );
};
