import {
  BaseQueryFn,
  createApi,
} from '@reduxjs/toolkit/query/react';
import { Auth } from 'aws-amplify';
import axios, {
  AxiosError,
  AxiosRequestConfig,
  Method,
} from 'axios';
import { OnProgress } from 'interfaces/api';
import { tagTypes } from './api-tags';
import { toast } from 'react-toastify';

interface IAxiosBaseQuery {
  baseUrl?: string;
  headers?: (headers: { [key: string]: string }) => { [key: string]: string };
}

interface IBaseQuery {
  url: string;
  params?: { [key: string]: string | number };
  method?: Method;
  data?: AxiosRequestConfig['data'];
  body?: AxiosRequestConfig['data'];
  headers?: AxiosRequestConfig['headers'];
  onProgress?: OnProgress;
  error?: {
    status: number;
    data: string;
  };
}

export const axiosBaseQuery = ({
  baseUrl = '',
}: IAxiosBaseQuery): BaseQueryFn<IBaseQuery, unknown, {
  status?: number;
  body?: unknown;
  error?: {
    status: number | string;
    data: unknown;
  };
}
> => {
  return async ({ url, params, method = 'GET', body, headers, onProgress }) => {
    let toastMessage = null;
    let isDisregarded = false;

    setTimeout(() => {
      if(!isDisregarded){
        toastMessage = toast.loading(`Loading...`, {
          position: 'bottom-left',
          hideProgressBar: true,
        });
      }
    }, 1000);

    try {
      const startedAt = Date.now();
      const user = await Auth.currentUserPoolUser();
      const token = user.getSignInUserSession()
        .getIdToken()
        .getJwtToken();

      const result = await axios({
        // @ts-ignore
        url: (window.tempApiUrl || baseUrl) + url,
        method: method,
        headers: {
          'Authorization': 'Bearer ' + token,
          ...headers,
        },
        ...(params && { params }),
        ...(body && { data: body }),
        ...(onProgress && {
          onUploadProgress: (progressEvent) => {
            const { loaded, total = 99999999999 } = progressEvent;
            const percent = (loaded / total) * 100;
            const timeElapsed = Date.now() - startedAt;
            const uploadSpeed = loaded / timeElapsed;
            const remaining = (total - loaded) / uploadSpeed;

            onProgress(percent, remaining);
          },
        }),
      });

      isDisregarded = true;

      if (toastMessage) {
        toast.update(toastMessage, {
          render: `Loading finished.`,
          type: 'success',
          isLoading: false,
          autoClose: 1000,
        });
      }

      return {
        data: result.data,
      };
    } catch (axiosError) {
      const err = axiosError as AxiosError;

      isDisregarded = true;

      if (toastMessage) {
        toast.update(toastMessage, {
          render: `Loading failed.`,
          type: 'error',
          isLoading: false,
          autoClose: 8000,
        });
      }

      if ((err as any) === 'No current user') {
        return {
          error: {
            status: 401,
            data: 'No current user',
          },
        };
      }

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data,
        },
      };
    }
  };
};

export const api = createApi({
  tagTypes,
  baseQuery: axiosBaseQuery({
    baseUrl: import.meta.env.VITE_APP_API_URL!,
  }),
  endpoints: () => ({}),
});

export const invalidateAllTags = api.util.invalidateTags(tagTypes);
export const clearApiState = api.util.resetApiState();
export const invalidateTags = (tags: any[]) => api.util.invalidateTags(tags);
