export const CohortsMetricsOptions = [
  { name: 'Revenue', value: 'revenue' },
  { name: 'New', value: 'new' },
  { name: 'Upgrade', value: 'upgrade' },
  { name: 'New + Upgrade', value: 'newUpgrade' },
  { name: 'Downgrade', value: 'downgrade' },
  { name: 'Net Expansion', value: 'netExpansion' },
  { name: 'Churned (Downgrade + Cancelled)', value: 'churned' },
  { name: 'Cancelled', value: 'cancelled' },
  { name: 'Net New Revenue', value: 'netNewRevenue' },
  { name: 'New Logos', value: 'newLogos', dontMultiply: true },
  { name: 'Churned Logos', value: 'churnedLogos', dontMultiply: true },
  { name: 'Active Logos', value: 'activeLogos', dontMultiply: true },
  { name: 'Active + Inactive Logos', value: 'activeInactiveLogos', dontMultiply: true },
];

export const CohortDimensionOptionsContracts = [
  { name: 'Customer Start Date' },
  { name: 'Customer' },
  { name: 'Customer Tags' },
  { name: 'Product' },
  { name: 'Product Tags' },
  { name: 'Contract' },
  { name: 'Contract Tags' },
  { name: 'Contract Line Tags' },
  { name: 'None' },
];

export const CohortDimensionOptionsSubscriptions = [
  { name: 'Customer Start Date' },
  { name: 'Customer' },
  { name: 'Customer Tags' },
  { name: 'Product' },
  { name: 'Product Tags' },
  { name: 'Subscription Tags' },
  { name: 'None' },
];

export const CohortTypeOptions = [
  { name: 'By Period', value: 'byPeriod' },
  { name: 'Since Inception', value: 'byInception' },
];
