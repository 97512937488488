import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  LeftPane,
  RightPane,
} from 'components/Layout';
import { AddSubscription } from './AddSubscription';
import { SectionLabel } from 'components/common/SectionLabel';
import {
  ButtonsContainer,
  Spacer,
} from 'components/common/ButtonsContainer';
import { Button } from 'components/common/Button';
import {
  DownloadIcon,
  RefreshIcon,
  UploadIcon,
} from 'assets/icons';
import { AgGridReact } from '@ag-grid-community/react';
import { useGetAllSubscriptionsQuery } from 'store/services/subscriptions';
import { getColumnDefs } from './columnDefinitions';
import { GridOptions } from '@ag-grid-community/core';
import { EditSubscriptions } from './EditSubscriptions';
import { DeleteSubscriptions } from './DeleteSubscriptions';
import { Subscription } from 'interfaces/subscriptions';
import { ContextMenu } from 'components/ContextMenu';
import { BulkImportPanel } from 'components/BulkImport/BulkImportPanel';
import { useRightPanes } from 'hooks/useRightPanes';
import { CancelSubscriptions } from './CancelSubscriptions';
import { RenewSubscription } from './RenewSubscription';
import { featureSwitch } from 'utils/featureSwitch';
import {
  highlightNodeById,
  reselectNodeById,
} from 'utils/aggrid';
import { AddTag } from 'pages/Tags/AddTag';
import { Tooltip } from 'components/common/Tooltip';
import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router';
import { FactaTable } from 'components/FactaTable';
import { AddCustomer } from 'pages/Customers/AddCustomer';
import { AddProduct } from 'pages/Products/AddProduct';
import { Tabs } from 'components/common/Tabs';
import {
  Breadcrumb,
  Breadcrumbs,
} from 'components/common/Breadcrumbs';
import { LinkSubscription } from './LinkSubscription';
import { UnlinkSubscription } from './UnlinkSubscription';
import { handleDownloadTemplate } from 'utils/bulkImport';
import { CompanyRevenueType } from 'interfaces/company';

interface Props {
  setCustomMarkerData: (customData: {}) => void;
}

export const Subscriptions = ({ setCustomMarkerData }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const gridRef = useRef<AgGridReact>(null);
  const [selectedRows, setSelectedRows] = useState<Subscription[]>([]);
  const importPath = '/data-input/revenue-management/revenue/import';
  const { revenueType = '' } = useParams();
  const columnDefs = getColumnDefs(revenueType);

  const entityName = revenueType !== "all"
    ? `${revenueType === 'Nonrecurring' ? 'Non-recurring' : revenueType} Revenue`
    : `Revenue`;

  const { rightPanes, setSelectedPane, resetPanes } = useRightPanes({
    add: false,
    edit: true,
    delete: false,
    bulkImport: false,
    cancelSubscriptions: false,
    renewSubscription: false,
    addTagFromAddSubscription: false,
    addTagFromEditSubscription: false,
    addTagFromRenewSubscription: false,
    addCustomerFromAddSubscription: false,
    addCustomerFromEditSubscription: false,
    addProductFromAddSubscription: false,
    addProductFromEditSubscription: false,
    link: false,
    unlink: false,
  });

  const gridOptions: GridOptions = {
    onSelectionChanged: (e) => {
      const selectedRows = e.api.getSelectedRows();
      setSelectedRows(selectedRows);
      if (selectedRows.length) {
        resetPanes();
      }
    },
    enableCharts: true,
    statusBar: {
      statusPanels: [
        { statusPanel: 'agAggregationComponent' },
      ],
    },
  };

  const handleBulkImport = () => {
    const hideInstructions = localStorage.getItem('hideInstructions') === 'true';
    gridRef.current?.api.deselectAll();
    if (hideInstructions) {
      navigate(importPath);
    } else {
      setSelectedPane('bulkImport');
    }
  };

  const handleAddSubscription = () => {
    gridRef.current?.api.deselectAll();
    setSelectedPane('add');
  };

  const handleTriggerDelete = () => {
    setSelectedPane('delete');
  };

  const handleTriggerCancel = () => {
    setSelectedPane('cancelSubscriptions');
  };

  const handleTriggerRenew = () => {
    setSelectedPane('renewSubscription');
  };

  const handleCSVDownload = () => gridRef.current?.api.exportDataAsCsv({
    fileName: 'insights-revenues.csv',
    columnKeys: [
      'name',
      'customer.name',
      'product.name',
      'amount',
      'mrr',
      'arr',
      'bookingDate',
      'startDate',
      'endDate',
      'cancelDate',
      'revRecStartDate',
      'revRecEndDate',
      'tags',
      'crmId',
      'previous.name',
      'next.name',
    ],
    skipRowGroups: true,
    skipColumnGroupHeaders: true,
    processHeaderCallback: ({ column }) => column.getColDef().headerName || '',
  });

  const {
    data: subscriptionsData,
    isLoading,
    isFetching,
    refetch: refetchSubscriptions,
  } = useGetAllSubscriptionsQuery(undefined);

  const subscriptions = useMemo(() => (revenueType === 'all'
    ? subscriptionsData
    : subscriptionsData?.filter((sub) => sub.product.revenueType === revenueType)),
    [revenueType, subscriptionsData]);

  const handleAddTagGoBack = () => {
    if (rightPanes.addTagFromRenewSubscription) {
      setSelectedPane('renewSubscription');
    } else {
      setSelectedPane(rightPanes.addTagFromAddSubscription ? 'add' : 'edit');
    }
  };

  const contextMenuItems = [
    {
      action: handleBulkImport,
      name: 'Upload PDF',
    },
    {
      action: handleBulkImport,
      name: 'Bulk Import',
    },
    {
      name: '--',
    },
    {
      action: () => handleDownloadTemplate('standard', CompanyRevenueType.SUBSCRIPTIONS),
      name: 'Download Flat Data Template',
    },
    {
      action: () => handleDownloadTemplate('unpivot', CompanyRevenueType.SUBSCRIPTIONS),
      name: 'Download Customer Table Template',
    },
    // { TODO enable when endpoint implemented on BE
    //   action: () => {},
    //   name: 'Download Data',
    // },
  ];

  const isAddTagPaneOpen = rightPanes.addTagFromAddSubscription
    || rightPanes.addTagFromEditSubscription
    || rightPanes.addTagFromRenewSubscription;

  const isAddCustomerPaneOpen = rightPanes.addCustomerFromAddSubscription
    || rightPanes.addCustomerFromEditSubscription;

  const isAddProductPaneOpen = rightPanes.addProductFromAddSubscription
    || rightPanes.addProductFromEditSubscription;

  useEffect(() => {
    setCustomMarkerData({
      selectedIds: selectedRows.map((row) => row.id),
    });
  }, [selectedRows, setCustomMarkerData]);

  useEffect(() => {
    if (location.hash === '#add') {
      location.hash = '';
      setSelectedPane('add');
    }
  }, [location, setSelectedPane]);

  useEffect(() => {
    setTimeout(() => {
      gridRef?.current?.api?.autoSizeColumn('ag-Grid-AutoColumn');
      gridRef.current?.api.deselectAll();
    }, 0);
  }, [revenueType]);

  useEffect(() => {
    if (selectedRows.length) {
      reselectNodeById(selectedRows[0].id, gridRef);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptions]);

  return (
    <>
      <LeftPane>
        <ButtonsContainer>
          <Breadcrumbs>
            <Breadcrumb link="/data-input">Data Sources</Breadcrumb>
            <Breadcrumb link="/data-input/revenue-management">Revenue Management</Breadcrumb>
            <Breadcrumb>Revenues</Breadcrumb>
          </Breadcrumbs>
          <Spacer />
          <Tooltip title="Refresh table data">
            <Button
              variant="icon"
              aria-label="Refresh"
              onClick={() => refetchSubscriptions()}
            >
              <RefreshIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Download CSV">
            <Button
              variant="icon"
              aria-label="Download"
              onClick={handleCSVDownload}
            >
              <DownloadIcon />
            </Button>
          </Tooltip>
          {featureSwitch.bulkImport && (
            <ContextMenu
              items={contextMenuItems}
              customIcon={<UploadIcon />}
              tooltip="Import data..."
            />
          )}
        </ButtonsContainer>
        <SectionLabel>
          <span>
            Revenue
          </span>
        </SectionLabel>
        <Tabs
          tabs={[
            {
              name: 'All',
              value: 'all',
            },
            {
              name: 'Recurring',
              value: 'Recurring',
            },
            {
              name: 'Non-recurring',
              value: 'Nonrecurring',
            },
            {
              name: 'Usage-based',
              value: 'Usage-based',
            },
          ]}
          value={revenueType}
          onChange={({ value }) => navigate(`/data-input/revenue-management/revenue/${value}`)}
          disabled={rightPanes.add}
        />
        <FactaTable
          gridRef={gridRef}
          data={subscriptions}
          useNativeFilters
          columnDefs={columnDefs}
          isLoading={isLoading || isFetching}
          isDisabled={rightPanes.add}
          onClickAdd={handleAddSubscription}
          selectedRowsLength={selectedRows.length}
          entityName={entityName}
          gridOptions={gridOptions}
          showQuickSearch
        />
      </LeftPane>
      <RightPane isOpen={rightPanes.bulkImport}>
        <BulkImportPanel
          onClose={() => resetPanes()}
          name="revenues"
          targetPath={importPath}
        />
      </RightPane>
      <RightPane isOpen={rightPanes.add}>
        <AddSubscription
          onClose={() => resetPanes()}
          onSuccess={(id) => highlightNodeById(id, gridRef)}
          onAddTag={() => setSelectedPane('addTagFromAddSubscription')}
          onAddCustomer={() => setSelectedPane('addCustomerFromAddSubscription')}
          onAddProduct={() => setSelectedPane('addProductFromAddSubscription')}
          revenueType={revenueType}
          entityName={entityName}
        />
      </RightPane>
      <RightPane isOpen={selectedRows.length > 0 && !isAddTagPaneOpen && !isAddCustomerPaneOpen && !isAddProductPaneOpen}>
        {rightPanes.delete && (
          <DeleteSubscriptions
            selectedRows={selectedRows}
            onClose={() => {
              resetPanes();
              gridRef.current?.api.deselectAll();
            }}
            onGoBack={() => resetPanes()}
          />
        )}
        {rightPanes.edit && (
          <EditSubscriptions
            selectedRows={selectedRows}
            onClose={() => gridRef.current?.api.deselectAll()}
            onSuccess={(id) => highlightNodeById(id, gridRef)}
            onSave={(id) => reselectNodeById(id, gridRef)}
            onDelete={handleTriggerDelete}
            onCancelSubscriptions={handleTriggerCancel}
            onRenewSubscription={handleTriggerRenew}
            onAddTag={() => setSelectedPane('addTagFromEditSubscription')}
            onAddCustomer={() => setSelectedPane('addCustomerFromEditSubscription')}
            onAddProduct={() => setSelectedPane('addProductFromEditSubscription')}
            onLinkSubscription={() => setSelectedPane('link')}
            onUnlinkSubscription={() => setSelectedPane('unlink')}
          />
        )}
        {rightPanes.cancelSubscriptions && (
          <CancelSubscriptions
            selectedRows={selectedRows}
            onClose={() => gridRef.current?.api.deselectAll()}
            onGoBack={() => resetPanes()}
          />
        )}
        {rightPanes.renewSubscription && selectedRows.length && (
          <RenewSubscription
            selectedRows={selectedRows}
            onClose={() => resetPanes()}
            onGoBack={() => setSelectedPane('edit')}
            onAddTag={() => {
              resetPanes();
              setSelectedPane('addTagFromRenewSubscription');
            }}
          />
        )}
        {rightPanes.link && (
          <LinkSubscription
            selectedRows={selectedRows}
            onClose={() => {
              resetPanes();
              gridRef.current?.api.deselectAll();
            }}
            onGoBack={() => setSelectedPane('edit')}
          />
        )}
        {rightPanes.unlink && (
          <UnlinkSubscription
            selectedRows={selectedRows}
            onClose={() => {
              resetPanes();
              gridRef.current?.api.deselectAll();
            }}
            onGoBack={() => setSelectedPane('edit')}
          />
        )}
      </RightPane>
      <RightPane isOpen={isAddTagPaneOpen}>
        <AddTag
          onClose={() => resetPanes()}
          onGoBack={handleAddTagGoBack}
          isIndirectOpen
        />
      </RightPane>
      <RightPane isOpen={isAddCustomerPaneOpen}>
        <AddCustomer
          onClose={() => resetPanes()}
          onGoBack={() => setSelectedPane(rightPanes.addCustomerFromAddSubscription ? 'add' : 'edit')}
          isIndirectOpen
        />
      </RightPane>
      <RightPane isOpen={isAddProductPaneOpen}>
        <AddProduct
          onClose={() => resetPanes()}
          onGoBack={() => setSelectedPane(rightPanes.addProductFromAddSubscription ? 'add' : 'edit')}
          isIndirectOpen
        />
      </RightPane>
    </>
  );
};
