import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  useGetAllMetricsQuery,
  useGetMetricsSummaryQuery,
  usePutMetricsSummaryMutation,
} from 'store/services/metrics';
import { SummaryItem } from './SummaryItem';
import { StyledSummaryContainer } from './SummaryItem/styled';
import { Button } from 'components/common/Button';
import {
  ButtonsContainer,
  Spacer,
} from 'components/common/ButtonsContainer';
import {
  CloseIcon,
  PlusIcon,
} from 'assets/icons';
import { Dropdown } from 'components/common/Dropdown';
import { toastWarning } from 'utils/toast';
import { Box } from 'components/common/Box';
import { Tooltip } from 'components/common/Tooltip';
import { Loading } from 'components/Loading';
import { Company } from 'interfaces/company';
import { MetricSummary } from 'interfaces/metrics';
import { useClickOutside } from 'hooks/useClickOutside';

interface Props {
  currentCompany: Company;
}

export const MetricsSummary = ({ currentCompany }: Props) => {
  const [addMetric, setAddMetric] = useState(false);
  const [deletingId, setDeletingId] = useState('');
  const [over, setOver] = useState<string | null>(null);
  const [renderList, setRenderList] = useState<any[]>([]);
  const { data: metrics } = useGetAllMetricsQuery();
  const { data: metricsSummary, isLoading: isLoadingMetricsSummary } = useGetMetricsSummaryQuery();
  const [putMetricsSummary, { isLoading: isSaving }] = usePutMetricsSummaryMutation();

  const metricsWithDisabled = metrics?.map((metric) => ({
    ...metric,
    disabled: metricsSummary?.find((m: any) => m.id === metric.id),
  }));

  const dropdownRef = useRef<any | null>(null);
  const dragItem = useRef<any | null>(null);
  const dragOver = useRef<any | null>(null);

  useClickOutside(dropdownRef, () => setAddMetric(false));

  const handleDragEnter = (ev: React.DragEvent<HTMLDivElement>) => {
    dragOver.current = ev.currentTarget.id;
    setOver(ev.currentTarget.id);
  };

  const handleDrop = () => {
    const updatedList = [...metricsSummary || []];
    const dragElement = updatedList[dragItem.current];
    updatedList.splice(dragItem.current, 1);
    updatedList.splice(dragOver.current, 0, dragElement);
    dragOver.current = null;
    dragItem.current = null;
    setRenderList(updatedList);
    setOver(null);
    putMetricsSummary(updatedList.map(({ id }) => id)
      .filter((id) => id !== 'invalid'));
  };

  useEffect(() => {
    if (Array.isArray(metricsSummary)) {
      setRenderList(metricsSummary);
    }
  }, [metricsSummary]);

  if (isLoadingMetricsSummary) return <Loading suppressFullHeight />;

  return (
    <>
      <ButtonsContainer paddingBottom={20}>
        <Spacer />
        {!addMetric && (
          <Tooltip title={renderList.length === 9 ? 'Maximum of 9 cards allowed.' : undefined}>
            <Button
              variant="borderless"
              size="large"
              onClick={() => setAddMetric(true)}
              disabled={renderList.length === 9}
            >
              <PlusIcon />
              Add metric
            </Button>
          </Tooltip>
        )}
        {addMetric && (
          <Dropdown
            ref={dropdownRef}
            keepOpen
            onDropdownClose={() => setAddMetric(false)}
            values={[]}
            options={metricsWithDisabled || []}
            labelField="name"
            valueField="id"
            searchBy="name"
            disabledLabel="used"
            onChange={(metrics) => {
              setAddMetric(false);

              if (metricsSummary?.find((m: any) => m.id === metrics[0].id)) {
                toastWarning('Metric already used.');
                return;
              }

              putMetricsSummary([...metricsSummary?.map(({ id }) => id)
                .filter((id) => id !== 'invalid') || [], metrics[0].id],);
            }}
            condensed
            minWidth={300}
            placeholder="Add metric"
            searchable
            disabled={renderList.length === 9}
          />
        )}
        {addMetric && (
          <Button
            variant="icon"
            color="primary"
            size="large"
            onClick={() => setAddMetric(false)}
          >
            <CloseIcon />
          </Button>
        )}
        <Button
          color="secondary"
          variant="borderless"
          size="large"
          onClick={() => putMetricsSummary('null')}
        >
          Reset to Defaults
        </Button>
      </ButtonsContainer>
      {renderList.length === 0 && <Box>No metrics selected.</Box>}
      <StyledSummaryContainer>
        {Array.isArray(renderList) && renderList?.map((item: MetricSummary, index) => (
          <SummaryItem
            index={index}
            currentCompany={currentCompany}
            onDragStart={(ev) => {
              dragItem.current = ev.currentTarget.id;
            }}
            onDragEnter={handleDragEnter}
            onDragEnd={handleDrop}
            over={over === `${index}`}
            key={item!.id}
            metric={item}
            isDeleting={deletingId === item.id}
            isSaving={isSaving}
            onRemove={(id) => {
              setDeletingId(id);
              putMetricsSummary((metricsSummary || [])?.filter((metric) => metric.id !== id && metric.id !== 'invalid')
                .map(({ id }) => id))
                .unwrap()
                .finally(() => setDeletingId(''));
            }}
          />
        ))}
      </StyledSummaryContainer>
    </>
  );
};
