import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  LeftPane,
  RightPane,
} from 'components/Layout';
import { SectionLabel } from 'components/common/SectionLabel';
import {
  ButtonsContainer,
  Spacer,
} from 'components/common/ButtonsContainer';
import { Button } from 'components/common/Button';
import {
  DownloadIcon,
  RefreshIcon,
} from 'assets/icons';
import { AgGridReact } from '@ag-grid-community/react';
import { useRightPanes } from 'hooks/useRightPanes';
import { GridOptions } from '@ag-grid-community/core';
import { getColumnDefs } from './columnDefinitions';
import { Tooltip } from 'components/common/Tooltip';
import { Tabs } from 'components/common/Tabs';
import { useLocation } from 'react-router';
import { FactaTable } from 'components/FactaTable';
import { AddContractLine } from '../AddContractLine';
import {
  highlightNodeById,
  reselectNodeById,
} from 'utils/aggrid';
import { AddTag } from 'pages/Tags/AddTag';
import { EditContractLines } from '../EditContractLines';
import { ContractLine } from 'interfaces/contractLines';
import { DeleteContractLines } from '../DeleteContractLines';
import { useGetAllContractLinesQuery } from 'store/services/contractLines';
import { AddProduct } from 'pages/Products/AddProduct';
import {
  Breadcrumb,
  Breadcrumbs,
} from 'components/common/Breadcrumbs';

interface Props {
  setCustomMarkerData: (customData: {}) => void;
}

export const ContractLines = ({ setCustomMarkerData }: Props) => {
  const location = useLocation();
  const gridRef = useRef<AgGridReact>(null);
  const columnDefs = getColumnDefs();
  const [selectedRows, setSelectedRows] = useState<ContractLine[]>([]);
  const { rightPanes, setSelectedPane, resetPanes } = useRightPanes({
    add: false,
    edit: true,
    delete: false,
    addTagFromAddContractLine: false,
    addTagFromEditContractLine: false,
    addProductFromAddContractLine: false,
    addProductFromEditContractLine: false,
  });

  const {
    data: contractLines,
    isLoading,
    isFetching,
    refetch: refetchContractLines,
  } = useGetAllContractLinesQuery();

  const gridOptions: GridOptions = {
    onSelectionChanged: (e) => {
      const selectedRows = e.api.getSelectedRows();
      setSelectedRows(selectedRows);
      if (selectedRows.length) {
        resetPanes();
      }
    },
    enableCharts: true,
    statusBar: {
      statusPanels: [
        { statusPanel: 'agAggregationComponent' },
      ],
    },
  };

  const handleAddContractLine = () => {
    gridRef.current?.api.deselectAll();
    setSelectedPane('add');
  };

  const handleAddTagGoBack = () => {
    setSelectedPane(rightPanes.addTagFromAddContractLine ? 'add' : 'edit');
  };

  const handleAddProductGoBack = () => {
    setSelectedPane(rightPanes.addProductFromAddContractLine ? 'add' : 'edit');
  };

  const handleTriggerDelete = () => {
    setSelectedPane('delete');
  };

  const handleCSVDownload = () => gridRef.current?.api.exportDataAsCsv({
    fileName: `insights-contract-lines.csv`,
    columnKeys: [
      'name',
      'contract.name',
      'customer.name',
      'product.name',
      'product.revenueType',
      'status',
      'amount',
      'mrr',
      'arr',
      'bookingDate',
      'startDate',
      'endDate',
      'cancelDate',
      'tags',
      'crmId',
      'next.name',
      'previous.name',
    ],
    skipRowGroups: true,
    skipColumnGroupHeaders: true,
    processHeaderCallback: ({ column }) => column.getColDef().headerName || '',
  });

  const isAddTagPaneOpen = rightPanes.addTagFromAddContractLine
    || rightPanes.addTagFromEditContractLine;

  const isAddProductPaneOpen = rightPanes.addProductFromAddContractLine
    || rightPanes.addProductFromEditContractLine;

  useEffect(() => {
    if (location.hash === '#add') {
      location.hash = '';
      setSelectedPane('add');
    }
  }, [location, setSelectedPane]);

  useEffect(() => {
    setCustomMarkerData({
      selectedIds: selectedRows.map((row) => row.id),
    });
  }, [selectedRows, setCustomMarkerData]);

  return (
    <>
      <LeftPane>
        <ButtonsContainer>
          <Breadcrumbs>
            <Breadcrumb link="/data-input">Data Sources</Breadcrumb>
            <Breadcrumb link="/data-input/contract-management">Contract Management</Breadcrumb>
            <Breadcrumb>Contracts</Breadcrumb>
          </Breadcrumbs>
          <Spacer />
          <Tooltip title="Refresh table data">
            <Button
              variant="icon"
              aria-label="Refresh"
              onClick={refetchContractLines}
            >
              <RefreshIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Download CSV">
            <Button
              variant="icon"
              aria-label="Download"
              onClick={handleCSVDownload}
            >
              <DownloadIcon />
            </Button>
          </Tooltip>
        </ButtonsContainer>
        <SectionLabel condensed>
          <span>
            Contract Lines
          </span>
        </SectionLabel>
        <Tabs
          tabs={[
            {
              name: 'Contracts',
              value: '/data-input/contract-management/contracts',
            },
            {
              name: 'Contract Lines',
              value: '/data-input/contract-management/contracts/contract-lines',
            },
          ]}
          value="/data-input/contract-management/contracts/contract-lines"
        />
        <FactaTable
          gridRef={gridRef}
          data={contractLines}
          useNativeFilters
          columnDefs={columnDefs}
          isLoading={isLoading || isFetching}
          isDisabled={rightPanes.add}
          onClickAdd={handleAddContractLine}
          selectedRowsLength={selectedRows.length}
          entityName="Contract Line"
          gridOptions={gridOptions}
          showQuickSearch
        />
      </LeftPane>
      <RightPane isOpen={rightPanes.add}>
        <AddContractLine
          onAddTag={() => setSelectedPane('addTagFromAddContractLine')}
          onClose={resetPanes}
          onSuccess={(id) => highlightNodeById(id, gridRef)}
          onAddProduct={() => setSelectedPane('addProductFromAddContractLine')}
        />
      </RightPane>
      <RightPane isOpen={isAddTagPaneOpen}>
        <AddTag
          onClose={() => resetPanes()}
          onGoBack={handleAddTagGoBack}
          isIndirectOpen
        />
      </RightPane>
      <RightPane isOpen={isAddProductPaneOpen}>
        <AddProduct
          onClose={() => resetPanes()}
          onGoBack={handleAddProductGoBack}
          isIndirectOpen
        />
      </RightPane>
      <RightPane isOpen={selectedRows.length > 0 && !isAddTagPaneOpen && !isAddProductPaneOpen}>
        {rightPanes.edit && (
          <EditContractLines
            selectedRows={selectedRows}
            onClose={() => gridRef.current?.api.deselectAll()}
            onSuccess={(id) => highlightNodeById(id, gridRef)}
            onSave={(id) => reselectNodeById(id, gridRef)}
            onAddTag={() => setSelectedPane('addTagFromEditContractLine')}
            onAddProduct={() => setSelectedPane('addProductFromEditContractLine')}
            onDelete={handleTriggerDelete}
            onCancelContractLines={() => { }}
          />
        )}
        {rightPanes.delete && (
          <DeleteContractLines
            selectedRows={selectedRows}
            onClose={() => {
              resetPanes();
              gridRef.current?.api.deselectAll();
            }}
            onGoBack={() => resetPanes()}
          />
        )}
      </RightPane>
    </>
  );
};
