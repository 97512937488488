import React, {
  useMemo,
  useRef,
  useState,
} from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { FactaTable } from 'components/FactaTable';
import { LeftPane } from 'components/Layout';
import {
  Breadcrumb,
  Breadcrumbs,
} from 'components/common/Breadcrumbs';
import { SectionLabel } from 'components/common/SectionLabel';
import { getColumnDefs } from './columnDefinitions';
import { GridOptions } from '@ag-grid-community/core';
import { useRightPanes } from 'hooks/useRightPanes';
import { Modal } from 'components/Layout/Modal';
import { AddMetric } from './AddMetric';
import {
  CopyIcon,
  DeleteIcon,
  NumberFormatIcon,
  PercentIcon,
  TagIcon,
  UploadIcon,
} from 'assets/icons';
import {
  useGetAllMetricsQuery,
  useUpdateMetricsFormatMutation,
} from 'store/services/metrics';
import { DuplicateMetrics } from './DuplicateMetrics';
import { commonPopperOptions } from 'utils/popper';
import { usePopper } from 'react-popper';
import { QuickTagging } from './QuickTagging';
import {
  useNavigate,
  useParams,
} from 'react-router';
import {
  Metric,
  MetricOrigin,
} from 'interfaces/metrics';
import { WhatAreMetrics } from './WhatAreMetrics';
import { Button } from 'components/common/Button';
import { Tabs } from 'components/common/Tabs';
import { MetricCategoryOptions } from 'utils/constants';
import {
  ButtonsContainer,
  Spacer,
} from 'components/common/ButtonsContainer';
import { Tooltip } from 'components/common/Tooltip';
import { featureSwitch } from 'utils/featureSwitch';
import { DeleteMetric } from './DeleteMetric';
import { toastSuccess } from 'utils/toast';
import { useGetAuthMeQuery } from 'store/services/auth';
import { getCurrentCompany } from 'utils/currentCompany';
import { slugify } from 'utils/slugify';

export const Metrics = () => {
  const navigate = useNavigate();
  const gridRef = useRef<AgGridReact>(null);
  const columnDefs = getColumnDefs();
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [isTaggingOpen, setIsTaggingOpen] = useState(false);
  const [createMetricOrigin, setCreateMetricOrigin] = useState(MetricOrigin.CUSTOM);

  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const tagsPopper = usePopper(referenceElement, popperElement, commonPopperOptions);

  const { data: metricsData, isLoading } = useGetAllMetricsQuery();
  const { data: user } = useGetAuthMeQuery();

  const { category = 'all' } = useParams();

  const metrics = useMemo(() => (category === 'all'
    ? metricsData
    : metricsData?.filter((met) => slugify(met.type.category) === category)),
    [category, metricsData]);

  const currency = getCurrentCompany(user)?.currency || {
    code: 'USD',
    symbol: '$',
    name: 'US Dollars',
  };

  const [updateMetricFormat, { isLoading: isUpdatingMetricsFormat }] = useUpdateMetricsFormatMutation();

  const { rightPanes, setSelectedPane, resetPanes } = useRightPanes({
    add: false,
    duplicate: false,
    whatAreMetrics: false,
    delete: false,
  });

  const tabs = [
    {
      name: 'All',
      value: 'all',
    },
    ...MetricCategoryOptions.map((opt) => ({ ...opt, value: slugify(opt.value) })),
  ];

  const gridOptions: GridOptions = {
    onSelectionChanged: (e) => {
      const selectedRows = e.api.getSelectedRows();
      setSelectedRows(selectedRows);
      if (selectedRows.length) {
        resetPanes();
      }
    },
    suppressRowClickSelection: true,
  };

  const handleToggleTagging = () => {
    setIsTaggingOpen(!isTaggingOpen);
  };

  const handleUpdateFormat = (valueFormat: string) => {
    const metricsIds = selectedRows.map((row) => row.id);

    updateMetricFormat({ metricsIds, valueFormat })
      .unwrap()
      .then(() => {
        toastSuccess('Metrics formatting updated successfully.');
      });
  };

  return (
    <>
      <LeftPane>
        <ButtonsContainer>
          <Breadcrumbs>
            <Breadcrumb link="/metrics">
              Metrics
            </Breadcrumb>
            <Breadcrumb>
              All Metrics
            </Breadcrumb>
          </Breadcrumbs>
          <Spacer />
          <Tooltip title="Bulk Upload Manual Metrics">
            <Button
              variant="icon"
              aria-label="Upload"
              onClick={() => navigate('/metrics/manual')}
              hidden={!featureSwitch.manualMetrics}
            >
              <UploadIcon />
            </Button>
          </Tooltip>
        </ButtonsContainer>
        <SectionLabel>
          <span>
            Metrics
            <p>
              <Button
                variant="simple"
                onClick={() => setSelectedPane('whatAreMetrics')}
                size="large"
              >
                What are metrics?
              </Button>
            </p>
          </span>
        </SectionLabel>
        <Tabs
          tabs={tabs}
          onChange={({ value }) => {
            navigate(`/metrics/metrics/${value}`);
            gridRef?.current?.api?.deselectAll();
          }}
          value={category}
        />
        <FactaTable
          gridRef={gridRef}
          data={metrics}
          useNativeFilters
          columnDefs={columnDefs}
          selectedRowsLength={selectedRows.length}
          entityName={'Metric'}
          onClickAdd={() => setSelectedPane('add')}
          gridOptions={gridOptions}
          customButtons={[
            {
              title: 'Delete',
              icon: <DeleteIcon />,
              size: 'large',
              variant: 'borderless',
              color: 'error',
              hidden: !selectedRows.length,
              onClick: () => setSelectedPane('delete'),
              divider: true,
              tooltip: selectedRows.length > 1 ? 'You can only delete one metric at a time' : 'Delete selected metrics',
              disabled: selectedRows.length > 1,
            },
            {
              title: `Duplicate`,
              icon: <CopyIcon />,
              size: 'large',
              variant: 'borderless',
              hidden: !selectedRows.length,
              onClick: () => setSelectedPane('duplicate'),
              divider: true,
              tooltip: 'Create copy of selected metrics',
            },
            {
              ref: setReferenceElement,
              title: `Tags`,
              icon: <TagIcon />,
              size: 'large',
              variant: 'borderless',
              hidden: !selectedRows.length,
              onClick: handleToggleTagging,
              divider: true,
              tooltip: 'Assign tags to selected metrics',
            },
            {
              title: ``,
              icon: <NumberFormatIcon />,
              size: 'large',
              variant: 'icon',
              color: 'primary',
              hidden: !selectedRows.length,
              onClick: () => handleUpdateFormat('number'),
              tooltip: 'Set selected metrics\' format to number',
              divider: true,
            },
            {
              title: ``,
              icon: <>{currency.symbol}</>,
              size: 'large',
              variant: 'icon',
              color: 'primary',
              hidden: !selectedRows.length,
              onClick: () => handleUpdateFormat('currency'),
              tooltip: 'Set selected metrics\' format to currency',
            },
            {
              title: ``,
              icon: <PercentIcon />,
              size: 'large',
              variant: 'icon',
              color: 'primary',
              hidden: !selectedRows.length,
              onClick: () => handleUpdateFormat('percentage'),
              tooltip: 'Set selected metrics\' format to percentage',
            },
            {
              title: ``,
              icon: <>x</>,
              size: 'large',
              variant: 'icon',
              color: 'primary',
              hidden: !selectedRows.length,
              onClick: () => handleUpdateFormat('multiple'),
              tooltip: 'Set selected metrics\' format to multiple',
            },
          ]}
          showQuickSearch
          isLoading={isLoading || isUpdatingMetricsFormat}
          suppressAddButton
          customAddLabel="CREATE CUSTOM METRIC"
          additionalAddOptions={[
            {
              name: 'Create Custom Metric',
              action: () => {
                setCreateMetricOrigin(MetricOrigin.CUSTOM);
                setSelectedPane('add');
              },
            },
            {
              name: 'Create System Metric',
              action: () => {
                setCreateMetricOrigin(MetricOrigin.SYSTEM_COPY);
                setSelectedPane('add');
              },
            },
            {
              name: 'Create Manual Metric',
              action: () => {
                setCreateMetricOrigin(MetricOrigin.MANUAL);
                setSelectedPane('add');
              },
              hidden: !featureSwitch.manualMetrics,
            },
            {
              name: '--',
              hidden: !featureSwitch.manualMetrics,
            },
            {
              name: 'Bulk Upload Manual Metrics',
              action: () => navigate('/metrics/manual'),
              hidden: !featureSwitch.manualMetrics,
            },
          ]}
        />
      </LeftPane>
      <Modal
        isOpen={rightPanes.add}
        onClose={() => resetPanes()}
      >
        <AddMetric
          onClose={() => resetPanes()}
          onSuccess={(result: Metric) => navigate(`/metrics/${result.id}`)}
          metricOrigin={createMetricOrigin}
        />
      </Modal>
      <Modal
        isOpen={rightPanes.duplicate}
        onClose={() => resetPanes()}
        condensed
      >
        <DuplicateMetrics
          selectedMetrics={selectedRows}
          onClose={() => resetPanes()}
          onSuccess={() => gridRef.current?.api.deselectAll()}
        />
      </Modal>
      <Modal
        isOpen={rightPanes.delete}
        onClose={() => resetPanes()}
        condensed
        maxWidth={500}
      >
        <DeleteMetric
          selectedMetrics={selectedRows}
          onClose={() => resetPanes()}
          onSuccess={() => resetPanes()}
        />
      </Modal>
      {isTaggingOpen && (
        <QuickTagging
          selectedRows={selectedRows}
          popperRef={setPopperElement}
          style={tagsPopper.styles.popper}
          attributes={tagsPopper.attributes}
          onClose={() => setIsTaggingOpen(false)}
        />
      )}
      <Modal
        isOpen={rightPanes.whatAreMetrics}
        onClose={() => resetPanes()}
        maxWidth={972}
      >
        <WhatAreMetrics onClose={() => resetPanes()} />
      </Modal>
    </>
  );
};
