import React, {
  useRef,
  useState,
} from "react";
import {
  BarChartIcon,
  DeleteIcon,
  DragIcon,
  InfoIcon,
  LineChartIcon,
  PlusIcon,
} from "assets/icons";
import { FactaChart } from "components/FactaChart";
import {
  LeftPane,
  RightPane,
} from "components/Layout";
import { Button } from "components/common/Button";
import { ButtonsContainer } from "components/common/ButtonsContainer";
import { Dropdown } from "components/common/Dropdown";
import { StyledFormContainer } from "components/common/Forms/styled";
import { InputBasic } from "components/common/Input";
import { Label } from "components/common/Input/Label";
import { SectionLabel } from "components/common/SectionLabel";
import { Tooltip } from "components/common/Tooltip";
import {
  ChartBackendResponse,
  ChartConfig,
} from "interfaces/charts";
import {
  Controller,
  useFieldArray,
  useForm,
} from "react-hook-form";
import {
  useGetAllMetricsQuery,
  useGetMetricsWithValuesQuery,
} from "store/services/metrics";
import {
  DragHandle,
  StyledMetric,
} from "./styled";
import { ChartButton } from "components/FactaChart/ChartButton";
import { CheckboxInput } from "components/common/CheckboxInput";
import { Hint } from "components/common/Hint";
import { ComparisonChartForm } from "interfaces/comparisonChart";

export const ComparisonChart = () => {
  const [over, setOver] = useState<string | null>(null);
  const [chartConfig, setChartConfig] = useState<ChartConfig>({
    valueFormat: "number",
    chartPeriodType: "monthly",
    showChartValues: true,
  });

  const dropdownRef = useRef<HTMLDivElement>();
  const dragItem = useRef<any | null>(null);
  const dragOver = useRef<any | null>(null);

  const { data: metrics } = useGetAllMetricsQuery();

  const {
    getValues,
    control,
    watch,
    setValue,
    register,
    formState: { errors },
  } = useForm<ComparisonChartForm>({
    mode: 'onChange',
    defaultValues: {
      name: 'Custom Chart',
      metricFields: [{}],
      metricOptions: [{ chartType: 'bar' }],
      shouldStackLines: false,
      shouldStackBars: false,
    },
  });

  const {
    append: appendMetric,
    remove: removeMetric,
    move: moveMetric,
  } = useFieldArray({
    control,
    name: 'metricFields',
  });

  const {
    append: appendMetricOptions,
    remove: removeMetricOptions,
    update,
    move: moveMetricOptions,
  } = useFieldArray({
    control,
    name: 'metricOptions',
  });

  const {
    metricFields,
    metricOptions,
    name,
    shouldStackLines,
    shouldStackBars,
  } = watch();

  const metricFieldsIds = metricFields.map((mf) => mf.id);

  const metricsCharts = [
    useGetMetricsWithValuesQuery({ metricId: metricFields[0]?.id || '' }, { skip: !metricFields[0]?.id }),
    useGetMetricsWithValuesQuery({ metricId: metricFields[1]?.id || '' }, { skip: !metricFields[1]?.id }),
    useGetMetricsWithValuesQuery({ metricId: metricFields[2]?.id || '' }, { skip: !metricFields[2]?.id }),
    useGetMetricsWithValuesQuery({ metricId: metricFields[3]?.id || '' }, { skip: !metricFields[3]?.id }),
    useGetMetricsWithValuesQuery({ metricId: metricFields[4]?.id || '' }, { skip: !metricFields[4]?.id }),
    useGetMetricsWithValuesQuery({ metricId: metricFields[5]?.id || '' }, { skip: !metricFields[5]?.id }),
    useGetMetricsWithValuesQuery({ metricId: metricFields[6]?.id || '' }, { skip: !metricFields[6]?.id }),
    useGetMetricsWithValuesQuery({ metricId: metricFields[7]?.id || '' }, { skip: !metricFields[7]?.id }),
    useGetMetricsWithValuesQuery({ metricId: metricFields[8]?.id || '' }, { skip: !metricFields[8]?.id }),
    useGetMetricsWithValuesQuery({ metricId: metricFields[9]?.id || '' }, { skip: !metricFields[9]?.id }),
  ];

  const isLoading = metricsCharts.some((mc) => mc.isFetching);

  const range = metricsCharts?.at(0)?.data?.at(0)?.entries || [];

  const chartData: ChartBackendResponse = {
    name,
    data: range.map(({ period }, index) => ({
      date: period,
      dataValues: metricFields
        .filter((mf) => mf.id)
        .map((mf) => {
          const metricResponse = metricsCharts.find((mc) => mc?.data?.at(0)?.id === mf?.id);
          return {
            chartDataCategoryValue: 'comparison',
            chartDataValueLabelId: metricResponse?.data?.at(0)?.id || 'customId',
            value: metricResponse?.data?.at(0)?.entries.at(index)?.value || 0,
          };
        }),
    })),
    chartDataValueLabels: metricFields
      .filter((mf) => mf.id)
      .map((mf, index) => {
        const metricResponse = metricsCharts.find((mc) => mc?.data?.at(0)?.id === mf?.id);
        return {
          id: metricResponse?.data?.at(0)?.id || 'id',
          name: metricResponse?.data?.at(0)?.name || '(loading)',
          chartType: metricOptions[index].chartType,
        };
      }),
    chartConfig,
    chartDataCategories: [
      {
        id: 'comparison',
        name: 'category1',
      },
    ],
  };

  const handleDragEnter = (ev: React.DragEvent<HTMLDivElement>) => {
    dragOver.current = ev.currentTarget.id;
    setOver(ev.currentTarget.id);
  };

  const handleDrop = () => {
    moveMetric(dragItem.current, dragOver.current);
    moveMetricOptions(dragItem.current, dragOver.current);
    dragOver.current = null;
    dragItem.current = null;
    setOver(null);
  };

  return (
    <>
      <LeftPane>
        <FactaChart
          tooltipStyle="full"
          chartData={chartData}
          shouldStackBars={shouldStackBars}
          shouldStackLines={shouldStackLines}
          showLegend
          onChartConfigChange={setChartConfig}
          loadingBar={isLoading}
        />
      </LeftPane>
      <RightPane
        isOpen
        collapsible
      >
        <header>
          <SectionLabel>
            Chart Configuration
          </SectionLabel>
        </header>
        <main>
          <StyledFormContainer>
            <InputBasic
              isRequired
              labelText="Chart Name"
              placeholder="Add chart name"
              {...register('name')}
              onBlur={(e) => {
                const fieldValue = e.target.value;

                if (fieldValue) {
                  setValue('name', fieldValue.trim(), { shouldValidate: true });
                }
              }}
              error={errors.name?.message}
            />
            <div>
              <CheckboxInput
                labelText="Stack metrics"
                {...register('shouldStackBars')}
                error={errors.shouldStackBars?.message}
                tooltip="Choose whether you'd like to stack bar and/or line chart metrics."
                checkboxLabel="Stack bar metrics"
                defaultValue={getValues().shouldStackBars}
              />
              <CheckboxInput
                labelText=""
                {...register('shouldStackLines')}
                error={errors.shouldStackLines?.message}
                checkboxLabel="Stack line metrics"
                defaultValue={getValues().shouldStackLines}
              />
            </div>
            <div>
              <Label
                name="metrics"
                labelText="Metrics"
                tooltip="First selected metric provides date range reference for the chart."
              />
              {metricFields.map((mf, index) => (
                <StyledMetric
                  key={`mf_${index}`}
                  id={`${index}`}
                  onDragEnter={handleDragEnter}
                  onDragEnd={handleDrop}
                  onDragOver={(ev) => ev.preventDefault()}
                  over={over === `${index}`}
                  beingDragged={dragItem.current === `${index}`}
                >
                  <DragHandle
                    id={`${index}`}
                    draggable
                    onDragStart={(ev) => dragItem.current = ev.currentTarget.id}
                  >
                    <DragIcon />
                  </DragHandle>
                  <Controller
                    name={`metricFields.${index}`}
                    control={control}
                    render={({ field }) =>
                      <Dropdown
                        {...field}
                        ref={dropdownRef}
                        options={metrics?.filter((m) => !metricFieldsIds.includes(m.id)) || []}
                        labelField="name"
                        valueField="name"
                        searchBy="name"
                        placeholder="Select metric"
                        values={[getValues(`metricFields.${index}`)]}
                        onChange={(v) => v.length ? field.onChange(v[0]) : undefined}
                        condensed
                      />
                    }
                  />
                  <ChartButton
                    tooltip="Bar chart"
                    isActive={metricOptions[index].chartType === 'bar'}
                    onClick={() => update(index, { chartType: 'bar' })}
                  >
                    <BarChartIcon />
                  </ChartButton>
                  <ChartButton
                    tooltip="Line chart"
                    isActive={metricOptions[index].chartType === 'line'}
                    onClick={() => update(index, { chartType: 'line' })}
                  >
                    <LineChartIcon />
                  </ChartButton>
                  <ChartButton
                    tooltip="Remove"
                    color="error"
                    onClick={() => {
                      removeMetricOptions(index);
                      removeMetric(index);
                    }}
                  >
                    <DeleteIcon />
                  </ChartButton>
                </StyledMetric>
              ))}
            </div>
          </StyledFormContainer>
          <ButtonsContainer>
            <Button
              type="button"
              variant="borderless"
              onClick={() => {
                appendMetric({});
                appendMetricOptions({ chartType: metricOptions.at(-1)?.chartType || 'bar' });
                setTimeout(() => {
                  dropdownRef.current?.querySelector('input')
                    ?.click();
                }, 0);
              }}
              disabled={metricFields.length === 10}
            >
              <PlusIcon />
              Add metric
            </Button>
            {metricFields.length === 10 && (
              <Tooltip title="Maximum of 10 metrics allowed." />
            )}
          </ButtonsContainer>
        </main>
        <footer>
          <Hint marginBottom={0}>
            <InfoIcon />
            <div>
              Saving charts feature is coming soon.
            </div>
          </Hint>
        </footer>
      </RightPane>
    </>
  );
};