import React from 'react';
import styled, { css } from 'styled-components';
import {
  ChevronDown,
  OpenInNewIcon,
} from 'assets/icons';
import { Button } from '../Button';
import { Spacer } from '../ButtonsContainer';
import { useNavigate } from 'react-router';
import { Tooltip } from '../Tooltip';
import { theme } from 'theme/theme';
import { ICellRendererParams } from '@ag-grid-community/core';

const StyledWrapper = styled.div<{ disabled?: boolean; error?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  
  ${props => props.disabled && css`
    color: ${theme.colors.textDisabled};
  `}

  ${props => props.error && !props.disabled && css`
    color: ${theme.colors.error};
  `}

  span {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  button {
    margin-left: 8px;
    width: 24px;
    height: 24px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  svg {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
  }
`;

const StyledTextContainer = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface Props {
  children: JSX.Element;
  link?: string;
  tooltip?: string;
  disabled?: boolean;
  error?: boolean;
  gridParams: ICellRendererParams;
}

export const AgGridCellRendererDropdown = ({
  children,
  link,
  tooltip,
  disabled,
  error,
  gridParams,
}: Props) => {
  const navigate = useNavigate();

  const startEditing = () => {
    if (gridParams.node.rowIndex === null) return;

    gridParams.api.startEditingCell({
      rowIndex: gridParams.node.rowIndex,
      colKey: gridParams.column!.getColId(),
    });
  };

  return (
    <StyledWrapper
      disabled={disabled}
      error={error}
    >
      <StyledTextContainer>
        {children}
      </StyledTextContainer>
      {link && (
        <Tooltip
          title={tooltip}
          position="right"
        >
          <Button
            variant="icon"
            color="primary"
            onClick={() => navigate(link)}
            disabled={disabled}
          >
            <OpenInNewIcon />
          </Button>
        </Tooltip>
      )}
      <Spacer />
      {!disabled && (
        <Button
          variant="icon"
          color="secondary"
          onClick={startEditing}
        >
          <ChevronDown />
        </Button>
      )}
    </StyledWrapper>
  );
};
