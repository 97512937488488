import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="currentColor"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="inherit"
      d="m6.936 15.682-.11-2.684L15.56 4h2.508l-6.754 7.128-1.232 1.364-3.146 3.19ZM5 19.4V4h2.2v15.4H5Zm10.912 0-6.314-7.348 1.474-1.628 7.414 8.976h-2.574Z"
    />
  </svg>
);

export default SvgComponent;
