import React, {
  forwardRef,
  useRef,
} from 'react';
import DatePicker, { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { ErrorDisplay } from '../Input/ErrorDisplay';
import { Label } from '../Input/Label';
import {
  StyledContainer,
  StyledInput,
  StyledInputWrapper,
} from '../Input/styled';
import "react-datepicker/dist/react-datepicker.css";
import {
  IconWrapper,
  StyledDatePickerWrapper,
} from './styled';
import { DatepickerHeader } from './DatepickerHeader';
import { CalendarIcon } from 'assets/icons';
import { endOfMonth } from 'date-fns';
import {
  formatDateStringToDisplay,
  formatDateToISODate,
  isoToDate,
} from 'utils/dates';
import { Button } from '../Button';

interface Props {
  name: string;
  error?: string;
  disabled?: boolean;
  labelText?: string
  placeholder?: string;
  isRequired?: boolean;
  isOptional?: boolean;
  isClearable?: boolean;
  onChange: (date: Date | [Date | null, Date | null] | undefined) => void;
  value?: Date | [Date | null, Date | null];
  selectsRange?: boolean;
  condensed?: boolean;
  tooltip?: string;
  variant?: 'monthPicker' | 'monthYearPicker' | 'quarterYearPicker' | 'yearPicker' | '';
  shouldSetMonthEndDate?: boolean;
  minDate?: Date | null | undefined;
  maxDate?: Date | null | undefined;
  suggestedDate?: string;
}

export const Datepicker = forwardRef(({
  disabled,
  name,
  isRequired,
  isOptional,
  isClearable = true,
  labelText,
  placeholder,
  error,
  onChange,
  value,
  selectsRange,
  condensed,
  tooltip,
  variant,
  shouldSetMonthEndDate,
  minDate,
  maxDate,
  suggestedDate,
}: Props, ref: any) => {
  const withError = Boolean(error);
  const dpRef = useRef<DatePicker<never, boolean> | null>(null);

  const getDateFormat = () => {
    switch (variant) {
      case 'monthPicker':
        return 'MMMM';
      case 'monthYearPicker':
        return 'MMM y';
      case 'quarterYearPicker':
        return 'QQQ y';
      case 'yearPicker':
        return 'y';
      default:
        return 'MM/dd/yyyy';
    }
  };

  const dateFormat = getDateFormat();

  const renderCustomHeader = (props: ReactDatePickerCustomHeaderProps) => {
    switch (variant) {
      case 'monthPicker':
        return null;
      case 'quarterYearPicker':
        return 'Q y';
      case 'yearPicker':
        return 'y';
      default:
        return <DatepickerHeader {...props} />;
    }
  };

  return (
    <StyledContainer ref={ref}>
      {labelText && (
        <Label
          isRequired={isRequired}
          isOptional={isOptional}
          labelText={labelText}
          name={name}
          tooltip={tooltip}
          annotation={suggestedDate && !value
            ? (
              <Button
                onClick={(ev) => {
                  ev.preventDefault();
                  onChange(isoToDate(suggestedDate));
                }}
              >
                Fill with: {formatDateStringToDisplay(suggestedDate)}
              </Button>
            )
            : undefined}
        />
      )}
      <StyledInputWrapper
        disabled={disabled}
        withError={withError}
      >
        <StyledDatePickerWrapper>
          <DatePicker
            ref={dpRef}
            showPopperArrow={false}
            customInput={<StyledInput condensed={condensed} />}
            disabled={disabled}
            name={name}
            placeholderText={placeholder || 'Select Date'}
            onChange={(date) => {
              if (!date) {
                onChange(undefined);
                return;
              };

              onChange(shouldSetMonthEndDate
                ? formatDateToISODate(endOfMonth((date as Date)))
                : date,
              );
            }}
            startDate={selectsRange ? (value as [Date, Date])[0] : null}
            endDate={selectsRange ? (value as [Date, Date])[1] : null}
            selected={selectsRange ? (value as [Date, Date])[0] : value as Date}
            formatWeekDay={(weekDayName) => weekDayName[0]}
            selectsRange={selectsRange}
            fixedHeight
            showMonthYearPicker={!!variant && ['monthPicker', 'monthYearPicker'].includes(variant)}
            showQuarterYearPicker={variant === 'quarterYearPicker'}
            showYearPicker={variant === 'yearPicker'}
            dateFormat={dateFormat}
            renderCustomHeader={variant !== 'monthYearPicker' && variant !== 'quarterYearPicker' && variant !== 'yearPicker' ? renderCustomHeader : undefined}
            isClearable={isClearable}
            minDate={minDate}
            maxDate={maxDate}
            preventOpenOnFocus
            onBlur={() => dpRef.current?.setOpen(false)}
            onFocus={(e) => e.currentTarget.setSelectionRange(0, 999)}
          />
          <IconWrapper onClick={() => dpRef.current?.setOpen(true)}>
            <CalendarIcon />
          </IconWrapper>
        </StyledDatePickerWrapper>
      </StyledInputWrapper>
      {error && <ErrorDisplay error={error} />}
    </StyledContainer>
  );
});
