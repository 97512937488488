import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="currentColor"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="inherit"
      d="M4 19.4V4h1.804l6.908 11.638h-.968L18.564 4h1.804l.022 15.4h-2.112l-.022-12.078h.506L12.69 17.53h-1.012L5.562 7.322h.55V19.4H4Z"
    />
  </svg>
);

export default SvgComponent;
