import {
  Middleware,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import {
  toastError,
  toastWarning,
} from "utils/toast";
import {
  captureException,
  configureScope,
} from "@sentry/react";
import { Auth } from "aws-amplify";

export const rtkQueryErrorLogger: Middleware = () => (next) => async (action) => {
  if (isRejectedWithValue(action)) {

    if (action?.payload?.status === 401) {
      toastWarning('You session has expired. Redirecting to login.', {
        autoClose: 5000,
        onClose: () => window.location.href = `${import.meta.env.VITE_APP_AUTH_APP_URL}${window.location.origin}`,
      });
      return;
    }

    if (action?.payload?.data?.errorCode === 'NO_ACCESS') {
      window.location.href = '/unauthorized';
      return;
    }

    const errorMessage = `${action?.error?.message || 'Unknown error'} 
      ${action?.payload?.data?.error ? `(${action.payload.data.error})` : ''}`;

    toastError(errorMessage);
    console.error('action', action);

    const error = new Error();
    error.name = action?.payload?.data?.errorCode || 'API Error: ' + action?.payload?.status;
    error.message = action?.payload?.data?.error || action?.payload?.data;

    const user = await Auth.currentUserPoolUser();

    configureScope(
      (scope) => scope
        .setLevel('error')
        .setUser({
          email: user?.attributes?.email,
        })
        .setExtra('response', action?.payload)
        .setExtra('endpoint', action?.meta?.arg?.endpointName)
        .setExtra('arguments', action?.meta?.arg?.originalArgs),
    );
    captureException(error);
  }

  return next(action);
};
