import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  ArrowRightIcon,
  DeleteIcon,
  DragIcon,
  InfoIcon,
} from "assets/icons";
import { Button } from "components/common/Button";
import {
  chartDateFormatter,
  prepareDataObject,
} from "utils/charts";
import {
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
} from "recharts";
import { theme } from "theme/theme";
import {
  StyledChange,
  StyledChart,
  StyledDragDrop,
  StyledLink,
  StyledPlaceholderItem,
  StyledRemove,
  StyledSummaryItem,
  StyledSummaryItemDate,
  StyledSummaryItemLabel,
  StyledSummaryItemValue,
} from "./styled";
import { getFormattedMetricValue } from "utils/metrics";
import { Company } from "interfaces/company";
import { MetricSummary } from "interfaces/metrics";

interface Props {
  metric: MetricSummary;
  onRemove: (id: string) => void;
  isDeleting?: boolean;
  onDragStart: React.DragEventHandler<HTMLDivElement> | undefined;
  onDragEnter: React.DragEventHandler<HTMLDivElement> | undefined;
  onDragEnd: React.DragEventHandler<HTMLDivElement> | undefined;
  index: number;
  over: boolean;
  isSaving: boolean;
  currentCompany: Company;
}

export const SummaryItem = ({
  metric,
  onRemove,
  isDeleting,
  onDragStart,
  onDragEnter,
  onDragEnd,
  index,
  over,
  isSaving,
  currentCompany,
}: Props) => {
  const [activeValue, setActiveValue] = useState({
    value: '',
    date: '',
    hover: false,
  });
  const data = useMemo(() => metric ? prepareDataObject(metric.data, metric.chartDataValueLabels, Math.max(metric.data.length - 12, 0), metric.data.length) : [], [metric]);
  const currencyCode = currentCompany.currency.code;
  const isMetricInvalid = metric?.id === 'invalid';

  const change = useMemo(() => {
    if (data.length <= 1) return 0;

    const decimals = metric?.chartConfig.valueFormat === 'percentage' ? 3 : 1;
    const lastValue = Number(data.at(-1).sum.toFixed(decimals));
    const oneBeforeLastValue = Number(data.at(-2).sum.toFixed(decimals));
    const internalChange = (lastValue - oneBeforeLastValue) / oneBeforeLastValue * 100 || 0;

    if (Math.abs(internalChange) === Infinity) return null;

    return Number(data.at(-2).sum.toFixed(1)) < 0
      ? -internalChange
      : internalChange;
  }, [data, metric?.chartConfig.valueFormat]);

  const formatValue = useCallback((value?: number) => {
    const rawValue = value !== undefined ? value : metric?.data.at(-1)?.dataValues.at(-1)?.value || 0;
    return getFormattedMetricValue(rawValue, currencyCode, metric?.chartConfig);
  }, [currencyCode, metric?.chartConfig, metric?.data]);

  const formatDate = useCallback((date?: string) => {
    const rawValue = date || metric?.data.at(-1)?.date;
    if (!rawValue) return '';

    return chartDateFormatter(rawValue);
  }, [metric?.data]);

  const resetActiveValue = useCallback(() => {
    setActiveValue({
      value: formatValue(),
      date: formatDate(),
      hover: false,
    });
  }, [formatDate, formatValue]);

  useEffect(() => {
    resetActiveValue();
  }, [formatValue, formatDate, resetActiveValue]);

  if (isDeleting) return <StyledPlaceholderItem />;

  return (
    <>
      <StyledSummaryItem
        draggable={!isSaving}
        onMouseLeave={resetActiveValue}
        onDragStart={onDragStart}
        onDragEnter={onDragEnter}
        onDragEnd={onDragEnd}
        onDragOver={(ev) => ev.preventDefault()}
        id={`${index}`}
        over={over}
      >
        <StyledDragDrop>
          <Button
            variant="icon"
            color="secondary"
            hidden={isSaving}
          >
            <DragIcon />
          </Button>
        </StyledDragDrop>
        <StyledRemove>
          <Button
            variant="icon"
            color="secondary"
            onClick={() => onRemove(metric.id)}
            hidden={isSaving}
          >
            <DeleteIcon />
          </Button>
        </StyledRemove>
        <StyledSummaryItemLabel>
          <StyledLink to={isMetricInvalid ? '/' : `metrics/${metric.id}`}>
            {metric?.name}
          </StyledLink>
          {change !== null && (
            <StyledChange change={change}>
              <ArrowRightIcon />
              {change > 0 ? `+${change.toFixed(1)}` : change.toFixed(1)}%
            </StyledChange>
          )}
        </StyledSummaryItemLabel>
        <StyledSummaryItemValue
          hover={activeValue.hover}
          basicSize={activeValue.value.length > 10
            ? 22
            : activeValue.value.length > 8
              ? 28
              : 34
          }
        >
          {isMetricInvalid ? <InfoIcon /> : activeValue.value}
        </StyledSummaryItemValue>
        <StyledSummaryItemDate hover={activeValue.hover}>
          {isMetricInvalid ? 'This metric has been removed' : activeValue.date}
        </StyledSummaryItemDate>
        {!isMetricInvalid && (
          <StyledChart>
            <ResponsiveContainer>
              <ComposedChart
                data={data}
                margin={{ top: 4, right: 4, bottom: 4, left: 190 }}
                stackOffset="sign"
                onMouseLeave={resetActiveValue}
                onMouseMove={(event) => {
                  if (!!event.activePayload && event.activePayload?.[0].date !== activeValue.date) {
                    setActiveValue({
                      value: formatValue(event.activePayload?.[0].value),
                      date: formatDate(event.activePayload?.[0].payload.date),
                      hover: true,
                    });
                  }
                  if (!event.activePayload && activeValue.hover) {
                    resetActiveValue();
                  }
                }}
              >
                <Line
                  type="monotone"
                  dataKey={`values.${metric.id}`}
                  stroke={theme.colors.primary}
                  strokeWidth={3}
                  dot={false}
                />
                <RechartsTooltip
                  position={{ x: 24, y: 0 }}
                  content={() => null}
                />
              </ComposedChart>
            </ResponsiveContainer>
          </StyledChart>
        )}
      </StyledSummaryItem>
    </>
  );
};
