import { api } from "./api";
import {
  Reports,
  Report,
  ReportWithData,
  ReportLine,
  UpdateReportLinePayload,
  UpdateReportColumnPayload,
  UpdateReportLinesOrderPayload,
  AddReportColumnPayload,
  UpdateReportColumnsOrderPayload,
  ReportPeriods,
  ReportColumnWithValues,
  ReportVersions,
  AddReportPayload,
  BulkAddReportColumnPayload,
  UpdateReportPayload,
  UpdateReportLinesFormattingPayload,
} from "interfaces/reports";
import {
  apiAddRecipe,
  apiHardDeleteRecipe,
  apiUpdateRecipe,
} from "utils/api";
import { ReportsTags } from "./api-tags";

export const reportsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllReports: builder.query<Reports, void>({
      query: () => ({
        url: 'reports',
      }),
      providesTags: [ReportsTags.Reports],
    }),
    getReport: builder.query<ReportWithData, string>({
      query: (id) => ({
        url: `reports/${id}`,
      }),
      providesTags: [ReportsTags.Report],
    }),
    getReportPeriods: builder.query<ReportPeriods, void>({
      query: () => ({
        url: 'report_periods',
      }),
      providesTags: [ReportsTags.ReportPeriods],
    }),
    getReportVersions: builder.query<ReportVersions, void>({
      query: () => ({
        // TODO waiting on backend endpoint
        // url: 'report_verions',
        url: 'health',
      }),
      providesTags: [ReportsTags.ReportVersions],
      transformResponse: () => ([
        {
          id: null,
          name: 'System',
        },
      ]),
    }),
    addReport: builder.mutation<Report, AddReportPayload>({
      query: (body) => {
        return {
          url: 'reports',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (res, err) => !err ? [ReportsTags.Reports] : [],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data: addedReport} = await queryFulfilled;
          dispatch(reportsApi.util
            .updateQueryData('getAllReports', undefined, apiAddRecipe(addedReport)));
        } catch {}
      },
    }),
    removeLines: builder.mutation<void, { reportId: string, lineIds: string[] }>({
      query: ({ reportId, lineIds }) => {
        return {
          url: `reports/${reportId}/lines`,
          method: 'DELETE',
          body: lineIds,
        };
      },
      invalidatesTags: (res, err) => !err ? [ReportsTags.Reports] : [],
      onQueryStarted: async ({ reportId, lineIds }, { dispatch, queryFulfilled }) => {
        const patch = dispatch(reportsApi.util
          .updateQueryData('getReport', reportId, (report) => ({
            ...report,
            lines: [...report.lines].filter((line) => !lineIds.includes(line.id)),
          })));
        try {
          await queryFulfilled;
        } catch {
          patch.undo();
        }
      },
    }),
    addLine: builder.mutation<ReportLine[], { reportId: string, quantity?: number }>({
      query: ({ reportId, ...rest }) => {
        return {
          url: `reports/${reportId}/lines`,
          method: 'POST',
          body: rest,
        };
      },
      invalidatesTags: (res, err) => !err ? [ReportsTags.Reports] : [],
      onQueryStarted: async ({ reportId }, { dispatch, queryFulfilled }) => {
        try {
          const { data: addedLines} = await queryFulfilled;
          await queryFulfilled;
          dispatch(reportsApi.util
            .updateQueryData('getReport', reportId, (report) => ({
              ...report,
              lines: [
                ...report.lines,
                ...addedLines,
              ],
            })));
        } catch {}
      },
    }),
    updateLine: builder.mutation<ReportLine, UpdateReportLinePayload>({
      query: ({ reportId, lineId, ...rest }) => {
        return {
          url: `reports/${reportId}/lines/${lineId}`,
          method: 'PUT',
          body: rest,
        };
      },
      invalidatesTags: (res, err) => !err ? [ReportsTags.Reports] : [],
      onQueryStarted: async ({ reportId, lineId }, { dispatch, queryFulfilled }) => {
        try {
          const { data: updatedLine} = await queryFulfilled;

          dispatch(reportsApi.util
            .updateQueryData('getReport', reportId, (report) => ({
              ...report,
              lines: report.lines.map((line) => line.id === lineId ? updatedLine : line ),
            })));
        } catch {}
      },
    }),
    updateMultipleLinesFormatting: builder.mutation<void, UpdateReportLinesFormattingPayload>({
      query: ({ reportId, ...rest }) => {
        return {
          url: `reports/${reportId}/lines/bulk_edit`,
          method: 'POST',
          body: rest,
        };
      },
      invalidatesTags: (res, err) => !err ? [
        ReportsTags.Report,
        ReportsTags.Reports,
      ] : [],
    }),
    updateSingleLineFormatting: builder.mutation<ReportLine, UpdateReportLinePayload>({
      query: ({ reportId, lineId, ...rest }) => {
        return {
          url: `reports/${reportId}/lines/${lineId}`,
          method: 'PUT',
          body: rest,
        };
      },
      invalidatesTags: (res, err) => !err ? [ReportsTags.Reports] : [],
      onQueryStarted: async ({ reportId, lineId, format }, { dispatch, queryFulfilled }) => {
        const patch = dispatch(reportsApi.util
          .updateQueryData('getReport', reportId, (report) => ({
            ...report,
            lines: [...report.lines].map((line) => line.id === lineId ? { ...line, format } : line),
          })));
        try {
          await queryFulfilled;
        } catch {
          patch.undo();
        }
      },
    }),
    updateLinesOrder: builder.mutation<void, UpdateReportLinesOrderPayload>({
      query: ({ reportId, linesOrder }) => {
        return {
          url: `reports/${reportId}/lines_order`,
          method: 'PUT',
          body: linesOrder,
        };
      },
      invalidatesTags: (res, err) => !err ? [ReportsTags.Reports] : [],
      onQueryStarted: async ({ reportId, linesOrder }, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;

          dispatch(reportsApi.util
            .updateQueryData('getReport', reportId, (report) => ({
              ...report,
              lines: [...report.lines].sort((a, b) => linesOrder.indexOf(a.id) - linesOrder.indexOf(b.id)),
            })));
        } catch {}
      },
    }),
    addColumn: builder.mutation<ReportColumnWithValues, AddReportColumnPayload>({
      query: ({ reportId }) => {
        return {
          url: `reports/${reportId}/columns`,
          method: 'POST',
          body: {},
        };
      },
      invalidatesTags: (res, err) => !err ? [ReportsTags.Reports] : [],
      onQueryStarted: async ({ reportId }, { dispatch, queryFulfilled }) => {
        try {
          const { data: addedColumn} = await queryFulfilled;
          const { values: _, ...addedColumnWithoutValues } = addedColumn;

          dispatch(reportsApi.util
            .updateQueryData('getReport', reportId, (report) => ({
              ...report,
              columns: [
                ...report.columns,
                addedColumnWithoutValues,
              ],
              lines: report.lines.map((line) => {
                const foundValue = addedColumn.values.find(({ lineId }) => lineId === line.id)?.value;
                const newLineValues = [...line.values, {
                  columnId: addedColumn.id,
                  value: foundValue,
                }];

                return {
                  ...line,
                  values: newLineValues,
                };
              }),
            })));
        } catch {}
      },
    }),
    bulkAddColumns: builder.mutation<ReportColumnWithValues[], BulkAddReportColumnPayload>({
      query: ({ reportId, ...rest }) => {
        return {
          url: `reports/${reportId}/columns/bulk_add`,
          method: 'POST',
          body: rest,
        };
      },
      invalidatesTags: (res, err) => !err ? [ReportsTags.Reports, ReportsTags.Report] : [],
    }),
    updateColumn: builder.mutation<ReportColumnWithValues, UpdateReportColumnPayload>({
      query: ({ reportId, columnId, ...rest }) => {
        return {
          url: `reports/${reportId}/columns/${columnId}`,
          method: 'PUT',
          body: rest,
        };
      },
      invalidatesTags: (res, err) => !err ? [ReportsTags.Reports] : [],
      onQueryStarted: async ({ reportId, columnId, ...rest }, { dispatch, queryFulfilled }) => {
        const patch = dispatch(reportsApi.util
          .updateQueryData('getReport', reportId, (report) => ({
            ...report,
            columns: report.columns.map((column) => column.id === columnId ? { ...column, ...rest } : column),
          })));
        try {
          const { data: updatedColumn} = await queryFulfilled;

          dispatch(reportsApi.util
            .updateQueryData('getReport', reportId, (report) => ({
              ...report,
              lines: report.lines.map((line) => {
                const foundValue = updatedColumn.values.find(({ lineId }) => lineId === line.id)?.value;
                const newLineValues = line.values.map(({ columnId, value }) => ({
                  columnId,
                  value: columnId === updatedColumn.id ? foundValue : value,
                }));

                return {
                  ...line,
                  values: newLineValues,
                };
              }),
            })));
        } catch {
          patch.undo();
        }
      },
    }),
    updateColumnsOrder: builder.mutation<void, UpdateReportColumnsOrderPayload>({
      query: ({ reportId, columnsOrder }) => {
        return {
          url: `reports/${reportId}/columns_order`,
          method: 'PUT',
          body: columnsOrder,
        };
      },
      invalidatesTags: (res, err) => !err ? [ReportsTags.Reports] : [],
      onQueryStarted: async ({ reportId, columnsOrder }, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;

          dispatch(reportsApi.util
            .updateQueryData('getReport', reportId, (report) => ({
              ...report,
              columns: [...report.columns].sort((a, b) => columnsOrder.indexOf(a.id) - columnsOrder.indexOf(b.id)),
            })));
        } catch {}
      },
    }),
    removeColumns: builder.mutation<void, { reportId: string, columnIds: string[] }>({
      query: ({ reportId, columnIds }) => {
        return {
          url: `reports/${reportId}/columns`,
          method: 'DELETE',
          body: columnIds,
        };
      },
      invalidatesTags: (res, err) => !err ? [ReportsTags.Reports] : [],
      onQueryStarted: async ({ reportId, columnIds }, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;

          dispatch(reportsApi.util
            .updateQueryData('getReport', reportId, (report) => ({
              ...report,
              columns: [...report.columns].filter((column) => !columnIds.includes(column.id)),
            })));
        } catch {}
      },
    }),
    deleteReport: builder.mutation<void, string[]>({
      query: (ids) => {
        return {
          url: `reports`,
          method: 'DELETE',
          body: ids,
        };
      },
      onQueryStarted: async (ids, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(reportsApi.util
            .updateQueryData('getAllReports', undefined, apiHardDeleteRecipe(ids)));
        } catch {}
      },
      invalidatesTags: (res, err) => !err ? [
        ReportsTags.Report,
      ] : [],
    }),
    duplicateReport: builder.mutation<Report, { reportId: string; name?: string; }>({
      query: ({ reportId, ...rest }) => {
        return {
          url: `reports/${reportId}/duplicate`,
          method: 'POST',
          body: rest,
        };
      },
      onQueryStarted: async (ids, { dispatch, queryFulfilled }) => {
        try {
          const { data: addedReport } = await queryFulfilled;
          dispatch(reportsApi.util
            .updateQueryData('getAllReports', undefined, apiAddRecipe(addedReport)));
        } catch {}
      },
      invalidatesTags: (res, err) => !err ? [
        ReportsTags.Reports,
      ] : [],
    }),
    updateReport: builder.mutation<ReportWithData, UpdateReportPayload>({
      query: ({ reportId, ...rest }) => {
        return {
          url: `reports/${reportId}`,
          method: 'PUT',
          body: rest,
        };
      },
      onQueryStarted: async ({ reportId }, { dispatch, queryFulfilled }) => {
        try {
          const { data: updatedReport } = await queryFulfilled;
          dispatch(reportsApi.util
            .updateQueryData('getAllReports', undefined, apiUpdateRecipe(updatedReport as Report)));
          dispatch(reportsApi.util
            .updateQueryData('getReport', reportId, () => updatedReport));
        } catch {}
      },
      invalidatesTags: (res, err) => !err ? [
        ReportsTags.Reports,
      ] : [],
    }),
  }),
});

export const {
  useGetAllReportsQuery,
  useAddReportMutation,
  useGetReportQuery,
  useUpdateColumnMutation,
  useUpdateLineMutation,
  useAddLineMutation,
  useAddColumnMutation,
  useRemoveLinesMutation,
  useUpdateLinesOrderMutation,
  useUpdateColumnsOrderMutation,
  useRemoveColumnsMutation,
  useGetReportPeriodsQuery,
  useGetReportVersionsQuery,
  useDeleteReportMutation,
  useBulkAddColumnsMutation,
  useDuplicateReportMutation,
  useUpdateReportMutation,
  useUpdateSingleLineFormattingMutation,
  useUpdateMultipleLinesFormattingMutation,
} = reportsApi;
