import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Slider from 'rc-slider';
import { theme } from 'theme/theme';
import {
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  Bar,
  Area,
  ComposedChart,
  Legend,
  LabelList,
} from 'recharts';
import {
  CustomTooltipPosition,
  CustomTooltipWrapper,
  FormattingContainer,
  LoadingBarContainer,
  LoadingWrapper,
  PngWrapper,
  StyledChartContainer,
  StyledSliderContainer,
} from './styled';
import { useDebouncedEffect } from 'hooks/useDebouncedEffect';
import {
  ButtonsContainer,
  Divider,
  Spacer,
} from 'components/common/ButtonsContainer';
import {
  Loading,
  TimeoutMessage,
} from 'components/Loading';
import { CustomTooltip } from './CustomTooltip';
import { SectionLabel } from 'components/common/SectionLabel';
import {
  BarChartIcon,
  BuildIcon,
  DecimalsLeftIcon,
  DecimalsRightIcon,
  DownloadIcon,
  FontSizeDecreaseIcon,
  FontSizeIncreaseIcon,
  FullscreenCollapse,
  FullscreenExpand,
  LineChartIcon,
  MillionsIcon,
  NumberFormatIcon,
  PercentIcon,
  ThousandsIcon,
} from 'assets/icons';
import { FormattingBox } from './FormattingBox';
import {
  chartDateFormatter,
  defaultChartData,
  prepareDataObject,
} from 'utils/charts';
import {
  ChartBackendResponse,
  ChartConfig,
  ChartType,
} from 'interfaces/charts';
import { ChartButton } from './ChartButton';
import { FactaChartNoDataOverlay } from './NoDataOverlay';
import { useGenerateImage } from 'recharts-to-png';
import { saveAs } from 'file-saver';
import { LoadingBar } from 'components/common/LoadingBar';
import { getFormattedMetricValue } from 'utils/metrics';
import { useGetAuthMeQuery } from 'store/services/auth';
import { getCurrentCompany } from 'utils/currentCompany';

interface Props {
  chartData?: ChartBackendResponse;
  loading?: boolean | string;
  loadingBar?: boolean | string;
  timeoutMessages?: TimeoutMessage[];
  showLegend?: boolean;
  /**
   * - `full`: shows tooltip with all bars values, less performant for huge data loads
   * - `simple`: shows tooltip with value for hovered bar only
  */
  tooltipStyle: 'full' | 'simple' | 'none';
  shouldStackBars?: boolean;
  shouldStackLines?: boolean;
  onChartConfigChange?: (chartConfig: ChartConfig) => void;
  noDataMessage?: string;
  additionalFormattingBoxes?: Array<{
    key: string;
    title: string;
    componentRenderer: () => JSX.Element;
  }>;
}

export const FactaChart = ({
  chartData = defaultChartData,
  loading,
  loadingBar,
  timeoutMessages,
  showLegend,
  tooltipStyle,
  shouldStackBars,
  shouldStackLines,
  onChartConfigChange,
  noDataMessage,
  additionalFormattingBoxes,
}: Props) => {
  const LAST_N_MONTHS = 12;
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const [getDivPng, { ref: pngRef, isLoading: isGettingPng }] = useGenerateImage<HTMLDivElement>();
  const { data: user } = useGetAuthMeQuery();

  const currency = useMemo(() => getCurrentCompany(user)?.currency || {
    name: 'US Dollars',
    code: 'USD',
    symbol: '$',
  }, [user]);

  const { name, chartConfig } = chartData;

  const [fontSize, setFontSize] = useState(12);
  const [precision, setPrecision] = useState(chartConfig.precision || 0);
  const [multiplier, setMultiplier] = useState<'M' | 'K' | null>(chartConfig.multiplier || null);
  const [showChartValues, setShowChartValues] = useState(chartConfig.showChartValues || false);
  const [chartDataValueLabels, setChartDataValueLabels] = useState(chartData.chartDataValueLabels);
  const [valueFormat, setValueFormat] = useState(chartConfig.valueFormat);
  const [tempRange, setTempRange] = useState([0, 0]);
  const [range, setRange] = useState(tempRange);

  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isFormattingVisible, setIsFormattingVisible] = useState(true);
  const [selectedLabel, setSelectedLabel] = useState('');
  const [tooltipVisible, setTooltipVisible] = useState(true);
  const [customTooltip, setCustomTooltip] = useState<any>(null);
  const [hoveredLegendElement, setHoveredLegendElement] = useState('');

  const data = useMemo(() => prepareDataObject(chartData.data, chartData.chartDataValueLabels, range[0], range[1]), [chartData.chartDataValueLabels, chartData.data, range]);

  const lastBarLabelId = useMemo(() => chartDataValueLabels.filter(({ chartType }) => chartType === 'bar')
    .at(-1)?.id, [chartDataValueLabels]);
  const lastLineLabelId = useMemo(() => chartDataValueLabels.filter(({ chartType }) => chartType === 'line')
    .at(-1)?.id, [chartDataValueLabels]);
  const sliderMax = useMemo(() => chartData.data.length - 1, [chartData.data]);
  const sliderMarks = useMemo(() => chartData.data.reduce((a, v, index) => ({ ...a, [index]: chartDateFormatter(v.date) }), {}), [chartData.data]);

  const formatCurrency = useCallback((value: number) => {
    return getFormattedMetricValue(value, currency.code, {
      valueFormat,
      multiplier,
      precision,
    });
  }, [currency, multiplier, precision, valueFormat]);

  const valueFormatter = useMemo(() => {
    return valueFormat === 'percentage'
      ? (value: number) => `${(value * 100).toFixed(precision)}%`
      : formatCurrency;
  }, [formatCurrency, precision, valueFormat]);

  const handleBarOnClick = useCallback(({ tooltipPayload }: any) => {
    setSelectedLabel(selectedLabel === tooltipPayload[0].dataKey ? '' : tooltipPayload[0].dataKey);
  }, [selectedLabel]);
  const handleBarOnMouseEnter = useCallback((tooltipPayload: any) => setCustomTooltip(tooltipPayload), []);
  const handleLineOnMouseEnter = useCallback((value: any) => {
    setCustomTooltip({
      date: value.payload.date,
      tooltipPayload: [{
        date: value.payload.date,
        name: value.name,
        color: value.stroke,
        value: value.value,
      }],
      tooltipPosition: {
        x: value.cx,
        y: value.cy,
      },
    });
  }, []);
  const handleBarOnMouseLeave = useCallback(() => setCustomTooltip(null), []);
  const handleLineOnMouseLeave = handleBarOnMouseLeave;
  const handleLineOnClick = useCallback(({ dataKey }: any) => setSelectedLabel(selectedLabel === dataKey ? '' : dataKey), [selectedLabel]);
  const handleLegendOnMouseEnter = useCallback(({ dataKey }: any) => setHoveredLegendElement(dataKey), []);
  const handleLegendOnMouseLeave = useCallback(() => setHoveredLegendElement(''), []);
  const handleLegendOnClick = useCallback(({ dataKey }: any) => setSelectedLabel(selectedLabel === dataKey ? '' : dataKey), [selectedLabel]);

  const handleConvertChartType = (chartType: ChartType) => {
    setChartDataValueLabels(chartDataValueLabels.map((label) => ({ ...label, chartType })));
    onChartConfigChange && onChartConfigChange({
      valueFormat,
      multiplier,
      precision,
      showChartValues,
      chartType,
      chartPeriodType: chartConfig.chartPeriodType,
    });
  };

  const yAxisWidth = useMemo(() => {
    const charWidth = fontSize / 2;
    const maxChars = multiplier === 'M'
      ? 4
      : multiplier === 'K'
        ? 8
        : 11;
    return charWidth * (maxChars + (precision ? precision + 0.5 : 0));
  }, [fontSize, multiplier, precision]);

  useDebouncedEffect(() => {
    setRange(tempRange);
  }, [tempRange], 100);

  useEffect(() => {
    const startIndex = chartData.data.findIndex((entry) => entry.date === chartConfig.chartStartPeriod);
    const endIndex = chartData.data.findIndex((entry) => entry.date === chartConfig.chartEndPeriod);

    setTempRange(chartConfig.chartStartPeriod
      ? [
        startIndex === -1 ? 0 : startIndex,
        endIndex === -1 ? chartData.data.length - 1 : endIndex,
      ]
      : [Math.max(chartData.data.length - LAST_N_MONTHS, 0), chartData.data.length - 1]);

    setValueFormat(chartConfig.valueFormat);
    setMultiplier(chartConfig.multiplier || null);
    setPrecision(chartConfig.precision || 0);
    setShowChartValues(chartConfig.showChartValues || false);
    setChartDataValueLabels(chartData.chartDataValueLabels);
  }, [chartConfig, chartData]);

  useDebouncedEffect(() => {
    if (isFormattingVisible && chartData.data.length && onChartConfigChange) {
      onChartConfigChange({
        valueFormat,
        multiplier,
        precision,
        showChartValues,
        chartType: chartDataValueLabels[0].chartType,
        chartStartPeriod: chartData.data.at(tempRange[0])!.date,
        chartEndPeriod: chartData.data.at(tempRange[1])!.date,
        chartPeriodType: chartConfig.chartPeriodType,
      });
    }
  }, [multiplier, precision, showChartValues, valueFormat, range], 1000);

  useEffect(() => {
    if (valueFormat === 'percentage') {
      setMultiplier(null);
    }
  }, [valueFormat]);

  return (
    (<StyledChartContainer
      ref={chartContainerRef}
      fullscreen={isFullscreen}
      fontSize={fontSize}
    >
      {loading && (
        <LoadingWrapper>
          <Loading
            title={typeof (loading) === 'string' ? loading : 'Calculating data...'}
            timeoutMessages={timeoutMessages}
          />
        </LoadingWrapper>
      )}
      <ButtonsContainer paddingBottom={0}>
        <Spacer />
        <ChartButton
          tooltip="Download PNG"
          onClick={() => {
            setTimeout(async () => {
              const png = await getDivPng();
              if (png) {
                saveAs(png, `insights-chart-${name.replace(/[^a-z0-9]/gi, '-')
                  .toLowerCase() || 'unnamed'}.png`);
              }
            }, 0);
          }}
        >
          <DownloadIcon />
        </ChartButton>
        <ChartButton
          tooltip="Adjust Formatting"
          isActive={isFormattingVisible}
          onClick={() => setIsFormattingVisible(!isFormattingVisible)}
        >
          <BuildIcon />
        </ChartButton>
        <ChartButton
          key={`fullscreen_${isFullscreen ? 'full' : ''}`}
          tooltip={`${isFullscreen ? 'Exit' : 'Enter'} full-screen mode`}
          onClick={() => setIsFullscreen(!isFullscreen)}
        >
          {!isFullscreen ? <FullscreenExpand /> : <FullscreenCollapse />}
        </ChartButton>
      </ButtonsContainer>
      <FormattingContainer hidden={!isFormattingVisible}>
        <FormattingBox title="Values Formatting">
          <>
            <ChartButton
              tooltip="Percentage"
              isActive={valueFormat === 'percentage'}
              onClick={() => setValueFormat(valueFormat === 'percentage' ? 'number' : 'percentage')}
            >
              <PercentIcon />
            </ChartButton>
            <ChartButton
              tooltip="Currency"
              isActive={valueFormat === 'currency'}
              onClick={() => setValueFormat(valueFormat === 'currency' ? 'number' : 'currency')}
            >
              {currency.symbol}
            </ChartButton>
            <ChartButton
              tooltip="Multiple"
              isActive={valueFormat === 'multiple'}
              onClick={() => setValueFormat(valueFormat === 'multiple' ? 'number' : 'multiple')}
            >
              x
            </ChartButton>
            <Divider />
            <ChartButton
              tooltip="Millions"
              isActive={multiplier === 'M'}
              onClick={() => setMultiplier(multiplier === 'M' ? null : 'M')}
              disabled={valueFormat === 'percentage' || valueFormat === 'multiple'}
            >
              <MillionsIcon />
            </ChartButton>
            <ChartButton
              tooltip="Thousands"
              isActive={multiplier === 'K'}
              onClick={() => setMultiplier(multiplier === 'K' ? null : 'K')}
              disabled={valueFormat === 'percentage' || valueFormat === 'multiple'}
            >
              <ThousandsIcon />
            </ChartButton>
            <Divider />
            <ChartButton onClick={() => setPrecision(Math.max(precision - 1, 0))}>
              <DecimalsLeftIcon />
            </ChartButton>
            <ChartButton onClick={() => setPrecision(Math.min(precision + 1, 2))}>
              <DecimalsRightIcon />
            </ChartButton>
          </>
        </FormattingBox>
        <FormattingBox title="Chart Formatting">
          <>
            <ChartButton
              isActive={showChartValues}
              tooltip={`${showChartValues ? 'Hide' : 'Show'} Values`}
              onClick={() => setShowChartValues(!showChartValues)}
            >
              <NumberFormatIcon />
            </ChartButton>
            <ChartButton onClick={() => setFontSize(Math.max(fontSize - 2, 10))}>
              <FontSizeDecreaseIcon />
            </ChartButton>
            <ChartButton onClick={() => setFontSize(Math.min(fontSize + 2, 20))}>
              <FontSizeIncreaseIcon />
            </ChartButton>
            {chartDataValueLabels.length === 1 && (
              <>
                <Divider />
                <ChartButton
                  isActive={chartDataValueLabels.at(0)?.chartType === 'bar'}
                  tooltip="Bar Chart"
                  onClick={() => handleConvertChartType('bar')}
                  disabled={chartDataValueLabels.length > 1}
                >
                  <BarChartIcon />
                </ChartButton>
                <ChartButton
                  isActive={chartDataValueLabels.at(0)?.chartType === 'line'}
                  tooltip="Line Chart"
                  onClick={() => handleConvertChartType('line')}
                  disabled={chartDataValueLabels.length > 1}
                >
                  <LineChartIcon />
                </ChartButton>
              </>
            )}
          </>
        </FormattingBox>
        <FormattingBox
          title="Date Range"
          width={300}
          maxWidth={Math.min(chartData.data.length * 20 + 48, 400)}
        >
          <StyledSliderContainer>
            <Slider
              range
              min={0}
              max={sliderMax}
              marks={sliderMarks}
              onChange={(values) => setTempRange(values as number[])}
              draggableTrack
              value={tempRange}
            />
          </StyledSliderContainer>
        </FormattingBox>
        {additionalFormattingBoxes?.map(({ key, title, componentRenderer }) => (
          <FormattingBox
            key={key}
            title={title}
          >
            {componentRenderer()}
          </FormattingBox>
        ))}
      </FormattingContainer>
      {loadingBar && (
        <LoadingBarContainer>
          <LoadingBar />
        </LoadingBarContainer>
      )}
      <PngWrapper
        ref={pngRef}
        isGettingPng={isGettingPng}
      >
        <SectionLabel marginBottom={16}>
          <div>
            {name}
            <p>{data.length && chartDateFormatter(data.at(0).date)} - {data.length && chartDateFormatter(data.at(-1).date)}</p>
          </div>
        </SectionLabel>
        <CustomTooltipWrapper>
          {data.length === 0 && (
            <LoadingWrapper>
              <FactaChartNoDataOverlay
                title="No data found"
                additionalMessage={noDataMessage}
              />
            </LoadingWrapper>
          )}
          {tooltipStyle === 'simple' && customTooltip && (
            <CustomTooltipPosition
              style={{
                top: `${customTooltip.tooltipPosition.y}px`,
                left: customTooltip.tooltipPosition.x / chartContainerRef.current!.clientWidth < 0.5 ? `${customTooltip.tooltipPosition.x}px` : undefined,
                right: customTooltip.tooltipPosition.x / chartContainerRef.current!.clientWidth >= 0.5 ? `${chartContainerRef.current!.clientWidth - customTooltip.tooltipPosition.x}px` : undefined,
              }}>
              <CustomTooltip
                label={customTooltip.date}
                payload={customTooltip.tooltipPayload}
                viewBox={undefined}
                chartData={chartData}
                valueFormatter={valueFormatter}
              />
            </CustomTooltipPosition>
          )}
          <ResponsiveContainer
            width="100%"
            height="100%"
            aspect={isFullscreen ? undefined : 2.5}
          >
            <ComposedChart
              data={data}
              margin={{ top: fontSize + 5, right: 30, bottom: 5, left: 30 }}
              stackOffset="sign"
              onMouseEnter={() => setTooltipVisible(true)}
              onMouseLeave={() => setTooltipVisible(false)}
            >
              <CartesianGrid
                stroke={theme.colors.borderColorLight}
                vertical={false}
              />
              {showLegend && (
                <Legend
                  verticalAlign="top"
                  height={56}
                  onMouseEnter={handleLegendOnMouseEnter}
                  onMouseLeave={handleLegendOnMouseLeave}
                  onClick={handleLegendOnClick}
                />
              )}
              {tooltipVisible && tooltipStyle === 'full' && (
                <RechartsTooltip
                  isAnimationActive={false}
                  content={(props) => {
                    if (props.active) return (
                      <CustomTooltip
                        label={props.label}
                        payload={props.payload}
                        chartData={chartData}
                        viewBox={props.viewBox}
                        valueFormatter={valueFormatter}
                      />
                    );
                    return null;
                  }}
                />
              )}
              {chartDataValueLabels.map(({ id, name, chartType }, index) => (
                <React.Fragment key={`chart_element${id}`}>
                  {chartType === 'bar' && (
                    <Bar
                      stackId={shouldStackBars ? 'stackedBars' : undefined}
                      name={name}
                      dataKey={`values.${id}`}
                      fill={theme.chartColors[index]}
                      opacity={!hoveredLegendElement ? 1 : hoveredLegendElement !== `values.${id}` ? 0.2 : 1}
                      hide={!selectedLabel ? false : selectedLabel !== `values.${id}` ? true : false}
                      onClick={handleBarOnClick}
                      onMouseEnter={handleBarOnMouseEnter}
                      onMouseOut={handleBarOnMouseLeave}
                      isAnimationActive={false}
                    >
                      {showChartValues && (!shouldStackBars || shouldStackBars && selectedLabel)
                        ? <LabelList
                          position="top"
                          formatter={valueFormatter}
                        />
                        : null}
                      {showChartValues && shouldStackBars && (!selectedLabel && id === lastBarLabelId)
                        ? <LabelList
                          accumulate="sum"
                          position="top"
                          formatter={valueFormatter}
                          dataKey="sumBars"
                        />
                        : null}
                    </Bar>
                  )}
                  {chartType === 'line' && (
                    <Area
                      stackId={shouldStackLines ? 'stackedLines' : undefined}
                      type="monotone"
                      name={name}
                      dataKey={`values.${id}`}
                      stroke={theme.chartColors[index]}
                      strokeWidth={2}
                      fill={shouldStackLines ? theme.chartColors[index] : 'transparent'}
                      opacity={!hoveredLegendElement ? 1 : hoveredLegendElement !== `values.${id}` ? 0.2 : 1}
                      hide={!selectedLabel ? false : selectedLabel !== `values.${id}` ? true : false}
                      isAnimationActive={false}
                      activeDot={false}
                      dot={{
                        onMouseEnter: handleLineOnMouseEnter,
                        onMouseOut: handleLineOnMouseLeave,
                        onClick: handleLineOnClick,
                      }}
                    >
                      {showChartValues && (!shouldStackLines || shouldStackLines && selectedLabel)
                        ? <LabelList
                          position="top"
                          formatter={valueFormatter}
                        />
                        : null}
                      {showChartValues && shouldStackLines && (!selectedLabel && id === lastLineLabelId)
                        ? <LabelList
                          accumulate="sum"
                          position="top"
                          formatter={valueFormatter}
                          dataKey="sumLines"
                        />
                        : null}
                    </Area>
                  )}
                </React.Fragment>
              ))}
              <XAxis
                dataKey="date"
                tickFormatter={chartDateFormatter}
                offset={100}
                dy={10}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                width={yAxisWidth}
                tickFormatter={valueFormatter}
                tickCount={5}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </CustomTooltipWrapper>
      </PngWrapper>
    </StyledChartContainer>)
  );
};
