import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import * as yup from "yup";
import {
  ChevronDown,
  CloseIcon,
  CopyIcon,
} from "assets/icons";
import { Button } from "components/common/Button";
import { ButtonsContainer } from "components/common/ButtonsContainer";
import {
  StyledAdditionalOptionsContainer,
  StyledButtonContainer,
  StyledExpandButton,
  StyledFormContainer,
  StyledFormSectionLabel,
} from "components/common/Forms/styled";
import {
  Contract,
  ContractFormFields,
  ContractStateFormFields,
} from "interfaces/contracts";
import { actionBlocker } from "utils/actionBlocker";
import { TagType } from 'interfaces/tags';
import { useLazyContractsUniquenessQuery } from 'store/services/contracts';
import { useGetAllTagsQuery } from 'store/services/tags';
import {
  getTagsIdsForSelectedRows,
  tagsSortFn,
} from 'utils/tags';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import { Datepicker } from 'components/common/Datepicker';
import { Dropdown } from 'components/common/Dropdown';
import { InputBasic } from 'components/common/Input';
import { SectionLabel } from 'components/common/SectionLabel';
import {
  parseContractFormData,
  parseStateFormData,
} from 'utils/contracts';
import {
  formatDateToISO,
  isoToDate,
} from 'utils/dates';
import { copyToClipboard } from 'utils/copyToClipboard';
import {
  useAppDispatch,
  useAppSelector,
} from 'hooks/redux';
import { formStateActions } from 'store/slices/formState';
import { formDataSelector } from 'store/selectors/formState';
import { contractsActions } from 'store/slices/contracts';
import { predefinedContractSelector } from 'store/selectors/contracts';
import { useUniquenessCheck } from 'hooks/useUniquenessCheck';
import { CustomersDropdownLazy } from 'components/common/CustomersDropdownLazy';
import {
  addDays,
  addMonths,
} from 'date-fns';

interface Props {
  onClose: () => void;
  onSave: () => void;
  selectedRows: Contract[];
  onAddTag: () => void;
}

export const AddRenewContract = ({
  onClose,
  onSave,
  selectedRows,
  onAddTag,
}: Props) => {
  const dispatch = useAppDispatch();
  const formData = useAppSelector(formDataSelector);
  const [isOptionsExpanded, setIsOptionsExpanded] = useState(false);
  const isBulkEdit = selectedRows.length > 1;
  const predefinedContract = useAppSelector(predefinedContractSelector);
  const [contractsUnique, { isFetching: isCheckingUniqueness}] = useLazyContractsUniquenessQuery();

  const selectedRowsTagsIds = useMemo(() => getTagsIdsForSelectedRows(selectedRows), [selectedRows]);

  const {
    tagOptions,
  } = useGetAllTagsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      tagOptions: data
        ?.filter((tag) => tag.type === TagType.CONTRACT && (!tag.deleted || selectedRowsTagsIds.includes(tag.id)))
        ?.sort(tagsSortFn) || [],
    }),
  });

  const schema = yup.object({
    name: yup.string()
      .required('Contract name is required.')
      .test(
        'isUnique',
        'Contract name must be unique.',
        () => isNameUnique,
      ),
    customer: yup.array()
      .of(
        yup.object({
          id: yup.string(),
          name: yup.string(),
        }))
      .min(1, 'Customer is required.'),
    startDate: yup.date()
      .nullable()
      .required('Start date is required.'),
    endDate: yup.date()
      .nullable()
      .when('startDate', {
        is: (val: Date | null) => val,
        then: yup.date()
          .min(yup.ref('startDate'),
            'End date cannot be before start date.'),
      })
      .required('End date is required.'),
    bookingDate: yup.date()
      .nullable()
      .when('endDate', {
        is: (val: Date | null) => val,
        then: yup.date()
          .max(yup.ref('endDate'),
            'Booking date cannot be after end date.'),
      })
      .required('Booking date is required.'),
    cancelDate: yup.date()
      .nullable()
      .when('startDate', {
        is: (val: Date | null) => val,
        then: yup.date()
          .min(yup.ref('startDate'),
            'Cancel date cannot be before start date.',
          ),
      }),
    crmId: yup.string(),
    externalLink: yup.string()
      .url('Please provide valid URL.'),
    notes: yup.string(),
  })
    .required();

  const defaultValues = useMemo(() => ({
    ...(formData
      ? {
          ...parseStateFormData(formData as ContractStateFormFields),
        }
      : {
          name: '',
          customer: [],
          startDate: undefined,
          endDate: undefined,
          bookingDate: undefined,
          cancelDate: undefined,
          crmId: '',
          tags: [],
          externalLink: '',
          notes: '',
        }
    ),
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors, isDirty, dirtyFields },
    trigger,
    setValue,
    reset,
  } = useForm<ContractFormFields>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues,
  });

  const [isNameUnique, setName] = useUniquenessCheck(contractsUnique, {}, 'name', trigger);

  const triggerTouched = (field: any) => ((dirtyFields as Record<string, boolean | undefined>)[field]) && trigger(field);

  const startDate = isoToDate(selectedRows[0]?.startDate);
  const suggestedEndDate = startDate
    ? formatDateToISO(addDays(addMonths(startDate, 12), -1))
    : undefined;

  const handleSaveAndClose = handleSubmit((data) => {
    const contract = {
      ...predefinedContract,
      name: data.name,
      customer: data.customer[0],
      bookingDate: formatDateToISO(data.bookingDate!),
      startDate: formatDateToISO(data.startDate!),
      endDate:  formatDateToISO(data.endDate!),
      renewalDate: '',
      cancelDate: data.cancelDate ? formatDateToISO(data.cancelDate) : null,
      tags: data.tags,
      crmId: data.crmId,
      externalLink: data.externalLink,
      notes: data.notes,
    };
    dispatch(contractsActions.updatePredefinedContract(contract));
    onSave();
    onClose();
  });

  const handleSave = handleSubmit((data) => {
    const contract = {
      ...predefinedContract,
      name: data.name,
      customer: data.customer[0],
      bookingDate: formatDateToISO(data.bookingDate!),
      startDate: formatDateToISO(data.startDate!),
      endDate:  formatDateToISO(data.endDate!),
      cancelDate: data.cancelDate ? formatDateToISO(data.cancelDate) : null,
      tags: data.tags,
      crmId: data.crmId,
      externalLink: data.externalLink,
      notes: data.notes,
    };
    dispatch(contractsActions.updatePredefinedContract(contract));
    onSave();
  });

  const handleAddTag = ({ name }: { name: string }) => {
    dispatch(formStateActions.setPredefinedData({
      name,
      type: TagType.CONTRACT,
    }));
    dispatch(formStateActions.setFormData(parseContractFormData(getValues())));
    onAddTag();
  };

  useEffect(() => {
    const selectedRow = selectedRows[0];

    if (isBulkEdit) {
      reset(defaultValues);
    } else if (selectedRow && !formData) {
      reset({
        id: selectedRow.id,
        name: selectedRow.name,
        customer: [selectedRow.customer],
        startDate: isoToDate(selectedRow.startDate),
        endDate: isoToDate(selectedRow.endDate),
        bookingDate: isoToDate(selectedRow.bookingDate),
        cancelDate: isoToDate(selectedRow.cancelDate),
        crmId: selectedRow.crmId || '',
        tags: selectedRow.tags,
        externalLink: selectedRow.externalLink || '',
        notes: selectedRow.notes || '',
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows, isBulkEdit]);

  return (
    <>
      <header>
        <ButtonsContainer paddingBottom={16}>
          <Button
            aria-label="Close"
            variant="icon"
            size="large"
            onClick={() => actionBlocker(onClose, isDirty)}
            pushRight
            data-cy="acro-button-close"
          >
            <CloseIcon />
          </Button>
        </ButtonsContainer>
        <StyledFormSectionLabel data-cy="acro-header">
          <span>
            Renew Contract
          </span>
          {isBulkEdit
            ? (
                <span>{selectedRows.length} Contracts</span>
              )
            : (
                <ButtonsContainer>
                  <Button
                    variant="simple"
                    size="large"
                    type="button"
                    onClick={(e) => copyToClipboard(e, selectedRows[0].id)}
                    data-cy="acro-button-id"
                  >
                    <span>
                    Insights Contract ID
                    </span>
                    <CopyIcon />
                  </Button>
                </ButtonsContainer>
              )}
        </StyledFormSectionLabel>
      </header>
      <main>
        <StyledFormContainer>
          <SectionLabel secondary>
            Contract Overview
          </SectionLabel>
          <InputBasic
            isRequired
            labelText="Renewal Contract Name"
            placeholder="Name your renewal contract"
            {...register('name')}
            onBlur={(e) => {
              const fieldValue = e.target.value;

              if (fieldValue) {
                setValue('name', fieldValue.trim(), { shouldValidate: true });
              }
            }}
            onCustomChange={(e) => setName(e.target.value)}
            error={errors.name?.message}
            tooltip="You can change the contract name, but it must be a unique value."
            isLoading={isCheckingUniqueness}
          />
          <Controller
            name="customer"
            control={control}
            render={({ field }) =>
              <CustomersDropdownLazy
                labelText="Customer"
                labelField="name"
                valueField="id"
                searchBy="name"
                placeholder="Select Customer"
                values={getValues('customer')}
                {...field}
                error={errors.customer?.message}
                isRequired
                disabled
                dataCy="acro-dropdown-customer"
              />
            }
          />
          <Controller
            name="bookingDate"
            control={control}
            defaultValue={undefined}
            render={({ field }) =>
              <Datepicker
                labelText="Contract Booking Date"
                placeholder="Select Date"
                {...field}
                onChange={(date) => {
                  field.onChange(date);
                  triggerTouched('startDate');
                  triggerTouched('endDate');
                  triggerTouched('cancelDate');
                }}
                error={errors.bookingDate?.message}
                isRequired
                data-cy="acro-datepicker-booking"
                tooltip="Contract booking date is used to indicate when this contract was initially booked."
                suggestedDate={selectedRows[0]?.startDate}
              />
            }
          />
          <Controller
            name="startDate"
            control={control}
            defaultValue={undefined}
            render={({ field }) =>
              <Datepicker
                labelText="Contract Start Date"
                placeholder="Select Date"
                {...field}
                onChange={(date) => {
                  field.onChange(date);
                  triggerTouched('bookingDate');
                  triggerTouched('endDate');
                  triggerTouched('cancelDate');
                }}
                error={errors.startDate?.message}
                isRequired
                disabled
                data-cy="acro-datepicker-start"
                tooltip="The contract start date is used to indicate the beginning of service for the overall contract. It is used to calculate the term for ARR, MRR, and other SaaS metrics"
              />
            }
          />
          <Controller
            name="endDate"
            control={control}
            defaultValue={undefined}
            render={({ field }) =>
              <Datepicker
                labelText="Contract End Date"
                placeholder="Select Date"
                {...field}
                onChange={(date) => {
                  field.onChange(date);
                  triggerTouched('bookingDate');
                  triggerTouched('startDate');
                  triggerTouched('cancelDate');
                }}
                error={errors.endDate?.message}
                tooltip="Contract end date is used to calculate the term for ARR, MRR, and other SaaS metrics. This contract will remain active until it is renewed or cancelled."
                isRequired
                data-cy="acro-datepicker-end"
                suggestedDate={suggestedEndDate}
              />
            }
          />
          <Controller
            name="cancelDate"
            control={control}
            defaultValue={undefined}
            render={({ field }) =>
              <Datepicker
                labelText="Contract Cancel Date"
                placeholder="Select Date"
                {...field}
                onChange={(date) => {
                  field.onChange(date);
                  triggerTouched('bookingDate');
                  triggerTouched('startDate');
                  triggerTouched('endDate');
                }}
                error={errors.cancelDate?.message}
                isOptional
                data-cy="acro-datepicker-cancel"
                tooltip="Entering a contract cancel date will terminate the overall contract upon that date. Unless renewed, this contract will be counted as churn after this date."
              />
            }
          />
          <StyledExpandButton
            variant="borderless"
            size="large"
            type="button"
            onClick={() => setIsOptionsExpanded(!isOptionsExpanded)}
            isOpen={isOptionsExpanded}
          >
            Additional Options
            <ChevronDown />
          </StyledExpandButton>
          <StyledAdditionalOptionsContainer isOpen={isOptionsExpanded}>
            <Controller
              name="tags"
              control={control}
              render={({ field }) =>
                <Dropdown
                  labelText="Tags"
                  options={tagOptions}
                  labelField="name"
                  valueField="id"
                  searchBy="name"
                  placeholder="Select Tags"
                  entityName="tag"
                  values={getValues('tags')}
                  {...field}
                  isOptional
                  clearable
                  multi
                  error={errors.tags?.message}
                  tooltip="Categorize this contract with a contract tag(s)."
                  create
                  onCreateNew={handleAddTag}
                  dataCy="acro-dropdown-tag"
                />
              }
            />
            <InputBasic
              isOptional
              labelText="CRM ID"
              placeholder="Paste CRM identifier"
              tooltip="Paste in an ID from your CRM to help identify this contract."
              {...register('crmId')}
              error={errors.crmId?.message}
              data-cy="acro-input-crm"
            />
            <InputBasic
              isOptional
              labelText="External Link"
              placeholder="Paste an external link"
              tooltip="You can paste in a link to your CRM or wherever you store your contracts."
              {...register('externalLink')}
              error={errors.externalLink?.message}
              onBlur={(e) => {
                const fieldValue = e.target.value;

                if (fieldValue && !fieldValue.startsWith('http')) {
                  setValue('externalLink', 'http://' + fieldValue, { shouldValidate: true });
                }
              }}
              data-cy="acro-input-external"
            />
            <InputBasic
              isOptional
              labelText="Notes"
              placeholder="Add a quick note"
              {...register('notes')}
              error={errors.notes?.message}
              data-cy="acro-input-notes"
              multiline
            />
          </StyledAdditionalOptionsContainer>
        </StyledFormContainer>
      </main>
      <footer>
        <StyledButtonContainer pushRight>
          <Button
            type="submit"
            variant="outlined"
            color="secondary"
            onClick={handleSave}
            data-cy="acro-button-save-back"
          >
            SAVE
          </Button>
          <Button
            type="submit"
            onClick={handleSaveAndClose}
            data-cy="acro-button-save"
          >
            CONTINUE
          </Button>
        </StyledButtonContainer>
      </footer>
    </>
  );
};
