import React from 'react';
import {
  CloseIcon,
  OpenInNewIcon,
} from 'assets/icons';
import { Button } from 'components/common/Button';
import {
  ButtonsContainer,
  Spacer,
} from 'components/common/ButtonsContainer';
import {
  StyledButtonContainer,
  StyledContainer,
  StyledFormContainer,
} from 'components/common/Forms/styled';
import { InputBasic } from 'components/common/Input';
import { SectionLabel } from 'components/common/SectionLabel';
import { Metric } from 'interfaces/metrics';
import {
  useDeleteMetricMutation,
  useGetMetricDependantsQuery,
} from 'store/services/metrics';
import { Label } from 'components/common/Input/Label';
import { Hint } from 'components/common/Hint';
import { Dependant } from './styled';
import { toastSuccess } from 'utils/toast';

interface Props {
  selectedMetrics: Metric[];
  onClose: () => void;
  onSuccess: () => void;
}

export const DeleteMetric: React.FC<Props> = ({
  selectedMetrics,
  onClose,
  onSuccess,
}) => {
  const metric = selectedMetrics[0];
  const [deleteMetric, { isLoading }] = useDeleteMetricMutation();
  const { data: dependants, isFetching: isLoadingDependants } = useGetMetricDependantsQuery(metric?.id, { skip: !metric });
  const { metricDependants, reportDependants } = dependants || {
    metricDependants: [],
    reportDependants: [],
  };

  const handleDelete = () => {
    deleteMetric({ id: metric?.id, force: true })
      .unwrap()
      .then(() => {
        toastSuccess('Metric deleted successfully.');
        onSuccess();
      });
  };

  return (
    <StyledContainer>
      <ButtonsContainer alignTop>
        <SectionLabel marginBottom={24}>
          Delete Metric
        </SectionLabel>
        <Spacer />
        <Button
          aria-label="Close"
          variant="icon"
          size="large"
          onClick={onClose}
        >
          <CloseIcon />
        </Button>
      </ButtonsContainer>
      <StyledFormContainer onSubmit={handleDelete}>
        <InputBasic
          labelText="Metric"
          disabled
          name="metricName"
          value={metric?.name || ''}
        />
        {(!!metricDependants.length || !!reportDependants.length) && (
          <Hint
            marginBottom={0}
            blinking
          >
            {isLoadingDependants && 'Loading dependants...'}
            Metric you are going to delete is used in other places. Deleting this metric will also
            {!!metricDependants.length && ' delete dependant metrics'}
            {!!metricDependants.length && !!reportDependants.length && ' and'}
            {!!reportDependants.length && ' remove it from reports'}
            . See details below.
          </Hint>
        )}
        {!!metricDependants.length && (
          <div>
            <Label
              labelText="Dependant Metrics"
              name="dependantMetrics"
              tooltip="The Metric you are going to delete is used in the following metrics. Deleting this metric will also delete dependant metrics."
            />
            <ul>
              {metricDependants.map((dependant) => (
                <Dependant key={dependant.id}>
                  <span>{dependant.name}</span>
                  <Button
                    variant="icon"
                    color="primary"

                    onClick={() => window.open(`/metrics/${dependant.id}`)}>
                    <OpenInNewIcon />
                  </Button>
                </Dependant>
              ))}
            </ul>
          </div>
        )}
        {!!reportDependants.length && (
          <div>
            <Label
              labelText="Dependant Reports"
              name="reportDependants"
              tooltip="The Metric you are going to delete is used in the following reports. Deleting this metric will remove it from these reports."
            />
            <ul>
              {reportDependants.map((dependant) => (
                <Dependant key={dependant.id}>
                  <span>{dependant.name}</span>
                  <Button
                    variant="icon"
                    color="primary"
                    onClick={() => window.open(`/reports/${dependant.id}`)}
                  >
                    <OpenInNewIcon />
                  </Button>
                </Dependant>
              ))}
            </ul>
          </div>
        )}
      </StyledFormContainer>
      <StyledButtonContainer
        marginTop={20}
        pushRight
      >
        <Button
          type="button"
          variant="outlined"
          color="secondary"
          onClick={onClose}
          disabled={isLoading || isLoadingDependants}
        >
          CANCEL
        </Button>
        <Button
          type="button"
          color="error"
          onClick={handleDelete}
          disabled={isLoading || isLoadingDependants}
          isLoading={isLoading}
        >
          DELETE
        </Button>
      </StyledButtonContainer>
    </StyledContainer>
  );
};
