import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import structuredClone from '@ungap/structured-clone';
import {
  LeftPane,
  RightPane,
} from 'components/Layout';
import { AgGridReact } from '@ag-grid-community/react';
import { FactaTable } from 'components/FactaTable';
import {
  ColDef,
  GridOptions,
} from '@ag-grid-community/core';
import {
  useGetAccountMappingsQuery,
  useGetAccountTypesQuery,
  usePutAccountMappingTableMutation,
} from 'store/services/accounts';
import { RichSelectCellRenderer } from 'components/common/AgGridCellEditors/RichSelectCellRenderer';
import {
  GridApi,
  PasteEndEvent,
  ProcessCellForExportParams,
  ProcessDataFromClipboardParams,
} from '@ag-grid-community/core';
import { FinancialType } from 'interfaces/financials';
import { Loading } from 'components/Loading';
import { SectionLabel } from 'components/common/SectionLabel';
import {
  Breadcrumb,
  Breadcrumbs,
} from 'components/common/Breadcrumbs';
import { Box } from 'components/common/Box';
import { Button } from 'components/common/Button';
import {
  createAccountMappingFinancialsColumns,
  mapAccountMappingsToTableView,
  prepareAccountMappingTablePayload,
} from 'utils/accounts';
import {
  toastSuccess,
  toastWarning,
} from 'utils/toast';
import { useGlobalHotkeys } from 'hooks/useGlobalHotkeys';
import { useDraft } from 'hooks/useDraft';
import { CheckCircleIcon } from 'assets/icons';
import {
  ButtonsContainer,
  Divider,
  Spacer,
} from 'components/common/ButtonsContainer';
import { SavingIndicator } from 'components/SavingIndicator';
import { theme } from 'theme/theme';
import { useDebouncedCallback } from 'use-debounce';
import { DRAFT_SAVE_INTERVAL } from 'utils/constants';
import {
  useNavigate,
  useParams,
} from 'react-router';
import {
  caseInsensitiveComparator,
  numericComparator,
} from 'utils/aggrid';
import { DraftFound } from 'components/common/DraftFound';
import { PeriodType } from 'interfaces/accounts';
import { InactiveGLAccounts } from '../common/InactiveGLAccounts';
import {
  useAppDispatch,
  useAppSelector,
} from 'hooks/redux';
import { viewBySelector } from 'store/selectors/accountMappings';
import { accountMappingActions } from 'store/slices/accountMapping';
import { AgGridCellRendererDropdown } from 'components/common/AgGridCellRendererDropdown';

export const AccountMappingTableView = () => {
  const { financialType = FinancialType.PROFIT_AND_LOSS } = useParams<{ financialType: FinancialType }>();
  return <AccountMappingTableViewComponent key={financialType} />;
};

const AccountMappingTableViewComponent = () => {
  const gridRef = useRef<AgGridReact>(null);
  const dispatch = useAppDispatch();
  const [dirty, setDirty] = useState(false);
  const [columns, setColumns] = useState<any[]>([]);
  const [includeUnused, setIncludeUnused] = useState(false);
  const navigate = useNavigate();
  const { financialType = FinancialType.PROFIT_AND_LOSS } = useParams<{ financialType: FinancialType }>();
  useGlobalHotkeys();
  const [uncategorizedAccounts, setUncategorizedAccounts] = useState(0);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [isDraftApplicable, setIsDraftApplicable] = useState(true);
  const setViewBy = (period: PeriodType) => dispatch(accountMappingActions.toggleViewBy(period));
  const viewBy = useAppSelector(viewBySelector);
  const [putAccountMapping] = usePutAccountMappingTableMutation();
  const {
    data: accountMappingsData,
    isFetching: isLoadingAccountMappings,
  } = useGetAccountMappingsQuery({
    financialType,
  }, {
    refetchOnMountOrArgChange: true,
  });

  const [accountMappings, setAccountMappings] = useState<any[]>([]);

  useEffect(() => {
    if (accountMappingsData?.accountMappings?.length) {
      setAccountMappings(mapAccountMappingsToTableView(accountMappingsData));
    }
  }, [accountMappingsData]);

  const { data: accountTypes, isLoading: isLoadingAccountTypes } = useGetAccountTypesQuery(financialType);
  const categories = useMemo(() => accountTypes?.map((at, index) => `${index + 1}:${at.name}`) || [], [accountTypes]);

  const {
    clearDraft,
    draft,
    draftFound,
    isSavingDraft,
    saveDraft,
    setDraftFound,
  } = useDraft(`account_mapping_table_${financialType}`);

  const processCellFromClipboard = (params: ProcessCellForExportParams<any, any>) => {
    if (params.column.getColId() === 'accountType') {
      return categories.find((acc) => acc.split(':')[1] === params.value);
    }

    return params.value;
  };

  const processCellForClipboard = (params: ProcessCellForExportParams<any, any>) => params.column.getColId() === 'accountType'
    ? params.value?.split(':')[1] || ''
    : params.value;

  const onPasteEnd = (e: PasteEndEvent) => {
    updateUncategorized();
    e.api.autoSizeAllColumns();
    setDirty(true);
  };

  const processDataFromClipboard = useCallback(
    (params: ProcessDataFromClipboardParams): string[][] | null => {
      const data = [...params.data]
        .map((line) => line.map((cell) => cell.trim()));

      return data;
    },
    [],
  );

  const handleSaveDraft = () => {
    let items: any[] = [];
    gridRef.current?.api.forEachNode((node) => {
      items.push({ ...node.data });
    });
    saveDraft(items);
  };

  const handleDebouncedSaveDraft = useDebouncedCallback(handleSaveDraft, DRAFT_SAVE_INTERVAL);

  const handleSuccess = () => {
    const typeName = financialType === FinancialType.PROFIT_AND_LOSS
      ? 'Profit & Loss'
      : 'Balance Sheet';

    clearDraft();
    toastSuccess(`${typeName} Account Mapping successfully updated.`);
  };

  const handleSave = async () => {
    gridRef.current?.api.stopEditing();

    const items: any[] = [];
    gridRef.current?.api.forEachNode((node) => {
      items.push(node.data);
    });

    const payload = prepareAccountMappingTablePayload(items);
    const isPayloadComplete = payload.every(line => !(line.accountName ? !line.accountType : !!line.accountType));

    if (!isPayloadComplete) {
      toastWarning('Cannot save incomplete mappings. For every line you have to provide both Category and Facta Account.', {
        autoClose: 10000,
      });
      return;
    }

    await putAccountMapping({ financialType, payload })
      .unwrap();
    handleSuccess();
  };

  const updateUncategorized = useCallback(() => {
    let uncategorizedCount = 0;

    gridRef.current?.api.forEachNode((node) => {
      if (!node.data.accountName && !node.data.accountType && includeUnused !== node.data.used) {
        uncategorizedCount++;
      }
    });

    setUncategorizedAccounts(uncategorizedCount);
  }, [includeUnused]);

  const handleApplyDraft = () => {
    setTimeout(() => {
      gridRef.current?.api.setGridOption('rowData', structuredClone(draft || []));
      setDraftFound(false);
      setDirty(true);
      gridRef.current?.api.autoSizeAllColumns();
      updateUncategorized();
    }, 100);
  };


  const handleResetForm = () => {
    if (accountMappingsData?.accountMappings?.length) {
      setAccountMappings(mapAccountMappingsToTableView(accountMappingsData));
    }
    setDirty(false);
    clearDraft();
  };

  useEffect(() => {
    gridRef.current?.api?.setGridOption('rowData', accountMappings || []);
  }, [accountMappings, accountMappingsData]);

  const handleDismissDraft = () => {
    clearDraft();
    setDraftFound(false);
    setDirty(false);
    setIsDraftApplicable(true);
  };

  const updateInactiveGLAccounts = useCallback((api: GridApi) => {
    api.deselectAll();
    api.setFilterModel(includeUnused
      ? {}
      : {
        used: {
          filterType: 'set',
          values: ['true'],
        },
      });
  }, [includeUnused]);

  const gridOptions: GridOptions = {
    getRowId: (params) => params.data.glAccountId,
    onSelectionChanged: (e) => setSelectedRows(e.api.getSelectedRows()),
    onPasteEnd: onPasteEnd,
    onCellEditingStopped: (e) => {
      e.api.autoSizeAllColumns();
    },
    processDataFromClipboard,
    processCellFromClipboard,
    processCellForClipboard,
    suppressRowClickSelection: true,
    rowDragManaged: true,
    rowDragMultiRow: true,
    icons: {
      rowDrag: '<i class="ag-icon ag-icon-columns"/>',
    },
    undoRedoCellEditing: true,
    undoRedoCellEditingLimit: 10,
    tooltipShowDelay: 1000,
    enableCharts: true,
    statusBar: {
      statusPanels: [
        { statusPanel: 'agAggregationComponent' },
      ],
    },
    onCellValueChanged: () => {
      updateUncategorized();
      handleDebouncedSaveDraft();
      setDirty(true);
    },
    onFirstDataRendered: ({ api }) => {
      updateUncategorized();
      updateInactiveGLAccounts(api);
    },
  };

  const commonColumns: ColDef[] = useMemo(() => ([
    {
      field: '',
      headerName: '',
      pinned: 'left',
      maxWidth: 52,
      rowDrag: true,
      rowDragText: (item: any, items: number) => {
        return items > 1
          ? `Dragging ${items} rows...`
          : item.rowNode.data.account || 'Dragging...';
      },
    },
    {
      field: '',
      headerName: '',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      pinned: 'left',
      maxWidth: 52,
    },
    {
      field: 'accountType',
      headerName: 'Category',
      editable: true,
      resizable: true,
      sortable: true,
      pinned: 'left',
      filter: 'agSetColumnFilter',
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      minWidth: 225,
      flex: 1,
      cellEditor: 'agRichSelectCellEditor',
      cellEditorPopup: false,
      cellEditorParams: {
        values: categories,
        cellRenderer: RichSelectCellRenderer,
        cellHeight: 40,
        allowTyping: true,
      },
      cellRenderer: ({ value }: any) => (
        <AgGridCellRendererDropdown>
          {value ? value.split(':')[1] : '-'}
        </AgGridCellRendererDropdown>
      ),
      cellClass: ({ value }) => !value ? 'cell-warning' : '',
      tooltipValueGetter: ({ value }) => !value ? 'Possibly missing value' : '',
      valueSetter: (params: any) => {
        params.data.accountType = params.newValue?.split(':')[1] || null;
        return true;
      },
      valueGetter: (params: any) => {
        return categories.find((acc) => acc.split(':')[1] === params.data.accountType);
      },
      comparator: numericComparator(),
    },
    {
      field: 'accountName',
      headerName: 'Facta Account',
      editable: true,
      resizable: true,
      sortable: true,
      pinned: 'left',
      filter: true,
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      minWidth: 215,
      flex: 1,
      cellClass: ({ value }) => !value ? 'cell-warning' : '',
      tooltipValueGetter: ({ value }) => !value ? 'Possibly missing value' : '',
    },
    {
      field: 'glAccountDisplayName',
      headerName: 'GL Account',
      pinned: 'left',
      resizable: true,
      sortable: true,
      filter: true,
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      comparator: caseInsensitiveComparator(),
      minWidth: 300,
      flex: 1,
    },
    {
      field: 'source',
      headerName: 'GL Account Source',
      resizable: true,
      sortable: true,
      filter: true,
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      valueFormatter: ({ value }: any) => value === 'manual' ? 'Manual' : value,
      minWidth: 225,
    },
    {
      field: 'used',
      headerName: 'Used',
      hide: true,
      filter: true,
    },
  ]), [categories]);

  const columnDefs: ColDef[] = useMemo(() => {
    return [
      ...commonColumns,
      ...columns,
    ];
  }, [columns, commonColumns]);

  useEffect(() => {
    if (draft && Array.isArray(draft) && accountMappingsData?.accountMappings?.length) {
      const localAccounts = accountMappingsData.accountMappings?.map((am) => am.glAccount.name)
        .sort()
        .toString();

      const draftAccounts = draft.map((da) => da.glAccountName)
        .sort()
        .toString();

      setIsDraftApplicable(localAccounts === draftAccounts);
    }
  }, [accountMappingsData, draft]);

  const getColumnNames = useMemo(() => (type: PeriodType) => {
    return accountMappingsData?.financialsDataRange[type].map((date) => `financialsData.${type}.${date}`) || [];
  }, [accountMappingsData?.financialsDataRange]);

  useEffect(() => {
    gridRef.current?.api?.setColumnsVisible(getColumnNames('monthly'), viewBy.includes('monthly'));
    gridRef.current?.api?.setColumnsVisible(getColumnNames('quarterly'), viewBy.includes('quarterly'));
    gridRef.current?.api?.setColumnsVisible(getColumnNames('annual'), viewBy.includes('annual'));
  }, [getColumnNames, viewBy]);

  useEffect(() => {
    if (gridRef.current?.api && accountMappingsData?.accountMappings?.length) {
      setColumns(createAccountMappingFinancialsColumns(accountMappingsData, viewBy));
    }
  }, [gridRef.current?.api, accountMappingsData, viewBy]);

  useEffect(() => {
    if (gridRef.current?.api) {
      updateInactiveGLAccounts(gridRef.current?.api);
    }
  }, [updateInactiveGLAccounts]);

  useEffect(() => {
    if (gridRef.current?.api) {
      updateUncategorized();
    }
  }, [updateUncategorized]);

  return (
    <>
      <LeftPane>
        <Breadcrumbs>
          <Breadcrumb link="/accounts">
            Financial Accounts
          </Breadcrumb>
          <Breadcrumb>
            {financialType === FinancialType.PROFIT_AND_LOSS ? 'Profit & Loss Account Mapping' : 'Balance Sheet Account Mapping'}
          </Breadcrumb>
        </Breadcrumbs>
        <ButtonsContainer>
          <SectionLabel marginBottom={30}>
            <span>
              {financialType === FinancialType.PROFIT_AND_LOSS ? 'Profit & Loss Account Mapping' : 'Balance Sheet Account Mapping'}
              <ins>Table view</ins>
              <p>Create Facta Accounts to map uncategorized GL accounts into simplified views for your business.</p>
            </span>
          </SectionLabel>
          <Spacer />
          <Button
            minWidth={100}
            variant="borderless"
            size="large"
            onClick={() => navigate(`/accounts/account-mapping/${financialType}`)}
          >
            Tree View
          </Button>
        </ButtonsContainer>
        {isLoadingAccountMappings || isLoadingAccountTypes
          ? (
            <Loading />
          )
          : (
            <>
              <Box
                condensed
                marginBottom={16}
              >
                <ButtonsContainer>
                  <div>&nbsp;&nbsp;{gridRef.current?.api?.getDisplayedRowCount() || accountMappings.length} GL Accounts</div>
                  <Divider />
                  <div>{uncategorizedAccounts} Uncategorized</div>
                  {uncategorizedAccounts === 0 && <CheckCircleIcon color={theme.colors.success} />}
                  <Spacer />
                  <SavingIndicator isSaving={isSavingDraft}>Saving Draft...</SavingIndicator>
                  <Button
                    variant="borderless"
                    onClick={handleResetForm}
                  >
                    Revert Changes
                  </Button>
                  <Button
                    variant="contained"
                    disabled={!dirty}
                    onClick={handleSave}
                    minWidth={175}
                  >
                    SAVE CHANGES
                  </Button>
                </ButtonsContainer>
              </Box>
              <FactaTable
                gridRef={gridRef}
                data={accountMappings}
                columnDefs={columnDefs}
                selectedRowsLength={selectedRows.length}
                entityName={'line'}
                gridOptions={gridOptions}
                condensed
                suppressQuantityDisplay
                customEmptyState={() => <>Nothing to show</>}
                customButtons={[
                  {
                    title: 'View by:',
                    pushRight: true,
                  },
                  {
                    title: 'Month',
                    onClick: () => setViewBy('monthly'),
                    active: viewBy.includes('monthly'),
                    variant: 'borderless',
                    size: 'large',
                  },
                  {
                    title: 'Quarter',
                    onClick: () => setViewBy('quarterly'),
                    active: viewBy.includes('quarterly'),
                    variant: 'borderless',
                    size: 'large',
                  },
                  {
                    title: 'Year',
                    onClick: () => setViewBy('annual'),
                    active: viewBy.includes('annual'),
                    variant: 'borderless',
                    size: 'large',
                  },
                ]}
                customJSX={<InactiveGLAccounts
                  setIncludeUnused={setIncludeUnused}
                  includeUnused={includeUnused}
                />}
                onResetView={() => setViewBy('monthly')}
                onClearAllFilters={() => setIncludeUnused(false)}
                clearAllFiltersExcludeProperty="used"
              />
            </>
          )}
      </LeftPane>
      <RightPane isOpen={draftFound}>
        <DraftFound
          isDraftApplicable={isDraftApplicable}
          onApplyDraft={handleApplyDraft}
          onDismissDraft={handleDismissDraft}
        />
      </RightPane>
    </>
  );
};
