import styled from "styled-components";
import { theme } from "theme/theme";

export const IconWrapper = styled.div`
  height: 20px;
  cursor: pointer;

  &:hover {
    color: ${theme.colors.primary};
  }

  svg {
    margin: 0 12px;
    width: 18px;
    height: 18px;
  }
`;

export const StyledDatePickerWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  .react-datepicker {
    font-family: inherit;
    font-size: 14px;
    box-shadow: ${theme.boxShadow};
    background-color: ${theme.colors.mainBackground};
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker-popper {
    z-index: 3;
  }
  
  .react-datepicker__month-container,
  .react-datepicker__year--container {
    padding: 12px 16px;
  }

  .react-datepicker__header {
    background-color: ${theme.colors.transparent};
    border-bottom: none;
    position: initial;
  }

  .react-datepicker__day,
  .react-datepicker__day-name {
    color: ${theme.colors.textDark};
    border-top: solid 2px ${theme.colors.transparent};
    border-bottom: solid 2px ${theme.colors.transparent};
    border-radius: 50%;
    width: 44px;
    line-height: 40px;
    text-align: center;
    margin: 2px 0;

    &--selected {
      background-color: ${theme.colors.primaryBleach};
      border: solid 2px ${theme.colors.primary};
    }
  }

  .react-datepicker__day-name {
    font-weight: 700;
  }

  .react-datepicker__day:hover {
    background-color: ${theme.colors.primaryBleach};
  }

  .react-datepicker__day--keyboard-selected {
    background-color: ${theme.colors.primaryBleach};
  }

  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range {
    border-radius: 0;
    background-color: ${theme.colors.primaryBleach};
    border-top: solid 2px ${theme.colors.primary};
    border-bottom: solid 2px ${theme.colors.primary};
  }

  .react-datepicker__day--selecting-range-start,
  .react-datepicker__day--range-start {
    border-radius: 50% 0 0 50%;
    border-right: none;
    margin: 0 !important;
  }
  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--selecting-range-start):hover,
  .react-datepicker__day--selecting-range-end,
  .react-datepicker__day--range-end {
    border-radius: 0 50% 50% 0;
    border-left: none;
    border-right: solid 2px ${theme.colors.primary};
  }

  .react-datepicker__day--range-start.react-datepicker__day--range-end,
  .react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start:hover {
    border-radius: 50% !important;
    border: solid 2px ${theme.colors.primary};
  }

  .react-datepicker__close-icon {
    &:after {
      background-color: ${theme.colors.transparent};
      color: ${theme.colors.textDark};
      padding: 1px;
      width: 18px;
      font-size: 16px;
    }

    &:hover::after {
      color: tomato;
    }
  }

  .react-datepicker__navigation {
    top: 18px;
  }

  .react-datepicker__year-text,
  .react-datepicker__month .react-datepicker__month-text,
  .react-datepicker__month .react-datepicker__quarter-text {
    padding: 6px 0;
  }

  .react-datepicker__year-text--selected,
  .react-datepicker__quarter--selected,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected, 
  .react-datepicker__quarter--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: ${theme.colors.primaryBleach};
    border: solid 2px ${theme.colors.primary};
    color: ${theme.colors.textDark};
  }

  .react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
  .react-datepicker__month-text.react-datepicker__month--selected:hover {
    background-color: ${theme.colors.primaryBleach};
  }

  .react-datepicker__year-text {
    flex-grow: 1;
  }
`;