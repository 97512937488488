import { CompanyRevenueType } from "interfaces/company";
import {
  CohortDimensionOptionsContracts,
  CohortDimensionOptionsSubscriptions,
} from "./cohortDetail";

export const RevenueDetailMetricsOptions = [
  { name: 'Revenue', value: 'revenue' },
  { name: 'New', value: 'new' },
  { name: 'Upgrade', value: 'upgrade' },
  { name: 'New + Upgrade', value: 'newUpgrade' },
  { name: 'Downgrade', value: 'downgrade' },
  { name: 'Net Expansion', value: 'netExpansion' },
  { name: 'Churned (Downgrade + Cancelled)', value: 'churned' },
  { name: 'Cancelled', value: 'cancelled' },
  { name: 'Net New Revenue', value: 'netNewRevenue' },
];

export const RevenueDetailDimensionOptionsContracts = [
  { name: 'Customer' },
  { name: 'Customer Tags' },
  { name: 'Product' },
  { name: 'Product Tags' },
  { name: 'Contract' },
  { name: 'Contract Tags' },
  { name: 'Contract Line Tags' },
  { name: 'None' },
];

export const RevenueDetailDimensionOptionsSubscriptions = [
  { name: 'Customer' },
  { name: 'Customer Tags' },
  { name: 'Product' },
  { name: 'Product Tags' },
  { name: 'Subscription Tags' },
  { name: 'None' },
];

export const getDimensionOptions = (isCohorts: boolean, companyRevenueType: CompanyRevenueType) => {
  if (isCohorts) {
    if (companyRevenueType === CompanyRevenueType.CONTRACTS) {
      return CohortDimensionOptionsContracts;
    } else {
      return CohortDimensionOptionsSubscriptions;
    }
  } else {
    if (companyRevenueType === CompanyRevenueType.CONTRACTS) {
      return RevenueDetailDimensionOptionsContracts;
    } else {
      return RevenueDetailDimensionOptionsSubscriptions;
    }
  }
};