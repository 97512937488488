import { FinancialType } from "interfaces/financials";
import { api } from "./api";
import {
  AccountMappingParams,
  AccountMappingTableUpdatePayload,
  AccountMappingTreeUpdatePayload,
  AccountMappings,
  AccountTypes,
  Accounts,
  FactaAccounts,
} from "interfaces/accounts";
import {
  AccountsTags,
  CommonMetricTags,
} from "./api-tags";

export const accountsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAccountMappings: builder.query<AccountMappings, AccountMappingParams>({
      query: ({ financialType, mappedOnly }) => ({
        url: `account_mappings/${financialType}`,
        params: {
          mappedOnly: mappedOnly?.toString() || '',
        },
      }),
      transformResponse: (response: AccountMappings) => ({
        ...response,
        accountMappings: response.accountMappings.map((am) => ({
          ...am,
          glAccount: {
            ...am.glAccount,
            displayName: am.glAccount.number
              ? `${am.glAccount.number} - ${am.glAccount.name}`
              : am.glAccount.name,
          },
        })),
      }),
      providesTags: [AccountsTags.AccountMappings],
    }),
    getAccountTypes: builder.query<AccountTypes, FinancialType>({
      query: (financialType) => ({
        url: `account_types/${financialType}`,
      }),
      providesTags: [AccountsTags.AccountTypes],
    }),
    getAccountsByFinancialType: builder.query<Accounts, FinancialType>({
      query: (financialType) => ({
        url: `accounts/${financialType}`,
      }),
      providesTags: [AccountsTags.Accounts],
    }),
    getFactaAccounts: builder.query<FactaAccounts, void>({
      query: () => ({
        url: `accounts`,
      }),
      providesTags: [AccountsTags.FactaAccounts],
    }),
    putAccountMappingTable: builder.mutation<void, {
      financialType: FinancialType;
      payload: AccountMappingTableUpdatePayload;
    }>({
      query: ({ financialType, payload }) => ({
        method: 'PUT',
        url: `account_mappings/table/${financialType}`,
        body: payload,
      }),
      invalidatesTags: (res, err) => !err ? [
        AccountsTags.AccountMappings,
        AccountsTags.Accounts,
        AccountsTags.FactaAccounts,
        ...CommonMetricTags,
      ] : [],
    }),
    putAccountMappingTree: builder.mutation<void, {
      financialType: FinancialType;
      payload: AccountMappingTreeUpdatePayload;
    }>({
      query: ({ financialType, payload }) => ({
        method: 'PUT',
        url: `account_mappings/tree/${financialType}`,
        body: payload,
      }),
      invalidatesTags: (res, err) => !err ? [
        AccountsTags.AccountMappings,
        AccountsTags.Accounts,
        AccountsTags.FactaAccounts,
        ...CommonMetricTags,
      ] : [],
    }),
  }),
});

export const {
  useGetAccountMappingsQuery,
  useGetAccountTypesQuery,
  usePutAccountMappingTableMutation,
  usePutAccountMappingTreeMutation,
  useGetAccountsByFinancialTypeQuery,
  useGetFactaAccountsQuery,
} = accountsApi;
