import React from 'react';
import { CloseIcon } from 'assets/icons';
import { ButtonsContainer } from 'components/common/ButtonsContainer';
import {
  MetricOrigin,
  MetricWithDetails,
  UpdateMetricRequest,
} from 'interfaces/metrics';
import { Button } from 'components/common/Button';
import structuredClone from '@ungap/structured-clone';
import { useUpdateMetricMutation } from 'store/services/metrics';
import { toastSuccess } from 'utils/toast';
import { Box } from 'components/common/Box';
import { SectionLabel } from 'components/common/SectionLabel';
import { useGetAuthMeQuery } from 'store/services/auth';
import { getCurrentCompany } from 'utils/currentCompany';
import { CompanyRevenueType } from 'interfaces/company';
import { Tooltip } from 'components/common/Tooltip';

interface Props {
  onEdit: () => void;
  metric: MetricWithDetails;
}

export const MetricFilteredBy = ({ onEdit, metric }: Props) => {
  const { data: user } = useGetAuthMeQuery();
  const [updateMetric, { isLoading: isSaving }] = useUpdateMetricMutation();
  const companyRevenueType = getCurrentCompany(user)?.companyRevenueType;

  const isSystemMetric = metric?.origin === MetricOrigin.SYSTEM;

  const clearAllFilters = () => {
    const payload: UpdateMetricRequest = {
      metricId: metric.id,
      name: metric.name,
      description: metric.description,
      type: metric.type.id,
      aggregationMethod: metric.aggregationMethod,
      tags: metric.tags,
      recurringRevenueType: metric.recurringRevenueType,
      filters: {
        lookback: metric.filters.lookback,
        customerFilter: [],
        customerTagFilter: [],
        productFilter: [],
        productTagFilter: [],
        revenueTypeFilter: [],
        contractTagFilter: [],
        contractLineTagFilter: [],
      },
      startDateField: metric.startDateField,
    };

    return updateMetric(payload)
      .unwrap()
      .then(() => {
        toastSuccess('Metric filters cleared.');
      });
  };

  const removeFilter = (filterType: string, value?: string) => {
    const updatedFilters = structuredClone(metric!.filters);
    if (filterType === 'lookbackPeriod') {
      updatedFilters.lookback.period = null;
    } else if (filterType === 'lookbackYTD') {
      updatedFilters.lookback.ytd = false;
    } else if (filterType === 'revenueTypeFilter') {
      updatedFilters.revenueTypeFilter = metric!.filters.revenueTypeFilter.filter((r) => r !== value);
    } else {
      (updatedFilters as any)[filterType] = (metric!.filters as any)[filterType].filter((f: any) => f.id !== value);
    }

    const payload: UpdateMetricRequest = {
      metricId: metric.id,
      name: metric.name,
      description: metric.description,
      type: metric.type.id,
      aggregationMethod: metric.aggregationMethod,
      tags: metric.tags,
      recurringRevenueType: metric.recurringRevenueType,
      filters: {
        lookback: updatedFilters.lookback?.period || updatedFilters.lookback?.ytd
          ? {
              period: updatedFilters.lookback.period ? Number(updatedFilters.lookback.period) : null,
              ytd: updatedFilters.lookback.ytd,
            }
          : null,
        customerFilter: updatedFilters.customerFilter.map(({ id }) => id),
        customerTagFilter: updatedFilters.customerTagFilter.map(({ id }) => id),
        productFilter: updatedFilters.productFilter.map(({ id }) => id),
        productTagFilter: updatedFilters.productTagFilter.map(({ id }) => id),
        revenueTypeFilter: updatedFilters.revenueTypeFilter,
        contractTagFilter: updatedFilters.contractTagFilter.map(({ id }) => id),
        contractLineTagFilter: updatedFilters.contractLineTagFilter.map(({ id }) => id),
      },
      startDateField: metric.startDateField,
    };

    return updateMetric(payload)
      .unwrap()
      .then(() => {
        toastSuccess('Metric filter removed.');
      });
  };

  return (
    <Box>
      <SectionLabel
        tertiary
        marginBottom={0}
      >
        <span>
          Filters
          {' '}
          <Button
            variant="simple"
            onClick={onEdit}
            inline
            disabled={isSaving}
            hidden={isSystemMetric}
          >
            Edit
          </Button>
        </span>
        <Tooltip title={isSystemMetric ? 'Filters on System Metrics cannot be edited.' : ''}>
          <Button
            onClick={clearAllFilters}
            variant="simple"
            disabled={isSaving || isSystemMetric}
          >
          Clear
          </Button>
        </Tooltip>
      </SectionLabel>
      <ButtonsContainer>
        {metric?.filters?.revenueTypeFilter.map((ent) => (
          <Button
            key={`fb_${ent}`}
            variant="outlined"
            size="large"
            onClick={() => removeFilter('revenueTypeFilter', ent)}
            disabled={isSystemMetric}
          >
            Revenue Type: {ent}
            {!isSystemMetric && <CloseIcon />}
          </Button>
        ))}
        {metric?.filters?.customerFilter.map((ent) => (
          <Button
            key={`fb_${ent.id}`}
            variant="outlined"
            size="large"
            onClick={() => removeFilter('customerFilter', ent.id)}
          >
            Customer: {ent.name}
            <CloseIcon />
          </Button>
        ))}
        {metric?.filters?.productFilter.map((ent) => (
          <Button
            key={`fb_${ent.id}`}
            variant="outlined"
            size="large"
            onClick={() => removeFilter('productFilter', ent.id)}
          >
            Product: {ent.name}
            <CloseIcon />
          </Button>
        ))}
        {metric?.filters?.customerTagFilter.map((ent) => (
          <Button
            key={`fb_${ent.id}`}
            variant="outlined"
            size="large"
            onClick={() => removeFilter('customerTagFilter', ent.id)}
          >
            Customer Tag: {ent.name}
            <CloseIcon />
          </Button>
        ))}
        {metric?.filters?.productTagFilter.map((ent) => (
          <Button
            key={`fb_${ent.id}`}
            variant="outlined"
            size="large"
            onClick={() => removeFilter('productTagFilter', ent.id)}
          >
            Product Tag: {ent.name}
            <CloseIcon />
          </Button>
        ))}
        {metric?.filters?.contractTagFilter.map((ent) => (
          <Button
            key={`fb_${ent.id}`}
            variant="outlined"
            size="large"
            onClick={() => removeFilter('contractTagFilter', ent.id)}
          >
            Contract Tag: {ent.name}
            <CloseIcon />
          </Button>
        ))}
        {metric?.filters?.contractLineTagFilter.map((ent) => (
          <Button
            key={`fb_${ent.id}`}
            variant="outlined"
            size="large"
            onClick={() => removeFilter('contractLineTagFilter', ent.id)}
          >
            {companyRevenueType === CompanyRevenueType.CONTRACTS ? 'Contract Line Tag' : 'Subscription Tag'}: {ent.name}
            <CloseIcon />
          </Button>
        ))}
      </ButtonsContainer>
    </Box>
  );
};
