import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { GridOptions } from '@ag-grid-community/core';
import {
  LeftPane,
  RightPane,
} from 'components/Layout';
import { SectionLabel } from 'components/common/SectionLabel';
import { Button } from 'components/common/Button';
import {
  ButtonsContainer,
  Spacer,
} from 'components/common/ButtonsContainer';
import {
  DownloadIcon,
  RefreshIcon,
} from 'assets/icons';
import { getColumnDefs } from './columnDefinitions';
import { useGetAllProductsQuery } from 'store/services/products';
import { AddProduct } from './AddProduct';
import { EditProducts } from './EditProducts';
import { DeleteProducts } from './DeleteProducts';
import { Product } from 'interfaces/products';
import { useRightPanes } from 'hooks/useRightPanes';
import { AddTag } from 'pages/Tags/AddTag';
import {
  highlightNodeById,
  reselectNodeById,
} from 'utils/aggrid';
import { Tooltip } from 'components/common/Tooltip';
import { FactaTable } from 'components/FactaTable';
import { useLocation } from 'react-router';
import {
  Breadcrumb,
  Breadcrumbs,
} from 'components/common/Breadcrumbs';
import { CompanyRevenueType } from 'interfaces/company';
import { getManagementInfo } from 'utils/managementInfo';

interface Props {
  setCustomMarkerData: (customData: {}) => void;
  companyRevenueType?: CompanyRevenueType;
}

export const Products = ({ setCustomMarkerData, companyRevenueType }: Props) => {
  const location = useLocation();
  const gridRef = useRef<AgGridReact>(null);
  const columnDefs = getColumnDefs();
  const [selectedRows, setSelectedRows] = useState<Product[]>([]);
  const { rightPanes, setSelectedPane, resetPanes } = useRightPanes({
    add: false,
    edit: true,
    delete: false,
    addTagFromAddProduct: false,
    addTagFromEditProduct: false,
  });

  const {
    data,
    isLoading,
    isFetching,
    refetch,
  } = useGetAllProductsQuery();

  const products = data?.filter(({ deleted }) => !deleted) || [];

  const managementInfo = getManagementInfo(companyRevenueType);

  const gridOptions: GridOptions = {
    onSelectionChanged: (e) => {
      const selectedRows = e.api.getSelectedRows();
      setSelectedRows(selectedRows);
      if (selectedRows.length) {
        resetPanes();
      }
    },
  };

  const handleTriggerDelete = () => {
    setSelectedPane('delete');
  };

  const handleAddProduct = () => {
    gridRef.current?.api.deselectAll();
    setSelectedPane('add');
  };

  const handleCSVDownload = () => gridRef.current?.api.exportDataAsCsv({
    fileName: 'insights-products.csv',
    skipRowGroups: true,
    columnKeys: [
      'name',
      'description',
      'revenueType',
      'tags',
      'crmId',
    ],
  });

  useEffect(() => {
    setCustomMarkerData({
      selectedIds: selectedRows.map((row) => row.id),
    });
  }, [selectedRows, setCustomMarkerData]);

  useEffect(() => {
    if (location.hash === '#add') {
      location.hash = '';
      setSelectedPane('add');
    }
  }, [location, setSelectedPane]);

  return (
    <>
      <LeftPane>
        <ButtonsContainer>
          <Breadcrumbs>
            <Breadcrumb link="/data-input">Data Sources</Breadcrumb>
            <Breadcrumb link={managementInfo.path}>{managementInfo.name}</Breadcrumb>
            <Breadcrumb>Products</Breadcrumb>
          </Breadcrumbs>
          <Spacer />
          <Tooltip title="Refresh table data">
            <Button
              variant="icon"
              aria-label="Refresh"
              onClick={refetch}
            >
              <RefreshIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Download CSV">
            <Button
              variant="icon"
              aria-label="Download"
              onClick={handleCSVDownload}
            >
              <DownloadIcon />
            </Button>
          </Tooltip>
        </ButtonsContainer>
        <SectionLabel>
          <span>
            Products
          </span>
        </SectionLabel>
        <FactaTable
          gridRef={gridRef}
          data={products}
          useNativeFilters
          columnDefs={columnDefs}
          isLoading={isLoading || isFetching}
          isDisabled={rightPanes.add}
          onClickAdd={handleAddProduct}
          selectedRowsLength={selectedRows.length}
          entityName="Product"
          gridOptions={gridOptions}
          showQuickSearch
        />
      </LeftPane>
      <RightPane isOpen={rightPanes.add}>
        <AddProduct
          onClose={() => resetPanes()}
          onSuccess={(result) => highlightNodeById(result?.id, gridRef)}
          onAddTag={() => setSelectedPane('addTagFromAddProduct')}
        />
      </RightPane>
      <RightPane isOpen={rightPanes.edit && selectedRows.length > 0}>
        <EditProducts
          onClose={() => gridRef.current?.api.deselectAll()}
          onSave={(id) => reselectNodeById(id, gridRef)}
          onSuccess={(id) => highlightNodeById(id, gridRef)}
          onAddTag={() => setSelectedPane('addTagFromEditProduct')}
          onDelete={handleTriggerDelete}
          selectedRows={selectedRows}
        />
      </RightPane>
      <RightPane isOpen={rightPanes.delete && selectedRows.length > 0}>
        <DeleteProducts
          onClose={() => {
            resetPanes();
            gridRef.current?.api.deselectAll();
          }}
          selectedRows={selectedRows}
        />
      </RightPane>
      <RightPane isOpen={rightPanes.addTagFromAddProduct || rightPanes.addTagFromEditProduct}>
        <AddTag
          onClose={() => resetPanes()}
          onGoBack={() => setSelectedPane(rightPanes.addTagFromAddProduct ? 'add' : 'edit')}
          isIndirectOpen
        />
      </RightPane>
    </>
  );
};
