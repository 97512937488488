import React from 'react';
import * as yup from "yup";
import { SectionLabel } from 'components/common/SectionLabel';
import {
  ButtonsContainer,
  Spacer,
} from 'components/common/ButtonsContainer';
import { Button } from 'components/common/Button';
import { CloseIcon } from 'assets/icons';
import {
  StyledButtonContainer,
  StyledContainer,
  StyledFormContainer,
} from 'components/common/Forms/styled';
import { toastSuccess } from 'utils/toast';
import { StyledRow } from './styled';
import { Dropdown } from 'components/common/Dropdown';
import {
  MetricOrigin,
  UpdateMetricFiltersRequest,
  UpdateMetricFiltersForm,
  UpdateMetricRequest,
} from 'interfaces/metrics';
import { RevenueTypeOptions } from 'utils/constants';
import { useGetAllProductsQuery } from 'store/services/products';
import { CustomersDropdownLazy } from 'components/common/CustomersDropdownLazy';
import { useGetAllTagsQuery } from 'store/services/tags';
import {
  useGetMetricQuery,
  useUpdateMetricMutation,
} from 'store/services/metrics';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import { Loading } from 'components/Loading';
import { TagType } from 'interfaces/tags';
import { useGetAuthMeQuery } from 'store/services/auth';
import { getCurrentCompany } from 'utils/currentCompany';
import { CompanyRevenueType } from 'interfaces/company';

interface Props {
  metricId: string;
  onClose: () => void;
}

export const MetricFiltersComponent = ({
  metricId,
  onClose,
}: Props) => {
  const { data: user } = useGetAuthMeQuery();
  const { data: metric, isLoading: isLoadingMetric } = useGetMetricQuery(metricId);
  const { data: products } = useGetAllProductsQuery();
  const { data: tags } = useGetAllTagsQuery();
  const [updateMetric, { isLoading: isSaving }] = useUpdateMetricMutation();

  const companyRevenueType = getCurrentCompany(user)?.companyRevenueType;

  const schema = yup.object({
    productFilter: yup.array()
      .of(yup.object()),
  })
    .required();

  const {
    handleSubmit,
    getValues,
    control,
    formState: { errors },
  } = useForm<UpdateMetricFiltersForm>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      customerFilter: metric?.filters.customerFilter,
      customerTagFilter: metric?.filters.customerTagFilter,
      productFilter: metric?.filters.productFilter,
      productTagFilter: metric?.filters.productTagFilter,
      contractTagFilter: metric?.filters.contractTagFilter,
      contractLineTagFilter: metric?.filters.contractLineTagFilter,
      revenueTypeFilter: metric?.filters.revenueTypeFilter.map((rtf) => RevenueTypeOptions.find((rto) => rto.value === rtf)),
    },
  });

  const handleSaveAndClose = handleSubmit((data) => {
    const filtersPayload: UpdateMetricFiltersRequest = {
      lookback: metric!.filters.lookback,
      revenueTypeFilter: data.revenueTypeFilter.map(({ label }) => label),
      customerFilter: data.customerFilter.map(({ id }) => id),
      customerTagFilter: data.customerTagFilter.map(({ id }) => id),
      productFilter: data.productFilter.map(({ id }) => id),
      productTagFilter: data.productTagFilter.map(({ id }) => id),
      contractTagFilter: data.contractTagFilter.map(({ id }) => id),
      contractLineTagFilter: data.contractLineTagFilter.map(({ id }) => id),
    };

    const payload: UpdateMetricRequest = {
      metricId,
      name: metric!.name,
      description: metric!.description,
      type: metric!.type.id,
      aggregationMethod: metric!.aggregationMethod,
      tags: metric!.tags,
      recurringRevenueType: metric!.recurringRevenueType,
      filters: filtersPayload,
      startDateField: metric!.startDateField,
    };

    return updateMetric(payload)
      .unwrap()
      .then(() => {
        toastSuccess('Metric filters successfully saved.');
        onClose();
      });
  });

  if (isLoadingMetric) return <Loading />;

  return (
    <StyledContainer>
      <ButtonsContainer alignTop>
        <SectionLabel marginBottom={24}>
          Filters
        </SectionLabel>
        <Spacer />
        <Button
          aria-label="Close"
          variant="icon"
          size="large"
          onClick={onClose}
        >
          <CloseIcon />
        </Button>
      </ButtonsContainer>
      <StyledFormContainer onSubmit={handleSaveAndClose}>
        <StyledRow>
          <Controller
            name="revenueTypeFilter"
            control={control}
            render={({ field }) =>
              <Dropdown
                {...field}
                isOptional
                labelText="Revenue Type"
                labelField="label"
                valueField="value"
                placeholder="None "
                values={getValues('revenueTypeFilter')}
                multi
                options={RevenueTypeOptions}
                error={errors.revenueTypeFilter?.message}
                clearable
                hidden={metric?.origin !== MetricOrigin.SYSTEM_COPY}
              />
            }
          />
          <div />
        </StyledRow>
        <StyledRow>
          <Controller
            name="customerFilter"
            control={control}
            render={({ field }) =>
              <CustomersDropdownLazy
                labelText="Customer"
                isOptional
                {...field}
                labelField="name"
                valueField="id"
                searchBy="name"
                placeholder="None "
                values={getValues('customerFilter')}
                multi
                error={errors.customerFilter?.message}
                clearable
                hidden={metric?.origin !== MetricOrigin.SYSTEM_COPY}
              />
            }
          />
          <Controller
            name="customerTagFilter"
            control={control}
            render={({ field }) =>
              <Dropdown
                labelText="Customer Tag"
                isOptional
                {...field}
                options={tags?.filter((tag) => tag.type === TagType.CUSTOMER) || []}
                labelField="name"
                valueField="id"
                placeholder="None "
                searchBy="name"
                values={getValues('customerTagFilter')}
                multi
                error={errors.customerTagFilter?.message}
                clearable
                hidden={metric?.origin !== MetricOrigin.SYSTEM_COPY}
              />
            }
          />
        </StyledRow>
        <StyledRow>
          <Controller
            name="productFilter"
            control={control}
            render={({ field }) =>
              <Dropdown
                labelText="Product"
                isOptional
                {...field}
                options={products || []}
                labelField="name"
                valueField="id"
                placeholder="None "
                searchBy="name"
                values={getValues('productFilter')}
                multi
                error={errors.productFilter?.message}
                clearable
                hidden={metric?.origin !== MetricOrigin.SYSTEM_COPY}
              />
            }
          />
          <Controller
            name="productTagFilter"
            control={control}
            render={({ field }) =>
              <Dropdown
                labelText="Product Tag"
                isOptional
                {...field}
                options={tags?.filter((tag) => tag.type === TagType.PRODUCT) || []}
                labelField="name"
                valueField="id"
                placeholder="None "
                searchBy="name"
                values={getValues('productTagFilter')}
                multi
                error={errors.productTagFilter?.message}
                clearable
                hidden={metric?.origin !== MetricOrigin.SYSTEM_COPY}
              />
            }
          />
        </StyledRow>
        <StyledRow>
          <Controller
            name="contractTagFilter"
            control={control}
            render={({ field }) =>
              <Dropdown
                labelText="Contract Tag"
                isOptional
                {...field}
                options={tags?.filter((tag) => tag.type === TagType.CONTRACT) || []}
                labelField="name"
                valueField="id"
                placeholder="None "
                searchBy="name"
                values={getValues('contractTagFilter')}
                multi
                error={errors.contractTagFilter?.message}
                clearable
                hidden={metric?.origin !== MetricOrigin.SYSTEM_COPY || companyRevenueType === CompanyRevenueType.SUBSCRIPTIONS}
              />
            }
          />
          <Controller
            name="contractLineTagFilter"
            control={control}
            render={({ field }) =>
              <Dropdown
                labelText={companyRevenueType === CompanyRevenueType.CONTRACTS ? 'Contract Line Tag' : 'Subscription Tag'}
                isOptional
                {...field}
                options={tags?.filter((tag) => tag.type === TagType.CONTRACT) || []}
                labelField="name"
                valueField="id"
                placeholder="None "
                searchBy="name"
                values={getValues('contractLineTagFilter')}
                multi
                error={errors.contractLineTagFilter?.message}
                clearable
                hidden={metric?.origin !== MetricOrigin.SYSTEM_COPY}
              />
            }
          />
          {companyRevenueType === CompanyRevenueType.SUBSCRIPTIONS && <div />}
        </StyledRow>
      </StyledFormContainer>
      <StyledButtonContainer pushRight>
        <Button
          type="button"
          variant="outlined"
          color="secondary"
          onClick={onClose}
          disabled={isSaving}
        >
          CANCEL
        </Button>
        <Button
          type="button"
          onClick={handleSaveAndClose}
          disabled={isSaving}
          isLoading={isSaving}
        >
          SAVE
        </Button>
      </StyledButtonContainer>
    </StyledContainer>
  );
};