import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import structuredClone from '@ungap/structured-clone';
import { AgGridReact } from '@ag-grid-community/react';
import { FactaTable } from 'components/FactaTable';
import {
  LeftPane,
  RightPane,
} from 'components/Layout';
import {
  CellKeyDownEvent,
  ColDef,
  GridOptions,
  ICellEditorParams,
  ICellRendererParams,
  IRichCellEditorParams,
  PasteEndEvent,
  ProcessCellForExportParams,
  ProcessDataFromClipboardParams,
  ValueGetterParams,
  ValueSetterParams,
} from '@ag-grid-community/core';
import { InputBasic } from 'components/common/Input';
import { ButtonsContainer } from 'components/common/ButtonsContainer';
import { Button } from 'components/common/Button';
import { Datepicker } from 'components/common/Datepicker';
import {
  addMonths,
  differenceInCalendarMonths,
  format,
  startOfMonth,
  subMonths,
} from 'date-fns';
import { Dropdown } from 'components/common/Dropdown';
import { format as currencyFormat } from "currency-formatter";
import {
  toastSuccess,
  toastWarning,
} from 'utils/toast';
import {
  DecimalsLeftIcon,
  DecimalsRightIcon,
  InfoIcon,
} from 'assets/icons';
import { SectionLabel } from 'components/common/SectionLabel';
import {
  MAX_PERIODS,
  DRAFT_SAVE_INTERVAL,
} from 'utils/constants';
import { Loading } from 'components/Loading';
import {
  prepareFinancialsPayload,
  verifyFinancialsPayload,
} from 'utils/accounts';
import { RichSelectCellRenderer } from '../../../../components/common/AgGridCellEditors/RichSelectCellRenderer';
import { PeriodsInputWrapper } from './styled';
import { Hint } from 'components/common/Hint';
import { FrequencyOptions } from 'utils/dates';
import {
  Breadcrumb,
  Breadcrumbs,
} from 'components/common/Breadcrumbs';
import { Tabs } from 'components/common/Tabs';
import { Box } from 'components/common/Box';
import {
  useGetAccountMappingsQuery,
  useGetAccountTypesQuery,
} from 'store/services/accounts';
import { usePutFinancialsMutation } from 'store/services/financials';
import {
  useNavigate,
  useParams,
} from 'react-router';
import { FinancialType } from 'interfaces/financials';
import { useDebouncedCallback } from 'use-debounce';
import { IntegrationType } from 'interfaces/accounts';
import { CollapsibleBox } from 'components/common/Box/CollapsibleBox';
import { SavingIndicator } from 'components/SavingIndicator';
import { useGlobalHotkeys } from 'hooks/useGlobalHotkeys';
import { useDraft } from 'hooks/useDraft';
import { parsedPastedValue } from 'utils/financialsUploader';
import { AgGridCellRendererDropdown } from 'components/common/AgGridCellRendererDropdown';
import { Company } from 'interfaces/company';

interface Props {
  currentCompany: Company;
}

export const IntegrationsFinancialsUploader = ({ currentCompany }: Props) => {
  const { financialType = FinancialType.PROFIT_AND_LOSS } = useParams<{ financialType: FinancialType }>();
  return <IntegrationsFinancialsUploaderView
    currentCompany={currentCompany}
    key={financialType}
  />;
};

export const IntegrationsFinancialsUploaderView = ({ currentCompany }: Props) => {
  const gridRef = useRef<AgGridReact>(null);
  const navigate = useNavigate();
  const { financialType = FinancialType.PROFIT_AND_LOSS } = useParams<{ financialType: FinancialType }>();
  useGlobalHotkeys();
  const [dirty, setDirty] = useState(false);
  const [touched, setTouched] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [periodsNumber, setPeriodsNumber] = useState<number | string | undefined>(12);
  const [columns, setColumns] = useState<any[]>([]);
  const [periodStart, setPeriodStart] = useState(subMonths(startOfMonth(new Date()), 12));
  const [periodEnd, setPeriodEnd] = useState<Date | null>(subMonths(startOfMonth(new Date()), 1));
  const [frequency, setFrequency] = useState({
    name: 'Monthly',
    variant: 'monthYearPicker' as const,
  });
  const [precision, setPrecision] = useState(0);
  const { data: accountMappings, isLoading: isLoadingAccountMappings } = useGetAccountMappingsQuery({ financialType });
  const { data: accountTypes, isLoading: isLoadingAccountTypes } = useGetAccountTypesQuery(financialType);
  const categories = accountTypes?.map((at, index) => `${index + 1}:${at.name}`) || [];
  const [putFinancials, { isLoading: isUploading }] = usePutFinancialsMutation();
  const currencyCode = currentCompany?.currency.code || 'USD';

  const [initialData] = useState([{
    id: crypto.randomUUID(),
    accountType: null,
    accountName: null,
    glAccountName: null,
  }]);

  const {
    clearDraft,
    draft,
    draftFound,
    isSavingDraft,
    saveDraft,
    setDraftFound,
  } = useDraft(`upload_${financialType}`);

  const updateMatchedValues = (e: PasteEndEvent<any, any>) => {
    const itemsToUpdate: any[] = [];

    e.api.forEachNode((node) => {
      const matchedAccount = accountMappings?.accountMappings?.find((mapping) => mapping.glAccount.source === IntegrationType.MANUAL && mapping.glAccount.name.toLowerCase() === node.data.glAccountName.toLowerCase());

      if (matchedAccount) {
        const data = { ...node.data };
        if (!data.accountType) {
          data.accountType = matchedAccount.account?.type;
        }
        if (!data.accountName) {
          data.accountName = matchedAccount.account?.name;
        }
        itemsToUpdate.push(data);
      }
    });

    e.api.applyTransaction({
      update: itemsToUpdate,
    });
  };

  const processDataFromClipboard = useCallback(
    (params: ProcessDataFromClipboardParams): string[][] | null => {
      const data = [...params.data]
        .map((line) => line.map((cell) => cell.trim()));

      const emptyLastRow =
        data[data.length - 1][0] === '' && data[data.length - 1].length === 1;
      if (emptyLastRow) {
        data.splice(data.length - 1, 1);
      }
      const lastIndex = gridRef.current!.api.getModel()
        .getRowCount() - 1;

      const focusedCell = gridRef.current!.api.getFocusedCell();
      const focusedIndex = focusedCell!.rowIndex;

      if (focusedIndex + data.length - 1 > lastIndex) {
        const resultLastIndex = focusedIndex + (data.length - 1);
        const numRowsToAdd = resultLastIndex - lastIndex;
        const rowsToAdd: any[] = [];
        for (let i = 0; i < numRowsToAdd; i++) {
          const index = data.length - 1;
          const row = data.slice(index, index + 1)[0];
          // Create row object
          const rowObject: any = {
            id: crypto.randomUUID(),
          };
          let currentColumn: any = focusedCell!.column;
          row.forEach((item) => {
            if (!currentColumn) {
              return;
            }
            rowObject[currentColumn.colDef.field] = currentColumn.colDef.type === "numberField" ? item || "0" : item;
            currentColumn = gridRef.current!.api.getDisplayedColAfter(
              currentColumn,
            );
          });
          rowsToAdd.push(rowObject);
        }
        gridRef.current!.api.applyTransaction({ add: rowsToAdd });
      }
      return data;
    },
    [],
  );

  const processCellForClipboard = (params: ProcessCellForExportParams<any, any>) => params.column.getColId() === 'accountType'
    ? params.value?.split(':')[1] || ''
    : params.value;

  const processCellFromClipboard = (params: ProcessCellForExportParams<any, any>) => {
    const colDef = params.column.getColDef();

    if (colDef.type === 'numberField') {
      return parsedPastedValue(params.value);
    }

    if (colDef.field === 'accountType') {
      return categories.find((acc) => acc.split(':')[1] === params.value);
    }

    return params.value;
  };

  const onPasteEnd = (e: PasteEndEvent) => {
    updateMatchedValues(e);
    e.api.autoSizeAllColumns();
  };

  const handleSaveDraft = () => {
    if (!periodEnd) return;

    let items: any[] = [];
    gridRef.current?.api.forEachNode((node) => {
      items.push({ ...node.data });
    });
    saveDraft({
      financialType,
      items,
      periodStart: periodStart.toISOString(),
      periodEnd: periodEnd.toISOString(),
    });
  };

  const handleDebouncedSaveDraft = useDebouncedCallback(handleSaveDraft, DRAFT_SAVE_INTERVAL);

  const onCellKeyDown = (props: CellKeyDownEvent<any, any>) => {
    setTouched(true);
    const event = props.event as KeyboardEvent;
    if (event.code === 'Minus' && (event.metaKey || event.ctrlKey) && event.altKey) {
      if (props.api.getSelectedRows().length === 0) {
        props.node.setSelected(true);
      }
      props.api.applyTransaction({
        remove: props.api.getSelectedRows(),
      });
    }

    if (event.key === 'Enter' && !gridRef.current?.api.getEditingCells().length) {
      const lastRowIndex = props.api.getLastDisplayedRow();
      const nextRowIndex = props.rowIndex !== null && props.rowIndex !== lastRowIndex
        ? props.rowIndex + 1
        : undefined;
      if (nextRowIndex) {
        gridRef.current?.api.setFocusedCell(nextRowIndex, props.column);
        gridRef.current?.api.clearRangeSelection();
      }
    }
  };

  const gridOptions: GridOptions = {
    onSelectionChanged: (e) => setSelectedRows(e.api.getSelectedRows()),
    onPasteEnd: onPasteEnd,
    onCellEditingStopped: (e) => {
      if (e.column.getColId() !== 'accountType') {
        e.api.autoSizeAllColumns();
      }
      setTouched(true);
    },
    processDataFromClipboard,
    processCellFromClipboard,
    processCellForClipboard,
    suppressRowClickSelection: true,
    rowDragManaged: true,
    suppressMoveWhenRowDragging: true,
    rowDragMultiRow: true,
    icons: {
      rowDrag: '<i class="ag-icon ag-icon-columns"/>',
    },
    undoRedoCellEditing: true,
    undoRedoCellEditingLimit: 10,
    columnTypes: {
      numberField: {
        editable: true,
      },
    },
    tooltipShowDelay: 1000,
    enableCharts: true,
    statusBar: {
      statusPanels: [
        { statusPanel: 'agAggregationComponent' },
      ],
    },
    onCellKeyDown: onCellKeyDown,
    onCellValueChanged: handleDebouncedSaveDraft,
    onColumnEverythingChanged: ({ api }) => {
      api.resetColumnState();
    },
  };

  const commonColumns: ColDef[] = [
    {
      field: '',
      headerName: '',
      pinned: 'left',
      maxWidth: 52,
      rowDrag: true,
      rowDragText: (item: any, items: number) => {
        return items > 1
          ? `Dragging ${items} rows...`
          : item.rowNode.data.account || 'Dragging...';
      },
    },
    {
      field: '',
      headerName: '',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      pinned: 'left',
      maxWidth: 52,
    },
    {
      field: 'accountType',
      headerName: 'Category',
      editable: true,
      resizable: true,
      pinned: 'left',
      minWidth: 225,
      cellEditor: 'agRichSelectCellEditor',
      cellEditorPopup: false,
      cellEditorParams: (e: ICellEditorParams): IRichCellEditorParams => {
        const actualWidth = e.column.getActualWidth();
        return {
          values: categories,
          cellRenderer: (props: ICellRendererParams) => <RichSelectCellRenderer
            {...props}
            width={actualWidth}
          />,
          valueListMaxWidth: actualWidth,
          allowTyping: true,
          cellHeight: 40,
          valueListMaxHeight: 'calc(var(--ag-row-height) * 10)',
        };
      },
      cellRenderer: (params: ICellRendererParams) => (
        <AgGridCellRendererDropdown gridParams={params}>
          {params.value ? params.value.split(':')[1] : '-'}
        </AgGridCellRendererDropdown>
      ),
      cellClass: ({ value }) => dirty && !value ? 'cell-warning' : '',
      tooltipValueGetter: ({ value }) => dirty && !value ? 'Possibly missing value' : '',
      valueSetter: (params: ValueSetterParams) => {
        params.data.accountType = params.newValue?.split(':')[1] || null;
        return true;
      },
      valueGetter: (params: ValueGetterParams) => {
        return categories.find((acc) => acc.split(':')[1] === params.data.accountType);
      },
    },
    {
      field: 'accountName',
      headerName: 'Facta Account',
      editable: true,
      resizable: true,
      pinned: 'left',
      minWidth: 215,
      cellClass: ({ value }) => dirty && !value ? 'cell-warning' : '',
      tooltipValueGetter: ({ value }) => dirty && !value ? 'Possibly missing value' : '',
    },
    {
      field: 'glAccountName',
      headerName: 'GL Account',
      editable: true,
      resizable: true,
      minWidth: 215,
      cellClass: ({ value, rowIndex }) => {
        if (dirty && !value) {
          return 'cell-error';
        }
        if (!touched && !value && rowIndex === 0) {
          return 'cell-placeholder';
        }
      },
      cellRenderer: ({ value, rowIndex }: ICellRendererParams) => !touched && !value && rowIndex === 0 ? 'Paste Spreadsheet' : value || '',
      tooltipValueGetter: ({ value, rowIndex }) => {
        if (dirty && !value) {
          return 'This field is required';
        }
        if (!touched && rowIndex === 0) {
          return 'Start pasting your data from this cell';
        }
      },
    },
  ];

  const columnDefs: ColDef[] = useMemo(() => {
    return [
      ...commonColumns,
      ...columns,
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, categories]);

  const addEmptyRow = () => {
    gridRef.current?.api?.applyTransaction({
      add: [{
        id: crypto.randomUUID(),
      }],
    });
  };

  const handlePeriodsChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (Number(e.currentTarget.value) > MAX_PERIODS) return MAX_PERIODS;
    if (Number(e.currentTarget.value) < 0) return;

    const newValue = Number(e.currentTarget.value);
    setPeriodsNumber(newValue ? newValue : '');
    setPeriodEnd(addMonths(periodStart, newValue ? newValue - 1 : 0));
  };

  const handleStartDateChange = (date: Date | [Date | null, Date | null] | undefined) => {
    if (!periodEnd) {
      setPeriodStart(date as Date);
      return;
    };

    const newNumberOfPeriods = differenceInCalendarMonths(periodEnd, date as Date) + 1;

    if (newNumberOfPeriods > 0) {
      setPeriodStart(date as Date);
      setPeriodsNumber(newNumberOfPeriods);
      handleDebouncedSaveDraft();
    }
  };

  const handleEndDateChange = (date: Date | [Date | null, Date | null] | undefined) => {
    const newNumberOfPeriods = differenceInCalendarMonths(date as Date, periodStart) + 1;

    if (newNumberOfPeriods > 0) {
      setPeriodEnd(date as Date);
      setPeriodsNumber(newNumberOfPeriods);
      handleDebouncedSaveDraft();
    }
  };

  const handleSuccess = () => {
    const isProfitAndLoss = financialType === FinancialType.PROFIT_AND_LOSS;
    const typeName = isProfitAndLoss
      ? 'Profit & Loss'
      : 'Balance Sheet';
    const urlPath = isProfitAndLoss
      ? 'profit_and_loss'
      : 'balance_sheet';

    clearDraft();
    toastSuccess(`${typeName} Financials successfully uploaded.`, {
      onClose: () => navigate(`/accounts/account-mapping/${urlPath}`),
    });
  };

  const handleImport = async (shouldImportAll?: boolean) => {
    if (!periodEnd) return;
    setDirty(true);
    setTouched(true);
    gridRef.current?.api.stopEditing();

    const items: any[] = [];
    gridRef.current?.api.forEachNode((node) => {
      items.push(node.data);
    });

    const itemsToImport = shouldImportAll
      ? items
      : gridRef.current?.api.getSelectedRows() || [];

    const payload = prepareFinancialsPayload(itemsToImport, periodStart, periodEnd, financialType);
    if (!payload.financials.length) {
      return toastWarning('No data provided. Fill in all required fields before uploading.');
    }
    if (!verifyFinancialsPayload(payload)) {
      return toastWarning('Missing fields found. Fill in all required data before uploading.');
    }
    await putFinancials(payload)
      .unwrap();
    handleSuccess();
  };

  const handleResetForm = () => {
    gridRef.current?.api?.setGridOption('rowData', []);
    addEmptyRow();
    setDirty(false);
    setTouched(false);
    clearDraft();
  };

  const handleApplyDraft = () => {
    const newPeriodsNumber = differenceInCalendarMonths(new Date(draft.periodEnd!), new Date(draft.periodStart!)) + 1;
    setPeriodStart(new Date(draft.periodStart!));
    setPeriodEnd(new Date(draft.periodEnd!));
    setPeriodsNumber(newPeriodsNumber);
    setTimeout(() => {
      gridRef.current?.api.setGridOption('rowData', structuredClone(draft.items || []));
      setDraftFound(false);
      setDirty(true);
      setTouched(true);
      gridRef.current?.api.autoSizeAllColumns();
    }, 100);
  };

  const handleDismissDraft = () => {
    clearDraft();
    setDraftFound(false);
    setDirty(false);
  };

  useEffect(() => {
    const columns: ColDef[] = new Array(periodsNumber)
      .fill(undefined)
      .map((_, index) => {
        const field = format(addMonths(periodStart, index), 'yyyy-MM-dd');
        const headerName = format(addMonths(periodStart, index), 'MMM y');
        return {
          field,
          headerName,
          editable: true,
          type: 'numberField',
          minWidth: 170,
          width: 170,
          valueFormatter: ({ value }) => value !== '' && value !== undefined && value !== null ? currencyFormat(value, { code: currencyCode, precision: precision }) : '',
          cellClass: ({ value }) => !dirty || (value !== '' && value !== undefined && value !== null) ? 'ag-right-aligned-cell' : 'cell-warning',
          headerClass: 'ag-right-aligned-header',
          tooltipValueGetter: ({ value }) => !dirty || (value !== '' && value !== undefined && value !== null) ? '' : 'Possibly missing value',
          cellEditor: 'agNumberCellEditor',
          cellEditorParams: {
            precision: 10,
          },
        };
      });

    setColumns(columns);
  }, [periodStart, periodEnd, periodsNumber, dirty, precision, currencyCode]);

  useEffect(() => {
    gridRef?.current?.api?.resetColumnState();
  }, [columns]);

  const adjustNumbers = (multiplier: number): void => {
    let shouldBreak = false;
    const selectedRows = [...gridRef.current?.api.getSelectedRows() || []];
    const updatedRows = selectedRows.map((row) => {
      const updatedRow = { ...row };
      columns.forEach((col) => {
        if (col.field === 'accountType' || col.field === 'accountName' || col.field === 'glAccountName') {
          return;
        }
        const newValue = (updatedRow[col.field] || 0) * multiplier;
        updatedRow[col.field] = newValue;
        if (newValue.toString()
          .includes('e')) {
          shouldBreak = true;
        }
      });
      return updatedRow;
    });

    if (shouldBreak) {
      toastWarning('Some values are too large or too small to be adjusted.');
    } else {
      gridRef.current?.api.applyTransaction({
        update: updatedRows,
      });
    }
    return;
  };

  return (
    <>
      <LeftPane secondary>
        <Breadcrumbs>
          <Breadcrumb link="/accounts">
            Financial Accounts
          </Breadcrumb>
          <Breadcrumb>
            Upload
          </Breadcrumb>
        </Breadcrumbs>
        <SectionLabel marginBottom={20}>
          Upload
        </SectionLabel>
        <Tabs
          tabs={[
            {
              name: 'Profit & Loss',
              value: `/accounts/upload/${FinancialType.PROFIT_AND_LOSS}`,
            },
            {
              name: 'Balance Sheet',
              value: `/accounts/upload/${FinancialType.BALANCE_SHEET}`,
            },
          ]}
          value={`/accounts/upload/${financialType}`}
        />
        {isLoadingAccountMappings || isLoadingAccountTypes
          ? (
            <Loading />
          )
          : (
            <Box
              fullHeight
              marginBottom={0}
            >
              <SectionLabel secondary>
                Paste in Financials
              </SectionLabel>
              <CollapsibleBox
                title="1. Select Dates"
                subtitle="Select the start and end dates to populate the table below."
                tertiary
                initiallyOpen
                autoFocus
              >
                <ButtonsContainer
                  paddingBottom={0}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      gridRef.current?.api.setFocusedCell(0, 'accountName');
                      gridRef.current?.api.startEditingCell({
                        rowIndex: 0,
                        colKey: 'accountName',
                      });
                    }
                  }}
                >
                  <Datepicker
                    name="periodStart"
                    labelText="Period Start"
                    onChange={handleStartDateChange}
                    value={periodStart}
                    variant={frequency.variant}
                    isClearable={false}
                    condensed
                  />
                  <PeriodsInputWrapper>
                    <InputBasic
                      name="periods"
                      labelText="Periods"
                      value={`${periodsNumber}`}
                      onChange={handlePeriodsChange}
                      type="number"
                      max={MAX_PERIODS}
                      min="1"
                      condensed
                    />
                  </PeriodsInputWrapper>
                  <Datepicker
                    name="periodEnd"
                    labelText="Period End"
                    onChange={handleEndDateChange}
                    value={periodEnd || undefined}
                    variant={frequency.variant}
                    isClearable={false}
                    condensed
                  />
                  <Dropdown
                    labelText="Frequency"
                    values={[frequency]}
                    labelField="name"
                    valueField="name"
                    minWidth={150}
                    options={FrequencyOptions}
                    onChange={(value: any[]) => {
                      setFrequency(value[0]);
                    }}
                    disabled
                    condensed
                  />
                </ButtonsContainer>
              </CollapsibleBox>
              <Box
                marginBottom={0}
                style={{ height: 'calc(100vh - 138px)' }}
                disabled={!periodStart || !periodEnd}
                disabledText="Select Dates first"
              >
                <SectionLabel tertiary>
                  <span>
                    2. Paste in Spreadsheet
                    <p>
                      Copy the data from your spreadsheet into the Name and Date columns.
                      Fill in the corresponding Category and Heading for each line, and delete all empty rows,
                      including any heading rows with empty values in the Date columns.
                      Click “Submit” when you're finished updating the table.
                    </p>
                  </span>
                </SectionLabel>
                <ButtonsContainer paddingBottom={32}>
                  <Button
                    variant="outlined"
                    onClick={handleSaveDraft}
                    disabled={isSavingDraft}
                    minWidth={175}
                  >
                    SAVE PROGRESS
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => handleImport(!selectedRows.length)}
                    disabled={isUploading}
                    minWidth={175}
                  >
                    {!selectedRows.length ? 'SUBMIT' : 'SUBMIT SELECTED'}
                  </Button>
                  <SavingIndicator isSaving={isSavingDraft}>Saving Draft...</SavingIndicator>
                  <Button
                    variant="outlined"
                    color="error"
                    pushRight
                    disabled={isUploading}
                    onClick={handleResetForm}
                  >
                    Reset form
                  </Button>
                </ButtonsContainer>
                <FactaTable
                  gridRef={gridRef}
                  data={initialData}
                  columnDefs={columnDefs}
                  selectedRowsLength={selectedRows.length}
                  entityName={'line'}
                  gridOptions={gridOptions}
                  onClickDelete={() => {
                    gridRef.current?.api.applyTransaction({
                      remove: selectedRows,
                    });
                  }}
                  onClickAdd={addEmptyRow}
                  condensed
                  suppressQuantityDisplay
                  customButtons={[
                    {
                      icon: precision === 2 ? <DecimalsLeftIcon /> : <DecimalsRightIcon />,
                      onClick: () => setPrecision(precision === 2 ? 0 : 2),
                      variant: 'icon',
                      color: 'primary',
                      size: 'large',
                      tooltip: precision === 2 ? 'Hide decimals' : 'Show decimals',
                      hidden: true, // TODO Unhide when agreed to show the button
                    },
                    {
                      icon: <>×1K</>,
                      onClick: () => adjustNumbers(1000),
                      variant: 'icon',
                      color: 'primary',
                      size: 'large',
                      tooltip: 'Multiply selected rows by 1000.',
                      hidden: selectedRows.length === 0,
                      divider: true,
                    },
                    {
                      icon: <>÷1K</>,
                      onClick: () => adjustNumbers(0.001),
                      variant: 'icon',
                      color: 'primary',
                      size: 'large',
                      tooltip: 'Divide selected rows by 1000.',
                      hidden: selectedRows.length === 0,
                    },
                  ]}
                  isDisabled={isUploading}
                />
              </Box>
            </Box>
          )}
      </LeftPane>
      <RightPane isOpen={draftFound}>
        <header>
          <SectionLabel>
            Unsaved version found
          </SectionLabel>
        </header>
        <main>
          <Hint>
            <InfoIcon />
            <div>
              <p>We've found unsaved version of your previous import.</p>
              <p>Hit apply to bring it back and continue or dismiss to start over.</p>
            </div>
          </Hint>
        </main>
        <footer>
          <ButtonsContainer pushRight>
            <Button
              variant="contained"
              onClick={handleApplyDraft}
            >
              APPLY
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={handleDismissDraft}
            >
              DISMISS
            </Button>
          </ButtonsContainer>
        </footer>
      </RightPane>
    </>
  );
};
