import {
  Company,
  CompanyAddPayload,
  CompanyUpdateByIdPayload,
  CompanyUpdatePayload,
  DuplicateCompanyPayload,
} from "interfaces/company";
import { api } from "./api";
import { authApi } from "./auth";
import {
  AuthTags,
  CompaniesTags,
} from "./api-tags";

export const companiesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addCompany: builder.mutation<Company, CompanyAddPayload>({
      query: (company) => ({
        url: 'companies',
        method: 'POST',
        body: company,
      }),
      invalidatesTags: (res, err) => !err ? [
        AuthTags.User,
        CompaniesTags.Company,
      ] : [],
    }),
    getCompanyDetails: builder.query<Company, void>({
      query: () => ({
        url: 'companies',
      }),
      providesTags: [CompaniesTags.Company],
    }),
    updateCompany: builder.mutation<Company, CompanyUpdatePayload>({
      query: (company) => {
        return {
          url: `companies`,
          method: 'PUT',
          body: company,
        };
      },
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data: updatedCompany} = await queryFulfilled;
          dispatch(companiesApi.util
            .updateQueryData('getCompanyDetails', undefined, () => updatedCompany));
        } catch {}
      },
      invalidatesTags: (res, err) => !err ? [AuthTags.User] : [],
    }),
    updateCompanyById: builder.mutation<Company, CompanyUpdateByIdPayload>({
      query: (company) => ({
        url: `${company.id}/companies`,
        method: 'PUT',
        body: company,
      }),
      invalidatesTags: (res, err) => !err ? [
        AuthTags.User,
        CompaniesTags.Company,
      ] : [],
    }),
    duplicateCompany: builder.mutation<Company, DuplicateCompanyPayload>({
      query: ({ companyId, ...rest }) => ({
        url: `${companyId}/companies/duplicate`,
        method: 'POST',
        body: rest,
      }),
      invalidatesTags: (res, err) => !err ? [
        AuthTags.User,
        CompaniesTags.Company,
      ] : [],
    }),
    deleteCompanies: builder.mutation<void, string[]>({
      query: (companies) => ({
        url: 'companies',
        method: 'DELETE',
        body: companies,
      }),
      onQueryStarted: async (deletedCompanies, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;

          dispatch(authApi.util
            .updateQueryData('getAuthMe', undefined, (me) => ({
              ...me,
              companies: me.companies.filter((company) => !deletedCompanies.includes(company.id)),
            })));
        } catch {}
      },
    }),
    getCurrencies: builder.query<string[], void>({
      query: () => ({
        url: 'currencies',
      }),
    }),
  }),
});

export const {
  useAddCompanyMutation,
  useGetCompanyDetailsQuery,
  useUpdateCompanyMutation,
  useUpdateCompanyByIdMutation,
  usePrefetch: usePrefetchCompanies,
  useDeleteCompaniesMutation,
  useDuplicateCompanyMutation,
  useGetCurrenciesQuery,
  util: {
    resetApiState: resetCompaniesApiState,
  },
} = companiesApi;
